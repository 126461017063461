import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconHeart: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.096 18.577c1.987-1.779 3.382-3.069 4.183-3.87 1.25-1.25 2.171-2.34 2.764-3.27.657-1.041.986-2.027.986-2.956a3.5 3.5 0 0 0-.457-1.78 3.255 3.255 0 0 0-1.25-1.237c-.529-.297-1.13-.445-1.803-.445-.785 0-1.51.22-2.175.661-.665.441-1.134 1.006-1.406 1.695h-1.876c-.256-.69-.72-1.254-1.394-1.695a3.878 3.878 0 0 0-2.163-.66c-.673 0-1.278.147-1.815.444-.537.296-.958.71-1.262 1.238A3.5 3.5 0 0 0 3.97 8.48c0 .93.329 1.915.986 2.957.593.929 1.514 2.019 2.764 3.269.801.801 2.196 2.091 4.183 3.87l.096.096.096-.096ZM16.52 3c1.01 0 1.935.24 2.777.721a5.237 5.237 0 0 1 1.983 1.983c.48.842.721 1.767.721 2.777 0 1.202-.353 2.42-1.058 3.654-.625 1.073-1.618 2.3-2.98 3.678-.818.833-2.316 2.243-4.496 4.23L12 21.365l-1.442-1.274c-2.148-1.955-3.654-3.373-4.52-4.255-1.362-1.378-2.347-2.604-2.956-3.677C2.36 10.925 2 9.699 2 8.48c0-1.01.244-1.935.733-2.777a5.352 5.352 0 0 1 1.983-1.983C5.55 3.241 6.48 3 7.505 3c.85 0 1.674.192 2.476.577A6.044 6.044 0 0 1 12 5.115a6.044 6.044 0 0 1 2.02-1.538A5.711 5.711 0 0 1 16.52 3Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconHeart.displayName = "heart";
export default IconHeart;
