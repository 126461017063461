import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCoffee: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.25 21.25h5A6.25 6.25 0 0 0 22.5 15v-1.25h1.25a3.75 3.75 0 0 0 0-7.5H22.5V5a1.25 1.25 0 0 0-1.25-1.25h-15A1.25 1.25 0 0 0 5 5v10a6.25 6.25 0 0 0 6.25 6.25ZM22.5 8.75h1.25a1.25 1.25 0 0 1 0 2.5H22.5v-2.5Zm-15-2.5H20V15a3.75 3.75 0 0 1-3.75 3.75h-5A3.75 3.75 0 0 1 7.5 15V6.25Zm18.75 17.5H3.75a1.25 1.25 0 0 0 0 2.5h22.5a1.25 1.25 0 0 0 0-2.5Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCoffee.displayName = "coffee";

export default IconCoffee;
