import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconTransporter: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 68.83 29.72"
        className={className}
      >
        <path
          fill="currentColor"
          d="m68.75 21-.09-.5-.61-.5a55.54 55.54 0 0 0 .15-7.66 46.62 46.62 0 0 0-1.14-6.45c-.28-1.1-.58-2.19-.91-3.22l-.3-.91-.1-.28a1.93 1.93 0 0 0-.54-.83 2.44 2.44 0 0 0-1.48-.56h-1L60.1 0H44.39l-3.6.05C34.65.19 27.83.51 25 .75s-5 1.56-8.86 4.3c-.41.29-.83.6-1.31 1l-.25.2c-.54.44-2 1.62-2.37 1.9a9.7 9.7 0 0 1-1.11.68 4.37 4.37 0 0 1-.41.2l-.21.06-.81.25c-.91.29-1.74.57-2.48.83L6 10.63a13.35 13.35 0 0 0-2.13 1C1.48 13.13.84 14.3.71 15v1.88c-.06.2-.2.63-.48 1.32a1.94 1.94 0 0 0-.1 1.25v.45a2.32 2.32 0 0 0-.07.83q.06.51.15 1.08c0 .3.11.56.15.77a3.35 3.35 0 0 0 0 .55 1.7 1.7 0 0 0 1.16 1.57l.39.16 1.09.45c.47.16.92.28 1.32.38a8.71 8.71 0 0 0 1.27.22h3.58a6.24 6.24 0 0 0 10.65 1.24L48.35 26a6.24 6.24 0 0 0 11.79-1.4h.47l2.56-.1h.92a14.71 14.71 0 0 0 3.18-.23 2 2 0 0 0 .6-.27c.83-.6 1.13-1.59.88-3Zm-53.94-3.85a6.25 6.25 0 0 0-6.23 6.12H5.84A8.14 8.14 0 0 1 5 23.1c-.34-.08-.71-.19-1.11-.32L3 22.49v-.27c0-.08-.09-.36-.17-.83l-.12-.84v-.14a3.64 3.64 0 0 0 .1-.7 3.77 3.77 0 0 0 0-.57.13.13 0 0 0 0-.06 16.41 16.41 0 0 0 .58-1.66v-1.9l.1-.14a7.47 7.47 0 0 1 1.79-1.5 13.4 13.4 0 0 1 1.67-.76l1.2-.44a47.5 47.5 0 0 1 3.48-1.14 10.54 10.54 0 0 0 2.21-1.24c.45-.3 2.16-1.74 2.68-2.17s.82-.64 1.19-.9c3.89-2.74 5.72-3.67 7.54-3.82 2.76-.24 9.68-.56 15.59-.66h3.58c4.72-.05 10 0 15.66 0h3.27l.26.76c.3 1 .59 2 .86 3.05a43.26 43.26 0 0 1 1.07 6.08 57 57 0 0 1-.2 7.91v.7l.56.44-1.9.09h-.91l-2.55.09h-.44a6.24 6.24 0 0 0-12.3 1.42L21 24.45a6.32 6.32 0 0 0 .09-1.06 6.25 6.25 0 0 0-6.28-6.24Zm3.57 6.24a3.57 3.57 0 1 1-3.57-3.57 3.57 3.57 0 0 1 3.57 3.57ZM54 19.82a3.57 3.57 0 1 1-3.57 3.57A3.57 3.57 0 0 1 54 19.82Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconTransporter.displayName = "transporter";
export default IconTransporter;
