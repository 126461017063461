import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconWinter: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="#1B394E"
          fillRule="evenodd"
          d="m13.64 2.33-1.652 1.736-1.758-1.631a1.072 1.072 0 0 0-1.502.046 1.039 1.039 0 0 0 .047 1.482l2.183 2.024-.029 4.17-3.613-2.162-.844-2.919a1.064 1.064 0 0 0-1.313-.72c-.564.159-.89.739-.73 1.296l.662 2.29-2.32.653c-.564.159-.89.739-.73 1.295.161.557.749.88 1.313.72l2.88-.81 3.718 2.225-3.661 2.191-2.937-.827a1.064 1.064 0 0 0-1.313.72 1.046 1.046 0 0 0 .73 1.296l2.32.654-.662 2.29a1.046 1.046 0 0 0 .73 1.295 1.064 1.064 0 0 0 1.313-.72l.827-2.859 3.604-2.158-.029 4.16-2.062 2.167a1.039 1.039 0 0 0 .046 1.482c.428.396 1.1.375 1.502-.046l1.652-1.736 1.758 1.631c.428.396 1.1.376 1.502-.046a1.038 1.038 0 0 0-.047-1.482L13 17.973l.028-4.107 3.673 2.2.827 2.858c.161.556.749.879 1.313.72.564-.159.89-.739.73-1.296l-.662-2.29 2.32-.653c.564-.159.89-.739.73-1.295a1.064 1.064 0 0 0-1.313-.72l-2.937.826-3.66-2.191 3.716-2.226 2.881.812a1.064 1.064 0 0 0 1.313-.72 1.047 1.047 0 0 0-.73-1.296l-2.32-.654.662-2.29a1.046 1.046 0 0 0-.73-1.295 1.064 1.064 0 0 0-1.313.72l-.844 2.919-3.63 2.173.028-4.19 2.106-2.212a1.038 1.038 0 0 0-.046-1.482 1.072 1.072 0 0 0-1.502.046Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconWinter.displayName = "winter";
export default IconWinter;
