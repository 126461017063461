import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconStar: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        viewBox="0 0 31 30"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7329 2.77335C15.636 2.57555 15.3639 2.57555 15.2671 2.77335L11.4315 10.6049L2.8509 11.8689C2.64517 11.8992 2.54123 12.1705 2.70809 12.3343L8.91205 18.4244L7.44825 27.0261C7.40769 27.2644 7.64808 27.4081 7.82425 27.3147L15.5 23.2465L23.1757 27.3147C23.3518 27.4081 23.5922 27.2644 23.5517 27.0261L22.0879 18.4244L28.2918 12.3343C28.4587 12.1705 28.3548 11.8992 28.149 11.8689L19.5685 10.6049L15.7329 2.77335ZM13.4709 1.89366C14.2994 0.202114 16.7005 0.202114 17.529 1.89366L20.9015 8.77965L28.4405 9.89024C30.303 10.1646 31.0233 12.4555 29.6929 13.7616L24.2349 19.1194L25.5233 26.6906C25.8362 28.5293 23.9135 29.9693 22.2391 29.0819L15.5 25.51L8.76086 29.0819C7.08646 29.9693 5.1637 28.5293 5.47659 26.6906L6.76503 19.1194L1.30704 13.7616C-0.0234185 12.4555 0.696936 10.1646 2.55942 9.89024L10.0984 8.77965L13.4709 1.89366Z"
          fill="currentColor"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconStar.displayName = "star";
export default IconStar;
