import { FC, PropsWithChildren } from "react";
import { Icon } from "../Icon";
import { createSlots } from "utils/create-slots";
import Image from "../Image/Image";

const ReviewCard = ({ children }) => {
  return (
    <div className="shadow-md p-7 mx-4 my-10 bg-white border-2 border-transparent lg:min-h-[350px] lg:max-h-[350px] min-h-[300px] max-h-[300px] ">
      {children}
    </div>
  );
};

{
  /* <Header>
        <Avatar></Avatar>
        <Title></Title>
        <Timest
      </Header> */
}

const Stars: FC<{ rating: number }> = ({ rating }) => {
  return (
    <>
      <div className="flex items-center my-6">
        {/* client wants to display 5-star ratings only anyways */}
        <Icon type="rating" className="w-8 h-8 text-warning" />
        <Icon type="rating" className="w-8 h-8 text-warning" />
        <Icon type="rating" className="w-8 h-8 text-warning" />
        <Icon type="rating" className="w-8 h-8 text-warning" />
        <Icon type="rating" className="w-8 h-8 text-warning" />
        {/* {rating} */}
      </div>
    </>
  );
};

const Content = ({ children }) => {
  return <p className="lg:line-clamp-6 line-clamp-5">{children}</p>;
};
{
  /* <Slot name="title"> */
}

const Header: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [items, avatar] = createSlots(["items", "avatar"], children);
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        {avatar}
        <div className="flex flex-col ml-5">{items}</div>
      </div>
      <Icon type="google" className="w-8 h-8" purposeLabel="Google Logo" />
    </div>
  );
};

const Avatar = ({ src, alt }) => {
  const placeHolderPath = "/assets/img/avatar.png";
  // to-do: rmv placceholder
  return (
    <Image
      src={src || placeHolderPath}
      layout="fixed"
      width={42}
      height={42}
      objectFit="contain"
      alt={alt}
      className=""
    />
  );
};

const Title = ({ children }) => {
  return <span className="font-bold text-3xl">{children}</span>;
};

const Timestamp = ({ children }) => {
  return <span className="text-gray-600">{children}</span>;
};

export default Object.assign(ReviewCard, {
  Stars,
  Content,
  Header,
  Title,
  Avatar,
  Timestamp,
});
