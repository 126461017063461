import {
  Root,
  Trigger,
  Content,
  CollapsibleProps,
} from "@radix-ui/react-collapsible";
import { PropsWithChildren } from "react";
import { createSlots } from "utils/create-slots";

const getVariant = (variant) => {
  switch (variant) {
    case "mobile":
      return "closed:hidden lg:!block";

    default:
      return "closed:hidden block";
  }
};

export const Collapsible: React.FC<
  CollapsibleProps &
    React.RefAttributes<HTMLDivElement> &
    PropsWithChildren<{
      variant: string;
    }>
> = ({ children, variant, ...props }) => {
  const [trigger, content] = createSlots(["trigger", "content"], children);

  return (
    <Root {...props}>
      {trigger}
      <Content forceMount={true} className={`${getVariant(variant)} `}>
        {content}
      </Content>
    </Root>
  );
};

export default Object.assign(Collapsible, { Trigger, Content });
