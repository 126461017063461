import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconShareArrow: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M2 20.65c-.09 0-.18-.01-.27-.04a.996.996 0 0 1-.73-.96c0-3.2.23-6.84 3.02-9.65 2.67-2.7 6.31-3.28 8.75-3.32V4.71c0-.34.1-.66.28-.94.18-.28.44-.5.75-.63.32-.14.66-.17.99-.1.33.06.62.23.86.46l6.54 6.59a2.784 2.784 0 0 1 .8 2.09c-.02.39-.11.76-.28 1.11-.17.35-.41.65-.7.9l-6.46 5.43c-.25.21-.55.34-.87.38-.33.04-.65-.01-.95-.16a1.704 1.704 0 0 1-.96-1.54v-2.13c-7.23-.22-9.81 3.82-9.92 4-.19.3-.51.48-.85.48Zm10.22-6.48c.52 0 1.06.02 1.63.06.52.04.92.47.92 1v2.42l5.95-5c.08-.07.15-.15.2-.25.05-.1.07-.2.08-.31 0-.11-.01-.22-.05-.33-.04-.1-.1-.19-.17-.26l-6.01-6.05v2.24a1.01 1.01 0 0 1-1.05 1c-2-.09-5.74.15-8.29 2.72-1.59 1.6-2.15 3.58-2.34 5.64 1.7-1.36 4.61-2.88 9.12-2.88h.01Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconShareArrow.displayName = "share-arrow";
export default IconShareArrow;
