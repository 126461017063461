import { Icon } from "@/components/Icon";
import {
  Content as PrimitiveContent,
  Trigger as PrimitiveTrigger,
} from "@radix-ui/react-tabs";
import IconsEnum from "enums/icons";
import { Fragment } from "react";

import { List, PrimitiveRoot } from "../Tabs";

const OpenCloseIcon = ({ className = "" }) => (
  <div
    className={`h-[27px] w-full flex justify-center items-center self-end ${className}`}
  >
    <div className="absolute w-[27px] h-[3px] bg-blue-dark group-hover:bg-white group-active:bg-white transition"></div>
    <div className="absolute w-[27px] h-[3px] bg-blue-dark group-hover:bg-white group-active:bg-white transition rotate-90 group-active:rotate-0"></div>
  </div>
);

const Trigger = ({ children, value, icon }) => {
  return (
    <PrimitiveTrigger
      value={value}
      className="group w-full p-8 bg-white hover:bg-blue-light active:bg-blue-light flex flex-wrap lg:justify-center items-start hover:text-white active:text-white shadow-md transition-all text-start lg:text-center"
    >
      {icon && IconsEnum[icon] && (
        <div className="rounded-full w-[56px] h-[56px] lg:w-[72px] lg:h-[72px] lg:mb-6 bg-blue-dark flex items-center justify-center group-active:bg-[#661B394E] mr-6 lg:mr-0">
          <Icon
            type={IconsEnum[icon]}
            purposeLabel="Zu Tab wechseln"
            className="w-[20px] h-[20px] text-green-light group-active:text-white group-hover:text-white"
          />
        </div>
      )}
      {children}
      <OpenCloseIcon className="mt-6" />
    </PrimitiveTrigger>
  );
};

const Content = ({ children, value, className = "" }) => {
  return (
    <PrimitiveContent value={value} className={className}>
      {children}
    </PrimitiveContent>
  );
};

const TopTab = ({ components }) => {
  return (
    <PrimitiveRoot defaultValue={components[0]?.id} className="">
      <List
        className="w-full flex flex-wrap lg:flex-nowrap gap-6 mb-6"
        data-orientation="vertical"
      >
        {components.map((component) => (
          <Fragment key={`tab-trigger-${component.id}`}>
            <Trigger value={component.id} icon={component.icon}>
              <p className="lg:w-full text-3xl font-bold my-auto lg:mb-auto">
                {component.title}
              </p>
              {component.subheader && (
                <p className="w-full text-sm group-active:text-white group-hover:text-white text-gray-600 mt-6">
                  {component.subheader}
                </p>
              )}
            </Trigger>

            <Content
              value={component.id}
              key={component.id}
              className="hidden next-to-active:block lg:next-to-active:hidden w-full"
            >
              {component.content}
            </Content>
          </Fragment>
        ))}
      </List>
      {components.map((component) => (
        <Content
          value={component.id}
          key={`tab-content-${component.id}`}
          className="hidden lg:active:block"
        >
          {component.content}
        </Content>
      ))}
    </PrimitiveRoot>
  );
};

export default Object.assign(TopTab, { Trigger, Content });
