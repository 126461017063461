import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const PhotoIcon: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 35 31"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.7 2.7c.5-1.3 1.8-2 3-2H22c1.3 0 2.6.7 3.1 2l1.5 3h4.3c1.9 0 3.4 1.6 3.4 3.5v17.3c0 2-1.5 3.5-3.4 3.5H3.8c-2 0-3.5-1.5-3.5-3.5V9.2c0-2 1.6-3.5 3.5-3.5h4.3l1.6-3Zm3 0c-.6 0-1 .3-1.2.8L9.6 7.3a1 1 0 0 1-.9.5H3.8c-.8 0-1.5.7-1.5 1.5v17.3c0 .9.7 1.5 1.5 1.5h27c.9 0 1.5-.6 1.5-1.5V9.2c0-.8-.6-1.5-1.4-1.5h-5a1 1 0 0 1-.9-.6l-1.7-3.6c-.2-.5-.6-.8-1.3-.8h-9.2Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M17.8 10.3c-3.3 0-6 2.8-6 6.5s2.7 6.5 6 6.5 6-2.8 6-6.5-2.7-6.5-6-6.5Zm-8 6.5c0-4.7 3.5-8.5 8-8.5s8 3.8 8 8.5c0 4.6-3.5 8.5-8 8.5s-8-3.9-8-8.5Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

PhotoIcon.displayName = "photo";
export default PhotoIcon;
