import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconTrade: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 19 19"
        className={className}
      >
        <path
          fill="currentColor"
          d="M15.6 8.159V8.03a5.962 5.962 0 0 0-4.193-5.715h-.045a6.24 6.24 0 0 0-1.942-.27A6.158 6.158 0 0 0 3.6 8.16a2.25 2.25 0 0 0 0 4.245 6 6 0 0 0 6 5.377h.172a6.098 6.098 0 0 0 5.76-5.37A2.25 2.25 0 0 0 15.6 8.16ZM9.735 16.28a4.5 4.5 0 0 1-4.568-3.75h8.828a4.567 4.567 0 0 1-4.26 3.75Zm5.115-5.25H4.35a.75.75 0 0 1 0-1.5h1.5a.75.75 0 1 0 0-1.5H5.1a4.8 4.8 0 0 1 2.25-3.86v2.36a.75.75 0 1 0 1.5 0V3.6c.204-.034.409-.057.615-.068H9.6c.251.004.502.026.75.068v2.93a.75.75 0 0 0 1.5 0V4.146c.318.184.615.403.885.653A4.5 4.5 0 0 1 14.1 8.03h-.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 1 1 0 1.5Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconTrade.displayName = "trade";
export default IconTrade;
