import Image, { ImageProps } from "next/image";
import { FC } from "react";
const cloudfrontLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number | string;
  quality?: number;
}) => {
  let sharpConfig = {
    key: src,
    edits: {
      resize: {
        width,
        height: width,
        fit: "inside",
      },
      ["jpeg"]: {
        quality,
      },
      ["webp"]: {
        quality,
      },
      ["avif"]: {
        quality,
      },
    },
  };

  const query = Buffer.from(JSON.stringify(sharpConfig)).toString("base64");

  return `${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGE_URL}/${query}`;
};

const CDNImage: FC<ImageProps & { sizesObj?: { [key: string]: string } }> = ({
  src,
  width,
  height,
  quality = 80,
  alt,
  sizes,
  sizesObj = {},
  priority = false,
  ...props
}) => {
  return (
    <Image
      loader={({ src, width, quality }) =>
        cloudfrontLoader({
          src,
          width: getInt(width)!,
          quality,
        })
      }
      quality={quality}
      width={width}
      height={height}
      src={src}
      alt={alt}
      sizes={
        sizes ||
        Object.entries(sizesObj)
          .map(([media, size]) => {
            return `${media} ${size}`;
          })
          .join(", ")
      }
      priority={priority}
      {...props}
    />
  );
};

export default CDNImage;

function getInt(x: unknown): number | undefined {
  if (typeof x === "number" || typeof x === "undefined") {
    return x;
  }
  if (typeof x === "string" && /^[0-9]+$/.test(x)) {
    return parseInt(x, 10);
  }
  return NaN;
}
