import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconLength: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="m22.783 4.172-.333-.336c-.857-.859-1.741-1.748-2.628-2.616a2.593 2.593 0 0 0-.884-.589c-.848-.294-1.644-.097-2.357.627-5 4.998-9.996 9.991-14.985 14.981-.445.444-.952.948-1.096 1.73v.433c.118.768.59 1.292 1.084 1.766.757.736 1.512 1.492 2.248 2.249.472.485.996.968 1.789 1.083h.344c.758-.109 1.28-.589 1.748-1.05 2.204-2.214 4.41-4.423 6.62-6.629l3.895-3.89 4.552-4.554a2.149 2.149 0 0 0 .003-3.205ZM4.33 18.479a.867.867 0 0 0 .622-.45.882.882 0 0 0-.2-1.104l-.655-.639 1.011-1.006.256.262a.98.98 0 0 0 .699.309.84.84 0 0 0 .589-.277.882.882 0 0 0-.059-1.266c-.088-.09-.18-.18-.268-.264L7.33 13.04c.218.224.442.45.668.674a.95.95 0 0 0 .929.241.79.79 0 0 0 .55-.603 1.073 1.073 0 0 0-.244-.868 13.274 13.274 0 0 0-.672-.677L9.57 10.8c.068.074.138.144.21.218a1.04 1.04 0 0 0 .742.353.822.822 0 0 0 .589-.274c.362-.37.324-.9-.091-1.312a5 5 0 0 0-.225-.212l1.014-1.004c.194.197.395.403.59.606a1.057 1.057 0 0 0 .754.339.832.832 0 0 0 .589-.274c.345-.368.295-.883-.11-1.307-.194-.197-.391-.391-.588-.588l1.001-1.007c.074.08.154.16.233.239a.884.884 0 0 0 1.27.047.864.864 0 0 0 0-1.269 6.135 6.135 0 0 0-.277-.277l.999-1.01.59.59c.526.526 1.048.391 1.334.114a.839.839 0 0 0 .262-.588 1.076 1.076 0 0 0-.348-.75l-.624-.616c.165-.186.341-.362.527-.527a.315.315 0 0 1 .404.044c1.199 1.178 2.239 2.22 3.176 3.176.062.062.244.25-.082.589-.44.445-.881.888-1.323 1.327L6.189 21.425l-.097.097c-.253.242-.445.244-.683 0a415.763 415.763 0 0 1-3.009-3.01c-.226-.23-.22-.416 0-.66l.372-.386c.238.245.471.483.71.713a.919.919 0 0 0 .848.3Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconLength.displayName = "length";
export default IconLength;
