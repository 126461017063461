import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPhone: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -1 26 26"
        fill="currentColor"
      >
        <path d="M23.46,19l-3.7-3.71a1.93,1.93,0,0,0-2.73.05l-1.87,1.87L14.79,17a18.7,18.7,0,0,1-4.49-3.25A18.73,18.73,0,0,1,7.05,9.22l-.2-.36L8.1,7.6,8.72,7a2,2,0,0,0,0-2.74L5.06.54a1.92,1.92,0,0,0-2.72,0l-1,1,0,0A6.29,6.29,0,0,0,.46,3.19,6,6,0,0,0,.08,4.74C-.41,8.8,1.44,12.52,6.47,17.56c7,7,12.56,6.45,12.8,6.42a6.08,6.08,0,0,0,1.55-.39,5.91,5.91,0,0,0,1.51-.86l0,0,1.06-1A1.94,1.94,0,0,0,23.46,19Z" />
      </svg>
    </ConditionalWrapper>
  );
};
IconPhone.displayName = "phone";
export default IconPhone;
