import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSuv: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 67.82 31.06"
        className={className}
      >
        <g>
          <g>
            <path
              fill="currentColor"
              d="M66.4,13.78a13.48,13.48,0,0,0,.21-2.87c-.33-3.31-3.3-5.71-4.91-6.78l.37-1.48a1.54,1.54,0,0,0-.23-1.23A1.54,1.54,0,0,0,60.75.79,140.6,140.6,0,0,0,42.81,0c-1.49,0-3.15,0-5.12,0-7.88,0-13.36,3.43-16.63,5.49a22.85,22.85,0,0,1-2.21,1.29C16.38,8,13.54,8.59,8.85,9.59l-2,.42C2,11.06.81,14.48.76,14.63a1.56,1.56,0,0,0-.06.68L1,17.08a6.65,6.65,0,0,0-.27,4.07A4.58,4.58,0,0,0,0,23.89,1.51,1.51,0,0,0,.69,25.1c1.9,1.23,5,1.37,6.1,1.39a6.24,6.24,0,0,0,11.83.58l29.52-.73a6.23,6.23,0,0,0,12,.17A28.6,28.6,0,0,0,64.87,26a1.52,1.52,0,0,0,.81-.45C69.49,21.38,67.2,15.5,66.4,13.78ZM12.8,28.06a3.25,3.25,0,1,1,3.25-3.25A3.26,3.26,0,0,1,12.8,28.06Zm41.39,0a3.25,3.25,0,1,1,3.25-3.25A3.25,3.25,0,0,1,54.19,28.06Zm9.58-4.92a24,24,0,0,1-3.47.37,6.25,6.25,0,0,0-12.18-.17L19,24.06a6.25,6.25,0,0,0-12.31-.57A13.32,13.32,0,0,1,3.28,23a1.46,1.46,0,0,0,.31-.3,1.52,1.52,0,0,0,.24-1.32,6.14,6.14,0,0,1-.25-2.88A1.49,1.49,0,0,0,4,17.22l-.28-1.9A5.35,5.35,0,0,1,7.47,13l2-.43c4.9-1,7.86-1.68,10.64-3a26,26,0,0,0,2.55-1.47c3.19-2,8-5,15-5,2,0,3.65,0,5.14,0a133.6,133.6,0,0,1,15.9.6l-.21.85a1.49,1.49,0,0,0,.75,1.69c1.13.6,4.12,2.68,4.35,5a13.9,13.9,0,0,1-.25,2.54,1.51,1.51,0,0,0,.15,1S66.25,19.86,63.77,23.14Z"
            />
          </g>
        </g>
      </svg>
    </ConditionalWrapper>
  );
};
IconSuv.displayName = "suv";
export default IconSuv;
