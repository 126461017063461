import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPrint: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.455 3.818v4.546h9.09V3.818h-9.09ZM4.727 8.364h.91V2.909A.91.91 0 0 1 6.544 2h10.91a.91.91 0 0 1 .909.91v5.454h.909A2.727 2.727 0 0 1 22 11.09v4.545a2.727 2.727 0 0 1-2.727 2.728h-.91v2.727a.91.91 0 0 1-.909.909H6.545a.91.91 0 0 1-.909-.91v-2.726h-.909A2.727 2.727 0 0 1 2 15.636v-4.545a2.727 2.727 0 0 1 2.727-2.727Zm2.728 9.09v2.728h9.09v-5.455h-9.09v2.727Zm10.909-.909v-2.727a.91.91 0 0 0-.91-.909H6.545a.91.91 0 0 0-.909.91v2.726h-.909a.91.91 0 0 1-.909-.909v-4.545a.91.91 0 0 1 .91-.91h14.545a.91.91 0 0 1 .909.91v4.545a.91.91 0 0 1-.91.91h-.908Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconPrint.displayName = "print";
export default IconPrint;
