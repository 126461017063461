import Image from "@/components/Image/Image";
import { Icon } from "@/components/Icon";
import Separator from "@/components/Separator/Separator";
import * as AspectRatioPrimitive from "@radix-ui/react-aspect-ratio";

const DefaultTeaser = ({ width, imgSrc, header, subheader, linkText }) => {
  return (
    <div className="relative max-w-[540px] w-full h-full flex flex-col">
      <AspectRatioPrimitive.Root ratio={4 / 3}>
        <Image
          src={imgSrc}
          alt="Forest"
          layout="fill"
          objectFit="cover"
          loading="lazy"
        />
      </AspectRatioPrimitive.Root>
      <div className="flex flex-col w-full bg-black text-white py-6 px-7 flex-grow">
        <p className="font-bold text-4xl ">{header}</p>
        <p className="text-base mb-6">{subheader}</p>
        <Separator
          className="!bg-white opacity-40 !h-2 mt-auto"
          orientation="horizontal"
        />

        <div className="flex items-center group justify-end pb-4 pt-7">
          <p className="text-white font-bold text-base mr-3">{linkText}</p>
          <Icon
            type="chevron"
            className="w-5 h-5 text-white group-hover:translate-x-2"
            purposeLabel="Mehr erfahren"
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultTeaser;
