import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconThumbUp: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M26.625 12.6a3.75 3.75 0 0 0-2.875-1.35h-5.7l.7-1.787A5.163 5.163 0 0 0 13.887 2.5a1.25 1.25 0 0 0-1.137.738L9.187 11.25H6.25A3.75 3.75 0 0 0 2.5 15v8.75a3.75 3.75 0 0 0 3.75 3.75h15.913a3.75 3.75 0 0 0 3.687-3.075l1.587-8.75a3.75 3.75 0 0 0-.812-3.075ZM8.75 25h-2.5A1.25 1.25 0 0 1 5 23.75V15a1.25 1.25 0 0 1 1.25-1.25h2.5V25ZM25 15.225l-1.587 8.75A1.25 1.25 0 0 1 22.163 25H11.25V12.763l3.4-7.65a2.638 2.638 0 0 1 1.725 3.475l-.662 1.787a2.5 2.5 0 0 0 2.337 3.375h5.7a1.25 1.25 0 0 1 .962.45A1.25 1.25 0 0 1 25 15.225Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconThumbUp.displayName = "thumb-up";

export default IconThumbUp;
