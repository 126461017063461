import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconElectro: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.3 45.3"
        className={className}
      >
        <path
          fill="currentColor"
          d="M64.3 8.6c1.2.4 1.8 1.7 1.3 2.9-.7 1.6-1.3 3-1.8 4.6 0 0 0 .1-.1.3.1 0 .1.1.1.1 1.2.7 2.4 1.3 3.6 2 1.5.8 1.6 2.5.4 3.6-2.6 2.5-5.4 5.2-8.1 7.7-1.3 1.2-3.3.7-3.7-.9-.1-.5-.1-.9.1-1.5.7-1.5 1.3-3 1.8-4.5 0 0 0-.1.1-.3-.1 0-.1-.1-.3-.1l-3.4-2c-1.3-.8-1.5-2.5-.3-3.6 2.6-2.5 5.4-5.2 8.1-7.7.2-.2.7-.5 1.2-.7.4 0 .9.1 1 .1z"
        />
        <path
          fill="currentColor"
          d="M110.8 18.9c.4-1.5.2-3.2-.6-4.6-1.7-2.7-3.9-5-6.5-6.9l.6-2.4c.2-.7 0-1.5-.4-2-.4-.6-1.1-1-1.8-1l-8.7-.8C85 .2 76.6-.2 68.2 0c-.8 0-1.7 0-2.5.1L54.9.9h-.8c-11.6 1.5-20 6.3-24.9 9.2-1.3.7-2.4 1.4-3.2 1.8a23.5 23.5 0 0 1-7.3 2.1c-.2 0-.5.1-.8.1-1.6.3-3.9.7-6.8 1.3-7.1 1.5-9.2 3.8-9.6 4.5-.3.6-.5 1.4-.3 2.1l.3 1.1C.8 25 .6 27 1.1 28.9.3 30.2-.1 31.8 0 33.4c0 .8.5 1.6 1.1 2 3.1 2 8.3 2.3 10 2.3 1.2 4.3 5 7.3 9.4 7.5s8.5-2.4 10-6.6l24.2-.4c.2 0-.2 0 0 0l28.9-.5c1.2 4.5 5.2 7.6 9.7 7.6s8.7-2.9 9.9-7.3c2.6-.1 5.2-.4 7.7-.9.9-.2 1.6-.8 1.9-1.7 3.4-8.6 0-14.2-2-16.5zM21 40.2c-3 0-5.4-2.4-5.4-5.3s2.4-5.4 5.4-5.4c3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.3-5.4 5.3zm72.9 0c-3 0-5.4-2.4-5.4-5.3s2.4-5.4 5.4-5.4c3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.3-5.4 5.3zm15-7.6c-1.7.3-3.4.5-5.1.5-1-4.7-5.1-8.2-9.9-8.2-4.8-.1-9.1 3.2-10.1 7.9l-28.9.4h-2.7l-21 .6A10.25 10.25 0 0 0 11 33c-1.9 0-3.8-.3-5.6-.8.2-.2.4-.4.5-.6.5-.6.6-1.5.3-2.2-.5-1.2-.6-2.5-.4-3.8.7-.6.9-1.6.7-2.5l-.2-.6c1.9-1 3.9-1.6 5.9-2 3.4-.7 5.7-1.1 7.4-1.4 3-.4 5.8-1.2 8.5-2.5.9-.4 2-1.1 3.4-1.8.1 0 .1-.1.2-.1h.1c4.9-2.8 12.4-7.1 22.8-8.8 3.3-.4 11.4-.8 11.4-.8h2.3c8.3-.2 16.5.2 24.7 1.2l5.9.6-.4 1.4c-.3 1.1.3 2.3 1.3 2.8 2.5 1.5 4.7 3.7 6.2 6.2 0 .6-.1 1.1-.3 1.7-.4 1-.1 2.1.7 2.8 2.6 2.8 3.6 7 2.5 10.8z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconElectro.displayName = "electric";
export default IconElectro;
