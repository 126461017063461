import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSpeakerFull: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M.25 14.863a1 1 0 0 0 1 1h3.977a1 1 0 0 1 .707.293l4.739 4.74c.63.63 1.707.183 1.707-.708V3.452c0-.89-1.077-1.337-1.707-.707L5.934 7.484a1 1 0 0 1-.707.293H1.25a1 1 0 0 0-1 1v6.086Zm16.162-7.53c-.556-.493-1.336-.03-1.336.714v7.604c0 .73.755 1.194 1.307.719a5.996 5.996 0 0 0 2.062-4.55 5.997 5.997 0 0 0-2.034-4.486Zm-2.096-5.302c0 .442.292.827.705.986a9.441 9.441 0 0 1 6.033 8.803 9.441 9.441 0 0 1-6.033 8.803c-.413.16-.705.544-.705.986v.779c0 .641.597 1.118 1.205.912 4.787-1.624 8.229-6.15 8.229-11.48S20.308 1.964 15.52.34c-.607-.206-1.204.27-1.204.912v.779Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSpeakerFull.displayName = "speaker-full";
export default IconSpeakerFull;
