import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconBusiness: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        className={className}
      >
        <path
          fill="currentColor"
          d="M26.667 12.894v-.226a10.6 10.6 0 0 0-7.453-10.16h-.08a11.093 11.093 0 0 0-3.454-.48A10.947 10.947 0 0 0 5.334 12.894a4 4 0 0 0 0 7.547A10.666 10.666 0 0 0 16 30h.307a10.84 10.84 0 0 0 10.24-9.547 4 4 0 0 0 .12-7.56ZM16.24 27.334a8 8 0 0 1-8.12-6.666h15.694a8.12 8.12 0 0 1-7.574 6.666Zm9.094-9.333H6.667a1.333 1.333 0 0 1 0-2.667h2.667a1.334 1.334 0 0 0 0-2.666H8A8.534 8.534 0 0 1 12 5.8V10a1.334 1.334 0 0 0 2.667 0V4.788c.362-.06.727-.101 1.093-.12H16c.447.006.893.046 1.334.12V10A1.333 1.333 0 0 0 20 10V5.76a8.863 8.863 0 0 1 1.574 1.16A8 8 0 0 1 24 12.668h-1.333a1.333 1.333 0 1 0 0 2.666h2.667a1.334 1.334 0 0 1 0 2.667Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconBusiness.displayName = "business";

export default IconBusiness;
