import s from "./SeoText.module.css";

const SeoText = ({ content }) => {
  const {
    text: textTop,
    textLeft,
    textRight
  } = content;

  return (
    <div className={`${s.SeoText} container mx-auto py-8 lg:py-16`}>
      <div className="grid grid-cols-2 gap-9 gap-y-12">
        <div
          className="col-span-2"
          dangerouslySetInnerHTML={{__html: textTop}}
        />
        <div
          className="col-span-2 lg:col-span-1"
          dangerouslySetInnerHTML={{__html: textLeft}}
        />
        <div
          className="col-span-2 lg:col-span-1"
          dangerouslySetInnerHTML={{__html: textRight}}
        />
      </div>
    </div>
  );
};

export default Object.assign(SeoText);
