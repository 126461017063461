import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconDownload: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M17.64 10.34a1.2 1.2 0 0 0-1.7 0L13.2 13.1V3.2c0-.66-.52-1.2-1.19-1.2-.66 0-1.2.54-1.2 1.2v9.88l-2.75-2.75a1.2 1.2 0 1 0-1.7 1.7l4.77 4.76c.22.23.53.38.87.38.31 0 .62-.11.85-.35l4.79-4.8a1.2 1.2 0 0 0 0-1.69l.01.01ZM3 22.17a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2H3Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconDownload.displayName = "download";
export default IconDownload;
