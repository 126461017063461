import { Root } from "@radix-ui/react-separator";
import { FC } from "react";

const Separator: FC<{
  orientation?: "horizontal" | "vertical";
  className?: string;
}> = ({ orientation = "horizontal", className = "" }) => {
  return (
    <Root
      className={`bg-gray-900 ${
        orientation === "horizontal" ? "h-[1px] w-full" : "w-[1px] h-full"
      } ${className}`}
      orientation={orientation}
    />
  );
};

export default Separator;
