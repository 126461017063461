import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPrivate: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        className={className}
      >
        <path
          fill="currentColor"
          d="m26.667 10.667-8-7.014a4 4 0 0 0-5.334 0l-8 7.014A4 4 0 0 0 4 13.68v11.653a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V13.667a4 4 0 0 0-1.333-3Zm-8 16h-5.334V20a1.333 1.333 0 0 1 1.334-1.333h2.667A1.333 1.333 0 0 1 18.666 20v6.667Zm6.666-1.334A1.333 1.333 0 0 1 24 26.667h-2.666V20a4 4 0 0 0-4-4h-2.667a4 4 0 0 0-4 4v6.667H8a1.333 1.333 0 0 1-1.333-1.334V13.667a1.334 1.334 0 0 1 .453-1l8-7a1.333 1.333 0 0 1 1.76 0l8 7a1.334 1.334 0 0 1 .454 1v11.666Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconPrivate.displayName = "private";
export default IconPrivate;
