export const formatPrice = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(value);
};

export const formatKm = (km) =>
  new Intl.NumberFormat("de-DE", {
    style: "unit",
    unit: "kilometer",
    minimumFractionDigits: 0,
  }).format(km);

export const formatPercentage = (num) => {
  return new Intl.NumberFormat("de-DE", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
};
