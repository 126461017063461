import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPlus: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M22.41,10.49H13.59V1.67a1.59,1.59,0,0,0-3.18,0v8.82H1.59a1.59,1.59,0,0,0,0,3.18h8.82v8.82a1.59,1.59,0,0,0,3.18,0V13.67h8.82a1.59,1.59,0,0,0,0-3.18Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconPlus.displayName = "plus";
export default IconPlus;
