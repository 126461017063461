"use client";

import Collapsible from "@/components/Collapsible/Collapsible";
import Honeycomb from "@/components/Masks/Honeycomb";
import Slider from "@/components/Slider/Slider";
import Product from "@/modules/Product/Product";
import { useState } from "react";
import { Slot } from "utils/create-slots";
import Headlines from "../Headlines/Headlines";
import Link from "@/components/Link/Link";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";

let cars = [];

const LastSeen = ({ content }) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);

  useIsomorphicLayoutEffect(() => {
    if (typeof localStorage !== "undefined") {
      cars = JSON.parse(localStorage.getItem("lastSeenCarData") || "[]");
      setProducts(cars);
    }
  }, []);

  if (products.length <= 0) return null;
  return (
    <>
      <div
        className="relative  z-0  overflow-hidden bg-gray-100 lg:pb-8"
        style={{ contentVisibility: "auto" }}
      >
        <Honeycomb className="absolute top-0 lg:right-0 h-full  z-0 text-gray-300 lg:opacity-100 opacity-0 " />
        <div className={`mx-auto container lg:mt-14 mt-8 relative`}>
          <Headlines {...content} />
          <div className="w-full l py-7  grid  grid-cols-2 gap-4 lg:hidden">
            {products.slice(0, 2).map((item, index) => (
              <Link href={(item as any)?.link?.detail} key={index}>
                <Product
                  className="cursor-pointer"
                  item={item}
                  mediaSizes={{
                    "(max-width: 1023px)": "50vw",
                    "": "33vw",
                  }}
                />
              </Link>
            ))}
          </div>

          <Collapsible
            onOpenChange={setOpen}
            variant="mobile"
            className="lg:hidden"
          >
            <Slot name="content">
              <div className="container">
                <div className="w-full l py-7  grid  grid-cols-2 gap-4 ">
                  {products.slice(2, cars.length).map((item, index) => (
                    <Link href={(item as any)?.link?.detail} key={index}>
                      <Product
                        key={index}
                        item={item}
                        mediaSizes={{
                          "(max-width: 1023px)": "50vw",
                          "": "33vw",
                        }}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </Slot>

            <Slot name="trigger">
              {products.length > 2 && (
                <Collapsible.Trigger className=" w-full block lg:hidden text-blue-light hover:text-blue-medium underline py-8 container">
                  {open ? "weniger lesen" : "mehr lesen"}
                </Collapsible.Trigger>
              )}
            </Slot>
          </Collapsible>

          <Slider
            aria-label="Stage Slider"
            className="hidden lg:flex"
            options={{
              perPage: 3,
              perMove: 1,
              autoHeight: true,
              pagination: false,
              arrows: products.length > 3,
              gap: 7,
              breakpoints: {
                1024: {
                  perPage: 3,
                },
                640: {
                  perPage: 1,
                },
              },
            }}
          >
            {products.map((item, index) => (
              <Slider.Slide key={index} className="px-5 py-8">
                <Link href={(item as any)?.link?.detail} key={index}>
                  <Product item={item} />
                </Link>
              </Slider.Slide>
            ))}
            <Slot name="arrows">
              <Slider.Arrows variant="blue" position="outside" />
            </Slot>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default LastSeen;
