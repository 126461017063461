import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconMoon: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.015 2.79a1.123 1.123 0 0 0-1.006-1.785h-.003v-.003a11.023 11.023 0 0 0-1.382 21.739A11.024 11.024 0 0 0 23 12.996h-.003v-.003a1.123 1.123 0 0 0-1.785-1.006 6.578 6.578 0 0 1-8.559-.636 6.577 6.577 0 0 1-.638-8.561ZM6.423 18.741a8.779 8.779 0 0 0 13.834-3.726A8.824 8.824 0 0 1 8.503 7.354a8.822 8.822 0 0 1 .484-3.609 8.778 8.778 0 0 0-2.564 14.996Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconMoon.displayName = "moon";
export default IconMoon;
