import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconFile: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        className={className}
      >
        <path
          fill="currentColor"
          d="M11.999 13.333h1.333a1.333 1.333 0 1 0 0-2.666h-1.333a1.333 1.333 0 1 0 0 2.666Zm0 2.667a1.333 1.333 0 0 0 0 2.667h8a1.333 1.333 0 1 0 0-2.667h-8Zm14.666-4.08a1.746 1.746 0 0 0-.08-.36v-.12a1.429 1.429 0 0 0-.253-.373l-8-8a1.426 1.426 0 0 0-.373-.254.427.427 0 0 0-.12 0 1.173 1.173 0 0 0-.44-.146H9.332a4 4 0 0 0-4 4v18.666a4 4 0 0 0 4 4h13.333a4 4 0 0 0 4-4V11.92Zm-8-4.707 3.454 3.454h-2.12a1.333 1.333 0 0 1-1.334-1.334v-2.12ZM24 25.333a1.333 1.333 0 0 1-1.334 1.334H9.332a1.333 1.333 0 0 1-1.333-1.334V6.667a1.333 1.333 0 0 1 1.333-1.334h6.667v4a4 4 0 0 0 4 4h4v12Zm-4-4h-8A1.333 1.333 0 0 0 12 24h8a1.333 1.333 0 0 0 0-2.667Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFile.displayName = "file";
export default IconFile;
