import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconWhatsapp: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m2 22 1.413-5.136a9.853 9.853 0 0 1-1.33-4.955C2.087 6.446 6.554 2 12.043 2a9.918 9.918 0 0 1 7.044 2.907A9.83 9.83 0 0 1 22 11.918c-.003 5.465-4.47 9.91-9.958 9.91a10 10 0 0 1-4.763-1.206L2 22Zm5.524-3.173c1.404.83 2.743 1.326 4.515 1.327 4.562 0 8.278-3.695 8.28-8.238.002-4.551-3.697-8.241-8.273-8.243-4.565 0-8.279 3.695-8.28 8.237-.001 1.854.545 3.242 1.462 4.695l-.837 3.04 3.133-.818Zm9.194-4.775c.174.083.29.14.34.222.063.104.063.6-.144 1.179-.208.578-1.202 1.106-1.68 1.177-.429.063-.971.09-1.567-.098-.361-.114-.825-.267-1.419-.522-2.328-1-3.903-3.245-4.205-3.675l-.045-.064-.003-.004c-.134-.179-1.013-1.348-1.013-2.558 0-1.139.562-1.736.821-2.012l.05-.054a.914.914 0 0 1 .663-.31l.478.009h.055c.145 0 .326-.002.505.425l.253.611c.219.532.47 1.142.514 1.227.063.124.104.27.02.434l-.035.072c-.063.127-.108.22-.215.34l-.128.154a3.977 3.977 0 0 1-.246.28c-.124.124-.254.258-.109.506.145.248.645 1.06 1.384 1.716.794.705 1.485 1.003 1.836 1.155.069.03.125.053.165.074.25.124.395.103.54-.062.145-.165.622-.723.788-.97.166-.249.332-.207.56-.125.228.083 1.452.682 1.7.806l.137.067Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconWhatsapp.displayName = "whatsapp";
export default IconWhatsapp;
