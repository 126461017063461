import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconHeartBeat: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M26.25 13.013h-3.125a1.251 1.251 0 0 0-.887.375L20.688 15l-3.5-3.987a1.248 1.248 0 0 0-1.825 0l-2.125 2.125H11.25a1.25 1.25 0 0 0 0 2.5h2.5a1.251 1.251 0 0 0 .887-.363L16.25 13.6l3.5 3.988a1.25 1.25 0 0 0 .9.425 1.251 1.251 0 0 0 .888-.363l2.125-2.137h2.587a1.25 1.25 0 0 0 0-2.5Zm-9.238 6.625-2.375 2.375a1.25 1.25 0 0 1-1.774 0L6.35 15.5a4.614 4.614 0 0 1 0-6.525 4.612 4.612 0 0 1 6.513 0 1.25 1.25 0 0 0 1.774 0 4.725 4.725 0 0 1 6.513 0c.279.282.522.597.725.938a1.25 1.25 0 0 0 2.15-1.25 7.495 7.495 0 0 0-1.1-1.413 7.1 7.1 0 0 0-9.175-.787 7.1 7.1 0 0 0-10.708 8.484 7.024 7.024 0 0 0 1.546 2.29L11.1 23.75a3.75 3.75 0 0 0 5.3 0l2.35-2.35a1.25 1.25 0 0 0 0-1.762 1.25 1.25 0 0 0-1.738 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconHeartBeat.displayName = "heart-beat";

export default IconHeartBeat;
