import { cva } from "class-variance-authority";
import Image from "next/image";
import { FC } from "react";

const ArtDirectionImage: FC<{
  image: string;
  imageTablet?: string;
  imageMobile?: string;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  priority?: boolean;
  alt: string;
  className?: string;
}> = ({
  image,
  imageTablet,
  imageMobile,
  fill = true,
  sizes = "100vw",
  priority = false,
  alt,
  className = "",
}) => {
  if (!image && !imageMobile && !imageTablet) return null;
  const imageStyles = cva(`w-screen h-full object-cover ${className}`, {
    variants: {
      is: {
        image: "!hidden lg:!block",
        imageTablet: "!hidden md:!block lg:!hidden",
        imageMobile: "!block md:!hidden",
      },
      imageTablet: {
        true: "",
        false: "",
      },
      imageMobile: {
        true: "",
        false: "",
      },
      image: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        imageTablet: false,
        imageMobile: false,
        is: "image",
        class: "!block",
      },
      {
        image: false,
        imageMobile: false,
        is: "imageTablet",
        class: "!block",
      },
      {
        imageTablet: false,
        image: false,
        is: "imageMobile",
        class: "!block",
      },
      {
        image: true,
        is: "imageTablet",
        class: "!block lg:!hidden",
      },
      {
        imageTablet: false,
        image: true,
        is: "imageMobile",
        class: "!block lg:!hidden",
      },
    ],
    defaultVariants: {
      imageTablet: false,
      imageMobile: false,
      image: true,
      is: "image",
    },
  });
  return (
    <>
      <Image
        alt={alt}
        className={`${imageStyles({
          image: !!image,
          imageMobile: !!imageMobile,
          imageTablet: !!imageTablet,
          is: "image",
        })} `}
        src={image}
        sizes={sizes}
        fill={fill}
        quality={95}
        priority={priority}
        loading={!priority ? "lazy" : "eager"}
      />
      {imageTablet && (
        <Image
          alt={alt}
          className={imageStyles({
            image: !!image,
            imageMobile: !!imageMobile,
            imageTablet: !!imageTablet,
            is: "imageTablet",
          })}
          src={imageTablet}
          priority={priority}
          loading={!priority ? "lazy" : "eager"}
          sizes={sizes}
          quality={55}
          fill={fill}
        />
      )}
      {imageMobile && (
        <Image
          className={imageStyles({
            image: !!image,
            imageMobile: !!imageMobile,
            imageTablet: !!imageTablet,
            is: "imageMobile",
          })}
          src={imageMobile}
          alt={alt}
          priority={priority}
          loading={!priority ? "lazy" : "eager"}
          sizes={sizes}
          fill={fill}
          quality={50}
        />
      )}
    </>
  );
};

export default ArtDirectionImage;
