import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const ConversationIcon: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 45 45"
        className={className}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeWidth=".6"
          d="M29.23 26.81a12.784 12.784 0 0 0 1.37-5.794v-.68c-.365-6.653-5.618-11.906-12.197-12.27l-.756-.001a12.786 12.786 0 0 0-5.796 1.373 12.951 12.951 0 0 0-7.158 11.58 12.785 12.785 0 0 0 1.124 5.272L3.4 33.54c-.356 1.066.659 2.08 1.724 1.725l7.251-2.417a12.79 12.79 0 0 0 5.275 1.124 12.952 12.952 0 0 0 11.58-7.163Zm-17.196 3.277-5.185 1.729 1.728-5.185a1.363 1.363 0 0 0-.076-1.046 10.063 10.063 0 0 1-1.081-4.563 10.228 10.228 0 0 1 5.654-9.147 10.07 10.07 0 0 1 4.569-1.083h.685c5.116.284 9.26 4.429 9.547 9.62l-.002.61a10.064 10.064 0 0 1-1.081 4.563 10.229 10.229 0 0 1-9.146 5.66 10.067 10.067 0 0 1-4.566-1.081 1.363 1.363 0 0 0-1.046-.077Z"
          clipRule="evenodd"
        />
        <path
          fill="#dbdcde"
          d="m35.125 27.851 3.803 1.268-1.268-3.803a1 1 0 0 1 .056-.767s1.975-1.03 2.215-1.673c.24-.643-1.422-1.673-1.422-1.673a7.5 7.5 0 0 0-4.147-6.707 7.385 7.385 0 0 0-3.35-.795h-.503c-3.751.209-6.79 3.248-7 7.055l.001.447a7.379 7.379 0 0 0 .793 3.346 7.501 7.501 0 0 0 6.707 4.15 7.383 7.383 0 0 0 3.348-.792 1 1 0 0 1 .767-.056Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeWidth=".8"
          d="M22.515 25.447a9.376 9.376 0 0 1-1.006-4.248V20.7c.268-4.879 4.12-8.731 8.944-8.997l.556-.002a9.378 9.378 0 0 1 4.25 1.008 9.498 9.498 0 0 1 4.425 12.357l1.772 5.317a1 1 0 0 1-1.265 1.265l-5.317-1.772a9.378 9.378 0 0 1-3.868.823 9.498 9.498 0 0 1-8.491-5.252Zm12.61 2.404 3.802 1.268-1.267-3.803a1 1 0 0 1 .056-.767 7.378 7.378 0 0 0 .792-3.346 7.5 7.5 0 0 0-4.146-6.707 7.384 7.384 0 0 0-3.35-.795h-.503c-3.751.209-6.79 3.248-7 7.055v.447a7.38 7.38 0 0 0 .793 3.346 7.5 7.5 0 0 0 6.707 4.15 7.382 7.382 0 0 0 3.349-.792 1 1 0 0 1 .767-.056Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

ConversationIcon.displayName = "conversation";
export default ConversationIcon;
