import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconStarFilled: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="m8.587 8.269 2.598-5.262a.909.909 0 0 1 1.63 0l2.598 5.262 5.808.849a.909.909 0 0 1 .503 1.55l-4.202 4.094.992 5.782a.909.909 0 0 1-1.32.959L12 18.77l-5.195 2.732a.909.909 0 0 1-1.319-.959l.992-5.782-4.202-4.093a.909.909 0 0 1 .503-1.551l5.808-.85Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconStarFilled.displayName = "star-filled";
export default IconStarFilled;
