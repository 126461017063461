import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconTemperature: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.97 18.747a4.583 4.583 0 0 0-1.47-4.865V4.916a2.916 2.916 0 1 0-5.833 0v8.966a4.583 4.583 0 1 0 7.302 4.865Zm-3.137-13.83v9.382c0 .279.14.538.37.693a2.916 2.916 0 1 1-3.24 0 .833.833 0 0 0 .37-.693V4.916a1.25 1.25 0 1 1 2.5 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconTemperature.displayName = "temperature";
export default IconTemperature;
