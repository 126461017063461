import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconMail: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M23.49,3.7a3.27,3.27,0,0,0-2.76-1.52H3.27A3.28,3.28,0,0,0,.49,3.73l11.51,8Z" />
        <path d="M12.63,14a1.08,1.08,0,0,1-1.26,0L0,6V18.55a3.28,3.28,0,0,0,3.27,3.27H20.73A3.28,3.28,0,0,0,24,18.55V6Z" />
      </svg>
    </ConditionalWrapper>
  );
};
IconMail.displayName = "mail";
export default IconMail;
