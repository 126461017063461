import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconEducation: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m26.863 12.738-1.25-.688-11.25-6.25h-.138a1.326 1.326 0 0 0-.237-.075h-.463a1.562 1.562 0 0 0-.25.075h-.137l-11.25 6.25a1.25 1.25 0 0 0 0 2.175L5 15.95v5.925a3.75 3.75 0 0 0 3.75 3.75h10a3.75 3.75 0 0 0 3.75-3.75V15.95l2.5-1.4v3.575a1.25 1.25 0 0 0 2.5 0v-4.3a1.25 1.25 0 0 0-.637-1.087ZM20 21.875a1.25 1.25 0 0 1-1.25 1.25h-10a1.25 1.25 0 0 1-1.25-1.25v-4.537l5.638 3.125.187.075h.113c.103.013.208.013.312 0 .104.013.209.013.313 0h.112a.588.588 0 0 0 .188-.075L20 17.338v4.537Zm-6.25-3.925-8.675-4.825L13.75 8.3l8.675 4.825-8.675 4.825Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconEducation.displayName = "education";

export default IconEducation;
