import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSpeed: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 57 57"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M27.59 9.5a22.7 22.7 0 0 1 16.14 6.69 22.7 22.7 0 0 1 6.7 16.15c0 4.98-1.59 9.72-4.57 13.7a2.2 2.2 0 0 1-1.76.88H11.07a2.2 2.2 0 0 1-1.75-.88 22.65 22.65 0 0 1-4.57-13.7 22.7 22.7 0 0 1 6.69-16.15A22.7 22.7 0 0 1 27.59 9.5Zm18.3 25.03h-.42a2.2 2.2 0 0 1 0-4.4h.43a18.34 18.34 0 0 0-3.82-9.18l-.3.3a2.2 2.2 0 1 1-3.1-3.12l.3-.29a18.34 18.34 0 0 0-9.2-3.81v.42a2.2 2.2 0 0 1-4.4 0v-.42c-3.37.4-6.53 1.73-9.18 3.81l.3.3a2.2 2.2 0 1 1-3.12 3.1l-.29-.29a18.34 18.34 0 0 0-3.81 9.19h.42a2.2 2.2 0 0 1 0 4.4h-.42a18.2 18.2 0 0 0 2.93 7.98h30.75a18.2 18.2 0 0 0 2.94-7.99Zm-9.6-7.78-2.96 2.95a6.33 6.33 0 1 1-3.1-3.1l2.95-2.96a2.2 2.2 0 1 1 3.1 3.1Zm-8.7 7.52a1.93 1.93 0 1 0-.01-3.87 1.93 1.93 0 0 0 0 3.87Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconSpeed.displayName = "speed";
export default IconSpeed;
