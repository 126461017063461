import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSafety: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M10.33,15.2a1.21,1.21,0,0,0,.84.34,1.18,1.18,0,0,0,.83-.35l5.82-6.07a1.18,1.18,0,1,0-1.68-1.66l-5,5.23L8.91,10.44h0a1.18,1.18,0,0,0-.84-.34h0a1.17,1.17,0,0,0-.83.34,1.2,1.2,0,0,0-.34.84,1.17,1.17,0,0,0,.34.83Z" />
        <path d="M20.92,2.19,12.3,0a1.22,1.22,0,0,0-.6,0L3.08,2.19a1.24,1.24,0,0,0-.93,1.19V12c0,3.05,1.6,6,4.73,8.73A24,24,0,0,0,10,23c.78.49,1.28.74,1.45.83a1.23,1.23,0,0,0,1.1,0c.17-.09.67-.34,1.45-.83a24,24,0,0,0,3.12-2.31C20.25,18,21.85,15.05,21.85,12V3.38A1.24,1.24,0,0,0,20.92,2.19ZM19.39,12c0,2.31-1.31,4.63-3.89,6.89A21.36,21.36,0,0,1,12.7,21l-.7.42L11.3,21a21.59,21.59,0,0,1-2.8-2.07C5.92,16.63,4.61,14.31,4.61,12V4.34L12,2.49l7.39,1.85Z" />
      </svg>
    </ConditionalWrapper>
  );
};

IconSafety.displayName = "safety";

export default IconSafety;
