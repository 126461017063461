import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconArrow: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 25 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m19.965 13.209-5.052 5.052a1.263 1.263 0 1 1-1.786-1.787l2.896-2.896H5.6a1.263 1.263 0 1 1 0-2.526h10.424l-2.896-2.896a1.263 1.263 0 0 1 1.786-1.787l5.053 5.053a1.26 1.26 0 0 1 .003 1.784"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconArrow.displayName = "arrow";

export default IconArrow;
