import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconShare: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="currentColor"
        viewBox="0 0 37 37"
      >
        <path
          fill="currentColor"
          d="M3.1 31.3h-.3c-.4-.1-.7-.5-.7-1 0-4.8.3-10.3 4.5-14.5 4.2-4.2 9.9-5 13.6-5h.1V7.3c0-.4.1-.8.3-1.1.2-.3.5-.6.9-.8.4-.2.8-.2 1.2-.1.4.1.8.3 1.1.6l10.1 10.2c.4.4.7.8.8 1.3.2.5.3 1 .2 1.5 0 .5-.2 1-.4 1.5s-.5.9-.9 1.2l-10 8.4c-.3.3-.7.4-1.1.5-.4 0-.8 0-1.1-.2-.4-.2-.7-.4-.9-.8-.2-.3-.3-.7-.3-1.1v-3.8c-11.9-.7-16.1 5.9-16.3 6.2-.1.3-.5.5-.8.5zm15.7-8.9c.8 0 1.6 0 2.5.1.5 0 .9.5.9 1v4.7l.1.1 10-8.4c.2-.2.3-.3.4-.6.1-.2.2-.5.2-.7 0-.2 0-.5-.1-.7-.1-.2-.2-.4-.4-.6L22.3 7.2l-.1.1v4.6c0 .3-.1.5-.3.7-.2.2-.5.3-.7.3-3.2-.2-9.1.2-13.2 4.3-2.9 2.9-3.7 6.7-3.9 10.3 2.4-2.1 7-5.1 14.7-5.1z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconShare.displayName = "share";
export default IconShare;
