import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSun: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12 1c.62 0 1.123.503 1.123 1.122v1.796a1.122 1.122 0 1 1-2.245 0V2.122c0-.62.503-1.122 1.123-1.122ZM4.222 4.22a1.122 1.122 0 0 1 1.587 0l1.276 1.275a1.122 1.122 0 1 1-1.588 1.588L4.221 5.808a1.122 1.122 0 0 1 0-1.588Zm15.559 0a1.122 1.122 0 0 1 0 1.588l-1.275 1.275a1.122 1.122 0 1 1-1.588-1.588l1.275-1.275a1.122 1.122 0 0 1 1.588 0ZM12 8.633a3.367 3.367 0 1 0 0 6.734 3.367 3.367 0 0 0 0-6.734ZM6.39 12a5.612 5.612 0 1 1 11.224 0A5.612 5.612 0 0 1 6.39 12ZM1 12c0-.62.503-1.122 1.122-1.122h1.796a1.122 1.122 0 1 1 0 2.245H2.122C1.502 13.123 1 12.62 1 12Zm17.96 0c0-.62.502-1.122 1.122-1.122h1.796a1.122 1.122 0 1 1 0 2.245h-1.796c-.62 0-1.123-.503-1.123-1.123ZM7.083 16.917a1.122 1.122 0 0 1 0 1.588L5.808 19.78a1.122 1.122 0 1 1-1.587-1.588l1.275-1.275a1.122 1.122 0 0 1 1.588 0Zm9.833 0a1.122 1.122 0 0 1 1.588 0l1.275 1.275a1.122 1.122 0 1 1-1.588 1.588l-1.275-1.275a1.122 1.122 0 0 1 0-1.588Zm-4.916 2.042c.62 0 1.122.503 1.122 1.123v1.796a1.122 1.122 0 1 1-2.245 0v-1.796c0-.62.503-1.123 1.123-1.123Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconSun.displayName = "sun";
export default IconSun;
