import { useState } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

export default function useCurrentAnchor(containerIds) {
  const [currentAnchor, setCurrentAnchor] = useState(null);

  let observerOptions = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  useIsomorphicLayoutEffect(() => {
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    function observerCallback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentAnchor(entry.target.id);
        }
      });
    }

    try {
      document.querySelectorAll(containerIds.join(",")).forEach((i) => {
        if (i) {
          observer.observe(i);
        }
      });
    } catch (e) {
      console.error("Anchor Selectors not valid!");
    }
    return () => {
      try {
        document.querySelectorAll(containerIds.join(",")).forEach((i) => {
          if (i) {
            observer.unobserve(i);
          }
        });
      } catch (e) {
        console.error("Anchor Selectors not valid");
      }
    };
  }, []);

  return {
    currentAnchor,
  };
}
