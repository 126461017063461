import Link from "@/components/Link/Link";
import CareerTile from "./variants/CareerTile";
import DefaultTile from "./variants/DefaultTile";

const getTileVariant = (type, props) => {
  switch (type) {
    case "career":
      return <CareerTile {...props} />;
    default:
      return <DefaultTile {...props} />;
  }
};

const Tile = ({ type, content }) => {
  return (
    <div className=" shadow-md hover:shadow-lg relative flex h-full">
      {getTileVariant(type, content)}
    </div>
  );
};

export default Tile;
