import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSend: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.337 21.46 21.9 3.383a.938.938 0 0 0 .037-.083c.304-.768-.465-1.537-1.233-1.233a.938.938 0 0 0-.083.037L2.545 10.667c-.808.383-.691 1.568.177 1.784l7.064 1.767 1.767 7.064c.216.868 1.401.985 1.784.177ZM5.765 11.248l4.515 1.129 6.207-6.208L5.765 11.25Zm6.99 6.99-1.129-4.515 6.208-6.207-5.079 10.722Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconSend.displayName = "send";
export default IconSend;
