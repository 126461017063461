import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
} from "react";
import s from "./Button.module.css";
import { cva } from "class-variance-authority";

type Variant = "regular" | "hollow" | "link";

type Size = "small" | "medium" | "large" | "fullwidth";

type Color = "primary" | "secondary";

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  color?: Color;
  /**
   * Is this a gh
   */
  variant?: Variant;
  /**
   * How large should the button be?
   */
  size?: Size;

  disabled?: boolean;

  icon?: ReactElement | null;

  iconPosition?: "before" | "after";

  type?: "button" | "submit" | "reset";

  as?: "button" | "a" | "span";

  rounded?: boolean;
  href?: string;
  className?: string;
  target?: any;
  rel?: any;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

const buttonStyles = cva(s.Button, {
  variants: {
    color: {
      primary: s.ButtonPrimary,
      secondary: s.ButtonSecondary,
    },
    size: {
      small: s.ButtonSmall,
      medium: s.ButtonMedium,
      large: s.ButtonLarge,
      fullwidth: "w-full px-7 xl:py-6 py-4 xl:text-4xl text-xl leading-5",
    },
    variant: {
      regular: "",
      hollow: s.ButtonHollow,
      link: s.ButtonLink,
    },
    rounded: {
      true: "rounded-6",
      false: "rounded-none",
    },
  },
  defaultVariants: {
    color: "primary",
    size: "medium",
  },
});

/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef(
  (
    {
      color = "primary",
      variant = "regular",
      size = "medium",
      type = "button",
      className = "",
      as = "button",
      rounded = true,
      href,
      children,
      target = "",
      rel = "",
      ...props
    }: PropsWithChildren<ButtonProps>,
    ref?: ForwardedRef<HTMLButtonElement | HTMLAnchorElement | HTMLLinkElement>
  ) => {
    const Component = as;

    return (
      <Component
        type={type}
        className={`${buttonStyles({
          color,
          size,
          variant,
          rounded,
        })} ${className}`}
        ref={ref as any}
        href={href}
        target={target}
        rel={rel}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

Button.displayName = "Button";
