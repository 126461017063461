import Image from "@/components/Image/Image";
import { Icon } from "@/components/Icon";
import Separator from "@/components/Separator/Separator";
import * as gtag from "@/lib/gtag";

const MarketingTeaser = ({
  width,
  imgSrc,
  header,
  subheader,
  linkText,
  ...props
}) => {
  return (
    <div
      className="relative min-h-[260px] lg:min-h-[360px] flex flex-col justify-between w-full"
      onClick={() => {
        gtag.event({
          event: "itemClick",
          itemClick: "marketingTeaser",
          itemName: `${header}`,
          itemType: "button",
          itemAction: "click",
          itemPosition: "detail page",
          clicktext: `${linkText}`,
        });
      }}
    >
      <div className="z-0 absolute inset-0">
        <Image
          src={imgSrc}
          alt="Forest"
          layout="fill"
          objectFit="cover"
          loading="lazy"
        />
      </div>
      <div className="lg:p-8 p-6 z-10 relative">
        <div className="font-bold lg:text-5xl text-4xl mb-2 text-white">
          {header}
        </div>
        <p className="text-white lg:text-3xl text-base">{subheader}</p>
      </div>

      <div className="lg:px-8 px-6 bottom-5 w-full mt-auto z-10 relative">
        <Separator
          className="!bg-white opacity-40  !h-2"
          orientation="horizontal"
        />

        <div className="flex items-center lg:py-7 py-5 group justify-end">
          <p className="text-white font-bold text-base mr-3">{linkText}</p>
          <Icon
            type="chevron"
            className="w-5 h-5 text-white group-hover:translate-x-2"
            purposeLabel="Mehr erfahren"
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingTeaser;
