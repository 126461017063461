import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Separator from "@/components/Separator/Separator";
import { useContext } from "react";
import { PageContext } from "pages/[[...slug]]";
import * as gtag from "@/lib/gtag";
import { cva } from "class-variance-authority";
import { Button } from "@/components/Button/Button";
import UspBarStatic from "../UspBarStatic/UspBarStatic";

const COL_POSITIONS = ["left", "center", "right"];

//  0:Standard
//  1:Karriere
// -1:Deaktiviert

const footerStyles = cva(
  "w-full  flex justify-center container mx-auto xl:pt-10 py-5 xl:pb-8",
  {
    variants: {
      layout: {
        "0": "",
        "1": "hidden",
        "-1": "hidden",
      },
    },
    defaultVariants: {
      layout: "-1",
    },
  }
);

const footerMetaStyles = cva("w-full container mx-auto py-7", {
  variants: {
    layout: {
      "0": "",
      "1": "[&>p]:hidden [&>div>div]:hidden !pb-0 !mb-0",
      "-1": "hidden",
    },
  },
  defaultVariants: {
    layout: "-1",
  },
});

const Footer = ({ items = {} }) => {
  const { config, appearance } = useContext(PageContext);
  const columns: any[] = Object.values(items);
  const { footerLayout = "0" } = appearance;

  return (
    <section className="bg-gray-100 " style={{ contentVisibility: "auto" }}>
      {footerLayout != ("1" || "-1") && (
        <footer
          className={`${footerStyles({
            layout: footerLayout.toString(),
          })}`}
        >
          <div className="w-full">
            <div className={`w-full xl:flex xl:justify-between xl:flex-nowrap`}>
              {columns.map((column: any[], index) => {
                return (
                  <div
                    className="border-b last:border-none xl:border-none border-gray-300 mb-5 last:mb-0"
                    key={`columns-${index}`}
                  >
                    <h3>
                      <p className="text-base xl:text-2xl mb-5 xl:mb-7">
                        {
                          config?.footer?.nav?.col[COL_POSITIONS[index]]
                            ?.headline
                        }
                      </p>
                    </h3>

                    <div className="mb-5 xl:mb-0">
                      <div
                        className={`columns-2 lg:columns-${
                          config?.footer?.nav?.col[COL_POSITIONS[index]]?.cols
                        }`}
                      >
                        <ul data-orientation="horizontal" dir="ltr">
                          {column.map(({ title, link, target }) => (
                            <li
                              key={link}
                              className="mr-4 lg:mb-5 "
                              onClick={() => {
                                gtag.event({
                                  event: "itemClick",
                                  itemClick: "footer",
                                  firstLevel: title || "firstLevel",
                                });
                              }}
                            >
                              <a
                                href={link}
                                target={target}
                                className="text-gray-900 text-sm pt-[9px] pb-[9px] lg:pt-0 lg:pb-3 hover:underline lg:hover:no-underline lg:border-b-[1px] lg:border-transparent lg:hover:border-gray-900 max-md:block max-md:w-full"
                              >
                                {title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </footer>
      )}
    </section>
  );
};
export default Footer;

export const FooterMeta = ({ items = [] }) => {
  const { config, appearance } = useContext(PageContext);
  const { footerLayout = "0" } = appearance;

  return (
    <section
      className={`bg-blue-dark text-white  lg:min-h-[80px] min-h-[40px]`}
      style={{ contentVisibility: "auto" }}
    >
      {footerLayout.toString() === "-1" && (
        <UspBarStatic className={"!mt-0"} isFooter={true} />
      )}
      <div
        className={` ${footerMetaStyles({ layout: footerLayout.toString() })}`}
      >
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="md:flex md:flex-col md:justify-between lg:block">
            <p className="text-white text-base leading-2 font-normal mb-5">
              {config?.footer?.meta?.opening?.left?.headline}
            </p>
            <ul className="flex flex-row md:flex-col lg:flex-row mb-8 justify-between">
              <li className="lg:mr-10 mr-5 lg:text-base text-[12px]">
                <span className="font-bold mr-4">
                  {config?.footer?.meta?.opening?.left?.week?.label}
                </span>
                {config?.footer?.meta?.opening?.left?.week?.value}
              </li>
              <li className="mr-5 lg:text-base text-[12px]">
                <span className="font-bold mr-4">
                  {config?.footer?.meta?.opening?.left?.weekend?.label}
                </span>

                {config?.footer?.meta?.opening?.left?.weekend?.value}
              </li>
            </ul>
          </div>
          <div>
            <p className="text-white text-base leading-2 font-normal mb-7 lg:mb-5">
              {config?.footer?.meta?.opening?.right?.headline}
            </p>
            <div className="flex flex-col lg:flex-row lg:gap-9">
              <div className="flex lg:order-2 mb-6 lg:mb-8">
                <Button
                  as="a"
                  className="!py-4 !px-4 cursor-pointer w-fit lg:mt-[-8px] mb-4 lg:mb-0 group"
                  href={config?.footer?.meta?.opening?.right?.button?.link}
                >
                  <span className=" pb-1 border-b-[1px] border-transparent group-hover:border-white">
                    {config?.footer?.meta?.opening?.right?.button?.label}
                  </span>
                </Button>
              </div>
              <div className="flex flex-col lg:flex-row mb-8 lg:order-1">
                <ul className="flex flex-row md:flex-col lg:flex-row justify-between">
                  <li className="lg:mr-10 mr-5  lg:text-base text-[12px]">
                    <span className="font-bold mr-4 ">
                      {config?.footer?.meta?.opening?.right?.week?.label}
                    </span>
                    {config?.footer?.meta?.opening?.right?.week?.value}
                  </li>
                  <li className="mr-5 lg:text-base text-[12px]">
                    <span className="font-bold mr-4 ">
                      {config?.footer?.meta?.opening?.right?.weekend?.label}
                    </span>
                    {config?.footer?.meta?.opening?.right?.weekend?.value}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Separator className="!bg-white mb-6" />
        <div className="flex flex-col xl:flex-row lg:justify-between">
          <p className="text-center mb-6 items-center flex justify-center">
            {config?.footer?.meta?.address}
          </p>
          <Separator className="!bg-white mb-6 lg:hidden" />
          <NavigationMenu.Root
            className={`flex justify-center p-4 w-full mb-6 lg:w-auto flex-col lg:flex-row`}
          >
            <NavigationMenu.List className="flex lg:justify-between justify-start w-full lg:flex-nowrap flex-wrap">
              {items.map(({ title, link, target }) => (
                <NavigationMenu.Item
                  key={link}
                  className="lg:ml-10 w-1/2 l:w-full"
                >
                  <a
                    href={link}
                    target={title === "Karriere" ? "_blank" : target}
                    className="pb-3 border-b-[1px] border-transparent hover:border-white"
                  >
                    {title}
                  </a>
                </NavigationMenu.Item>
              ))}
              <NavigationMenu.Item className="lg:ml-10">
                <a
                  href="#"
                  onClick={() => (window as any).UC_SET_VIEW(3)}
                  className="pb-3 border-b-[1px] border-transparent hover:border-white"
                >
                  Dateneinstellungen
                </a>
              </NavigationMenu.Item>
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
        {footerLayout != "1" && <Separator className="!bg-white mb-6" />}
        <p className="text-xs">{config?.footer?.meta?.infoText}</p>
      </div>
    </section>
  );
};
