import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";
const svgStyles = `
  .st0, .st1 { fill-rule:evenodd; clip-rule:evenodd; fill: #fff; }
  .st1 { fill: #345c9a; }
`;

const IconFordGtCi: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Isolationsmodus"
        version="1.1"
        viewBox="0 0 248.7 33.7"
        className={className}
      >
        <style dangerouslySetInnerHTML={{ __html: svgStyles }} />
        <path
          d="M134.1 10.8h33.5c2.4 0 2.4 1.3 1.4 2.9-1.6 2.5-6.6 11.6-8.2 13-2.3 2.1-5.5 2.1-10.6 2.3-14.8.2-29.3.2-43.2 0-8.3.1-10.5-1.7-9.4-5.4.7-2.4 2.2-5.7 4.2-8.9 3-5.3 6.4-10.9 10.8-13.2 2.9-1.6 7.2-1.7 12.3-1.7h122.2c1.1 0 2 .6 1.1 1-1.3.6-4.5 1.6-5.3 1.6H127.8c-10.4 0-11.7 2.5-17.6 12.2-3 5.8-4.6 8.8-4 10.2.5 1.1 2.3 1.3 5.3 1.3 12.2.3 24.6.1 36.9 0 3.4-.3 4-.6 6.6-5.2.6-1 2.5-4.1 3-5.9.3-1.1.1-1.6-1.2-1.6h-26.1l.7-1.2c.7-1 1.6-1.4 2.7-1.4zm65.1-3.1c1.2-2.1 2.5-3.9 5.6-3.9h34c-1.3.5-8.7 2.8-12.2 2.8h-13.7c-3.7 0-5.3 1.4-6.3 3.1l-8 13.7c-.9 1.6-2.8 3.4-4.4 4l-8.1 2.4 13.1-22.1zM129 3.8h64.2c2 0 2.9 1.3 2 2.8l-14.5 24.8-7.3 2.1c-1.5.5-2.1-.4-1.2-2.1l12.6-21.5c.4-.7 1.7-3.3-2.2-3.3h-42.7c-2.8 0-5.7-.1-8.4.1-2.3.2-2.6.5-4 2.3-1.5 2-6.9 11.6-6.8 12.6.1.7.9.9 1.9.9H139c2.2 0 3.4-.6 3.8-1.4l1-1.8c.6-1 .3-1.7-.5-1.7h-12.8c-1.5 0-1.6-1.1-1.1-2l.5-1h19.5c2.9 0 3 1.6 1.9 3.4l-2.8 4.7c-1.3 2.3-3.7 2.3-5.5 2.3h-29.5c-1.2 0-2.3-.4-2.4-1.4-.2-1.9 3.6-8.2 5-10.5 2.6-4.4 3.9-6.9 6.6-8.3 1.7-.8 3.5-1 6.3-1z"
          className="st0"
        />
        <path
          d="M44.8 33.6c24.7 0 44.7-7.5 44.7-16.9 0-9.3-20-16.9-44.7-16.9C20.1-.2.1 7.4.1 16.7c0 9.4 20 16.9 44.7 16.9z"
          className="st1"
        />
        <path
          d="M44.8 31.9c-23.7 0-42.9-6.7-42.9-15.2 0-8.4 19.2-15.2 42.9-15.2s43 6.8 43 15.2c0 8.4-19.2 15.2-43 15.2z"
          className="st0"
        />
        <path
          d="M44.8 30.7c23 0 41.6-6.3 41.6-14S67.8 2.8 44.8 2.8 3.2 9 3.2 16.7s18.6 14 41.6 14z"
          className="st1"
        />
        <path
          d="M55.7 19.5c2-1.8 3.1-3.8 5.9-3.7 1.7 0 2.1 1.4 2.4 2l3.7-6.3H70l-6.2 10.9c-.1.2-.4.7-.4 1 .1.5.7.4.5.4 1.2-.1 3.5-2.4 4.1-3 .1-.1.3-.4.4-.4.1 0 .3.1.3.3 0 .1-.3.5-.5.8-1.2 1.4-3.1 3.7-5.2 3.7-1.1 0-1.5-.4-1.6-1.3-.5.4-2.2 1.2-3.9 1.2-1.4 0-2-.9-2.3-1.5-.3-.5-.4-1.4-.2-2.2-.8.4-1.5.5-2.5.4-1.2 0-2-.6-2.3-2-1.4 2.5-1.4 2.6-2.9 5.3H45l3.1-5.3c.4-.7.6-1.3.7-1.9.1-.3-.2-.7-.5-.7-.4 0-1.1.4-1.7 1 0 0-1.6 1.7-2.8 3.5-1.1 1.7-3.1 3.6-5.2 3.6-1.5 0-2.9-.8-3.2-2.4 0 0-.1.4-1.5 1.2-.1.1-.4.2-.5.2-.3-.2-.4-.5-.4-.7.1 0 .3-.1.8-.5 1.1-.8 1.3-1.4 1.3-1.4.3-.5.7-1.4 1-2 .9-1.7 2.4-3.5 4.8-3.6.4 0 .5.2.6.3.2.2.1.5.1.5-1.7.3-2.9 1.3-3.6 2.2-.4.6-.9 1.7-1 2.4-.2 1.3.4 2.2 1.3 2.4 1.7.3 3.1-1 4.1-2.8.3-.5.5-1.8.5-2.4 0-.7-.4-1.4-.7-1.7 0-.5.3-.8.7-.8.1 0 .2 0 .3.1.6.6.9 1.7.9 2.5 0 .3 0 .6-.1.9 1.2-1.5 2.7-3.4 3.9-3.7.2-.1.5-.1.7-.1 2.2-.1 1.9 1.9 1.9 1.9 1.2-1.3 2-1.8 3-1.8 1.2.1 1.6.9 1.6 1.5 0 .7-.6 1.6-1.5 1.6-.8 0-1.3-.4-1.6-1.2-.5.2-.8.6-.9 1.3-.2 1 .5 1.7 1.5 1.7 1.8-.2 2.5-.9 3.1-1.4zm1.7-12.4c0 .9-3.2 2.7-6 2.7-4.3 0-8.7-2-10.7-2.5-2.2-.5-4.2-1-5.8-1-5.6 0-8 2.4-7.9 4.6.1.9.8 1.5 1.8 1.3 1.2-.2 2.8-1.3 3.2-2.3.1-.2.3-.2.4-.2.2.1.1.3.1.3-.1 1.1-1.9 4.2-4.9 4.2-1.7 0-2.8-1.6-2.8-3.1 0-2.9 2.9-5.7 10.1-5.7 1.4 0 2.9.1 4.6.4 1.9.3 6.7 1.2 10.7 1.2 1.4 0 3.3.1 4.8-.2 1.6-.3 1.2-.3 1.9-.3.5.1.5.4.5.6z"
          className="st0"
        />
        <path
          d="M61.7 17.1c-1-.1-2.3.7-3.3 2-.5.6-1.1 1.8-1.2 2.6-.1.7.1 1.4.3 1.6.3.2 1.4.8 3.2-1 1-1 2-2.7 2-2.7l-.3-.1c.7-1 .5-2.2-.7-2.4z"
          className="st1"
        />
        <path
          d="M40.6 13.7c0 .3.4.6.8.6-.1.3-.1.5-.2.4-.9 0-1.2-.8-1.2-.8-1.2.2-2.3.3-4.6.6-5.7 9.3-7.5 10.8-11 10.8-2.5 0-4.7-1.6-4.8-4.6 0-1.8 1.4-4 4.1-4.6.3 0 .4.1.4.3 0 .1-.1.3-.4.4-.7.2-2 1.8-2 3.2 0 1.1.7 2.2 1.8 2.5.3.1.6.1.9.1 2.8 0 5.9-3.1 9.3-7.9-.5 0-2.9.3-3.5 1-.3.2-.4.1-.5-.1 0-.1.1-.3.2-.4.9-1 3.7-1.3 4.4-1.4 2.6-4 5.9-6.5 6.6-6.4.1 0 .4.2.3.5 0 .1-.3.4-.4.5-.6.5-2.5 1.8-4.7 5.4 1.7-.1 3.7-.4 3.7-.4s-.1-.4-.1-.7c.1-1.2.8-2 1.7-1.8.5.1.9.5.9 1s-.4 1.2-.7 1.6c-.3-.1-.6.1-1 .2z"
          className="st0"
        />
        <path
          d="M40.5 13.1s.3 0 .7-.5c.2-.2.5-.7.4-1 0-.2-.1-.4-.3-.3-.4.1-.7.3-.8.7-.1.4-.1.7 0 1.1z"
          className="st1"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFordGtCi.displayName = "FordGtCi";
export default IconFordGtCi;
