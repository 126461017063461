import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconRequest: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M20.996 15.747a9.374 9.374 0 0 0 1.006-4.248V11c-.268-4.88-4.12-8.732-8.945-8.998L12.502 2A9.377 9.377 0 0 0 8.25 3.007a9.498 9.498 0 0 0-4.425 12.36l-1.773 5.317a1 1 0 0 0 1.265 1.265l5.318-1.773a9.379 9.379 0 0 0 3.868.824 9.499 9.499 0 0 0 8.492-5.253Zm-12.61 2.404L4.583 19.42l1.267-3.803a1 1 0 0 0-.056-.767 7.38 7.38 0 0 1-.792-3.346 7.5 7.5 0 0 1 4.147-6.708A7.385 7.385 0 0 1 12.499 4h.503c3.751.209 6.791 3.248 7.001 7.055l-.001.448a7.379 7.379 0 0 1-.793 3.346A7.501 7.501 0 0 1 12.501 19a7.381 7.381 0 0 1-3.348-.793 1 1 0 0 0-.768-.056Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconRequest.displayName = "request";
export default IconRequest;
