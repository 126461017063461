import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon";
import Link from "next/link";

const JobNavigation = ({ content }) => {
  const { header, text, link, linkText } = content;
  return (
    <Link href={link.href || "#"}>
      <div className="max-w-[540px] lg:h-full shadow-lg lg:p-7 p-6 hover:bg-blue-medium group transition duration-150 ease-in-out">
        <p className="text-base lg:text-3xl font-bold text-blue-dark group-hover:text-white transition duration-150 ease-in-out mb-5">
          {header}
        </p>
        <p className="text-sm text-gray-600 group-hover:text-white transition duration-150 ease-in-out mb-5">
          {text}
        </p>
        {link && link.href && linkText && (
          <div className="flex items-center  cursor-pointer !no-underline text-base text-blue-light group-hover:text-white !justify-start !font-normal !py-0">
            {linkText}
            <Icon
              type="chevron"
              className="ml-3 w-5 h-5 text-blue-light cursor-pointer group-hover:text-white group-hover:ml-4 transition duration-150 ease-in-out"
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default JobNavigation;
