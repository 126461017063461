import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSpeakerLittle: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M19.556 15.285c-.576.769-1.8.36-1.8-.6V9.953c0-.96 1.224-1.369 1.8-.6l.875 1.166a3 3 0 0 1 0 3.6l-.875 1.166ZM8.659 8.002a1 1 0 0 1-.707.293H4a1 1 0 0 0-1 1v6.049a1 1 0 0 0 1 1h3.952a1 1 0 0 1 .707.293l4.707 4.707c.63.63 1.707.183 1.707-.707V4.002c0-.89-1.077-1.337-1.707-.707L8.659 8.002Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSpeakerLittle.displayName = "speaker-little";
export default IconSpeakerLittle;
