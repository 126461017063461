import ReviewCard from "@/components/ReviewCard/ReviewCard";
import Slider from "@/components/Slider/Slider";
import { Slot } from "utils/create-slots";
import Headlines from "../Headlines/Headlines";

const Review = ({ content }) => {
  const { header, headerLayout = 2, headerPosition = 0, data } = content;
  const { reviews } = data;

  return (
    <div
      className=" bg-gray-100 relative  z-0 py-15 overflow-hidden"
      style={{ contentVisibility: "auto" }}
    >
      <div className="container mx-auto px-3 z-50">
        <Headlines
          headerLayout={headerLayout}
          headerPosition={headerPosition}
          {...content}
        />
        <Slider
          aria-label="Google Review Slider"
          className="flex !visible"
          options={{
            perPage: 3,
            perMove: 1,
            autoHeight: true,
            pagination: false,
            breakpoints: {
              1024: {
                perPage: 3,
              },
              640: {
                perPage: 1,
              },
            },
          }}
        >
          {reviews.map((item, index) => (
            <Slider.Slide key={index}>
              <ReviewCard>
                <ReviewCard.Header>
                  <Slot name="avatar">
                    <ReviewCard.Avatar
                      alt="google-avatar"
                      src={item.reviewerProfilePhotoUrl}
                    />
                  </Slot>
                  <Slot name="items">
                    <ReviewCard.Title>
                      {item.reviewerDisplayName}
                    </ReviewCard.Title>
                    <ReviewCard.Timestamp>
                      {item.publishedOn}
                    </ReviewCard.Timestamp>
                  </Slot>
                </ReviewCard.Header>
                <ReviewCard.Stars rating={4.5} />
                <ReviewCard.Content>{item.reviewerComment}</ReviewCard.Content>
              </ReviewCard>
            </Slider.Slide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Review;
