import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPickUp: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70.44 30.51"
        className={className}
      >
        <g>
          <path
            d="M70.05 18.78a1.52 1.52 0 0 0-.5-.37c.56-2.77 1-7.76-2.63-9.12a1.6 1.6 0 0 0-.53-.09h-5.5C58.6 3.55 55.3 3.46 55 3.47h-6.13v-1.5a1.51 1.51 0 0 0-.64-1.18C47.88.54 47.16 0 37.69 0c-7.18 0-12.36 3.68-15.78 6.12a20.7 20.7 0 0 1-2.56 1.67 18.86 18.86 0 0 1-5.59 1.13A64 64 0 0 0 6.84 10C2 11 .81 14.44.76 14.58a1.47 1.47 0 0 0-.05.75l.23 1.23a6.74 6.74 0 0 0-.25 4.05A4.53 4.53 0 0 0 0 23.34a1.53 1.53 0 0 0 .69 1.22c1.9 1.22 5 1.37 6.1 1.39a6.24 6.24 0 0 0 11.83.58l29.51-.74a6.24 6.24 0 0 0 12.06.21c2.62 0 6.38-.48 6.56-.51a1.48 1.48 0 0 0 .78-.33 8.79 8.79 0 0 0 2.9-5.16 1.52 1.52 0 0 0-.38-1.22ZM55 6.47s1.3.21 2.6 2.73H49V6.47Zm-42.2 21a3.25 3.25 0 1 1 3.25-3.25 3.26 3.26 0 0 1-3.25 3.29Zm41.39 0a3.25 3.25 0 1 1 3.25-3.25 3.25 3.25 0 0 1-3.25 3.29Zm11.71-5c-1.08.13-3.71.4-5.6.43a6.24 6.24 0 0 0-12.18-.18l-29.12.8A6.25 6.25 0 0 0 6.69 23a13.78 13.78 0 0 1-3.41-.49 1.82 1.82 0 0 0 .31-.31 1.52 1.52 0 0 0 .24-1.32A6 6 0 0 1 3.58 18 1.5 1.5 0 0 0 4 16.62l-.25-1.37a5.39 5.39 0 0 1 3.72-2.35 62.92 62.92 0 0 1 6.62-1 20.61 20.61 0 0 0 6.52-1.39 21.33 21.33 0 0 0 3-1.95C27 6.22 31.46 3 37.69 3c4.74 0 7.07.14 8.18.25l.13 7.48a1.51 1.51 0 0 0 1.5 1.47h18.56c1.38.88.82 5 .17 7.14a1.5 1.5 0 0 0 .23 1.33 1.47 1.47 0 0 0 .5.43 6.61 6.61 0 0 1-1.06 1.44Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </ConditionalWrapper>
  );
};

IconPickUp.displayName = "pick-up";
export default IconPickUp;
