import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCalendar: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.727 2a.91.91 0 0 0-.909.91v.908H9.364V2.91a.91.91 0 0 0-1.819 0v.91H5.727A2.727 2.727 0 0 0 3 6.544v12.727A2.727 2.727 0 0 0 5.727 22h12.727a2.727 2.727 0 0 0 2.728-2.727V6.545a2.727 2.727 0 0 0-2.727-2.727h-1.819V2.91A.91.91 0 0 0 15.727 2Zm3.637 7.273V6.545a.91.91 0 0 0-.91-.909h-1.818v.91a.91.91 0 1 1-1.818 0v-.91H9.364v.91a.91.91 0 0 1-1.819 0v-.91H5.727a.91.91 0 0 0-.909.91v2.727h14.546ZM4.818 11.09h14.546v8.182a.91.91 0 0 1-.91.909H5.727a.91.91 0 0 1-.909-.91v-8.181Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCalendar.displayName = "calendar";

export default IconCalendar;
