import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconDelivery: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8871 6.573C16.8496 6.2504 16.5755 6 16.2428 6H5.21579L5.14015 6.00436C4.81755 6.04183 4.56715 6.316 4.56715 6.64865V8.59459H2.6212L2.54555 8.59896C2.22295 8.63643 1.97255 8.91059 1.97255 9.24324C1.97255 9.60148 2.26296 9.89189 2.6212 9.89189H4.56715V11.1626H0.675243L0.601668 11.1666C0.263351 11.2032 0 11.4898 0 11.8378C0 12.2108 0.302317 12.5131 0.675243 12.5131H4.56715V16.3784L4.57151 16.454C4.60898 16.7766 4.88314 17.027 5.21579 17.027H7.2724C7.53944 17.7829 8.26015 18.3243 9.10769 18.3243C9.95522 18.3243 10.6759 17.7829 10.943 17.027H16.2428H17.6508C17.9178 17.7829 18.6385 18.3243 19.4861 18.3243C20.3336 18.3243 21.0543 17.7829 21.3214 17.027H22.7293L22.805 17.0227C23.1276 16.9852 23.378 16.711 23.378 16.3784V12.4865L23.372 12.3988C23.3562 12.2832 23.3094 12.1733 23.2358 12.0813L20.6412 8.83804L20.5847 8.7761C20.4647 8.6604 20.3036 8.59459 20.1347 8.59459H16.8915V6.64865L16.8871 6.573ZM5.86392 12.5131V15.7297H7.2724C7.53944 14.9738 8.26015 14.4324 9.10769 14.4324C9.95522 14.4324 10.6759 14.9738 10.943 15.7297H15.5937V7.2973H5.86392V8.59459H8.45904C8.81728 8.59459 9.10769 8.885 9.10769 9.24324C9.10769 9.57589 8.85728 9.85006 8.53468 9.88753L8.45904 9.89189H5.86392V11.1626H7.00281C7.37574 11.1626 7.67805 11.4649 7.67805 11.8378C7.67805 12.1859 7.4147 12.4725 7.07639 12.5091L7.00281 12.5131H5.86392ZM16.8915 15.7297V9.89189H19.8228L22.0801 12.7135V15.7297H21.3214C21.0543 14.9738 20.3336 14.4324 19.4861 14.4324C18.6385 14.4324 17.9178 14.9738 17.6508 15.7297H16.8915ZM20.1347 16.3784C20.1347 16.0199 19.8446 15.7297 19.4861 15.7297C19.1275 15.7297 18.8374 16.0199 18.8374 16.3784C18.8374 16.7369 19.1275 17.027 19.4861 17.027C19.8446 17.027 20.1347 16.7369 20.1347 16.3784ZM9.10769 17.027C8.74917 17.027 8.45904 16.7369 8.45904 16.3784C8.45904 16.0199 8.74917 15.7297 9.10769 15.7297C9.4662 15.7297 9.75633 16.0199 9.75633 16.3784C9.75633 16.7369 9.4662 17.027 9.10769 17.027Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconDelivery.displayName = "delivery";
export default IconDelivery;
