export const getTextColorClass = (type: string) => {
  switch (type) {
    case "white":
      return "text-white";
    default:
      return "text-blue-dark";
  }
};

export const textColorVariant = {
  white: "text-white",
  blue: "text-blue-dark",
};
