import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconLike: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M21.628 10.181a2.451 2.451 0 0 0-2.035-1.22.765.765 0 0 0-.1-.006h-.002l-2.551-.003-2.096-.002a9.47 9.47 0 0 0 .486-3.005c0-.684-.072-1.348-.208-1.99l-.012.003C14.86 2.835 13.9 2 12.748 2c-.271 0-.53.047-.773.132-.96.337-1.649 1.282-1.649 2.396l.002.092c.002.049.004.098.008.145-.075 2.68-2.09 4.852-4.63 5.042v.017L2.94 9.83a.796.796 0 0 0-.102-.006c-.46 0-.833.39-.833.869v.894l-.002 3.52L2 21.13c0 .48.373.869.833.869.044 0 .09-.004.132-.013l3.076.002a.796.796 0 0 0 .249 0l11.377.005c.512.04 1.04-.083 1.505-.394.396-.262.696-.625.888-1.036a.854.854 0 0 0 .106-.251l.008-.043c.06-.179.1-.364.12-.551l1.121-5.161.492-2.269.06-.268a.935.935 0 0 0 .026-.28 2.675 2.675 0 0 0-.365-1.559Zm-16.29 1.383-.001 3.527-.005 5.16H3.667l.004-5.151.002-3.532 1.666-.004Zm14.37 2.997-.582 2.677-.494 2.277-.053.241a.8.8 0 0 1-.306.388.742.742 0 0 1-.315.119.882.882 0 0 0-.106-.009H15.52l-8.522-.004.004-5.167.004-3.734c1.794-.475 3.296-1.715 4.171-3.366.2-.377.365-.773.496-1.188l.004.002c.071-.222.149-.5.204-.801.13-.703.116-1.382.116-1.382h.01l-.001-.056c0-.45.348-.813.78-.813.43 0 .779.364.779.813l-.002.039h.006a8.357 8.357 0 0 0 .083 2.28c-.03.301-.067.454-.067.454h-.01a7.556 7.556 0 0 1-.712 2.094l.008.009a.895.895 0 0 0-.088.385c0 .48.373.87.833.87h.72l2.602.002 2.555.002h.002v.002a.794.794 0 0 1 .726.396.872.872 0 0 1 .082.699l.006.002-.602 2.769Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconLike.displayName = "like";
export default IconLike;
