import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconOne: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8659 0.253174H9.04914C6.93478 0.253174 4.91817 1.83368 4.22191 3.66983L0.75111 12.8055C-0.15268 15.186 1.47745 17.747 4.13017 17.747H11.947C14.0613 17.747 16.078 16.1665 16.7742 14.3304L20.245 5.1947C21.1217 2.88184 19.6751 0.253174 16.8659 0.253174ZM12.2838 14.5273C12.7666 14.6131 13.1395 14.6612 13.3967 14.6792L12.9139 15.9815H3.02036L3.54369 14.571L4.22491 14.5529C5.30465 14.5243 6.35732 14.0732 6.80245 12.8747L9.3093 6.12706H7.25509L7.99647 4.12098C9.7063 4.12098 11.2176 3.15102 12.541 1.8818H15.2433L11.1124 12.7047C10.6462 13.9273 11.1018 14.3168 12.2838 14.5273V14.5273Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconOne.displayName = "one";
export default IconOne;
