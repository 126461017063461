import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconWay: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7 21.24V2.76C7 2.34 6.552 2 6 2s-1 .34-1 .76v18.48c0 .42.448.76 1 .76s1-.34 1-.76Zm5.5-7.335a1 1 0 0 1-1-1v-1.81a1 1 0 0 1 2 0v1.81a1 1 0 0 1-1 1ZM18 2.76v18.478c0 .42.448.761 1 .761s1-.34 1-.76V2.76c0-.42-.448-.76-1-.76s-1 .34-1 .76Zm-6.5 17.763a1 1 0 1 0 2 0v-1.81a1 1 0 1 0-2 0v1.81Zm0-14.286a1 1 0 1 0 2 0v-1.81a1 1 0 1 0-2 0v1.81Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconWay.displayName = "way";
export default IconWay;
