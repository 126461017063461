const RichSnippets = ({ meta, config }) => {
  const { deactivateLocalBusiness = false, deactivateOrganisation = false } =
    meta?.jsonld || {};

  const { LocalBusiness = "", Organisation = "" } = config?.["json-ld"] || {};

  return (
    <>
      {!deactivateLocalBusiness && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: LocalBusiness,
          }}
        />
      )}
      {!deactivateOrganisation && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: Organisation,
          }}
        />
      )}
    </>
  );
};

export default RichSnippets;
