import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconOpenBook: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M28.87 2.81a17.864 17.864 0 0 0-2.959-.26 17.647 17.647 0 0 0-9.546 2.796 17.647 17.647 0 0 0-9.546-2.728c-.992.005-1.982.091-2.96.26A1.364 1.364 0 0 0 2.727 4.24v16.365a1.364 1.364 0 0 0 1.596 1.364 14.865 14.865 0 0 1 11.251 2.605l.164.095h.15a1.242 1.242 0 0 0 .954 0h.15l.164-.095a14.865 14.865 0 0 1 11.25-2.755 1.363 1.363 0 0 0 1.596-1.364V4.091a1.363 1.363 0 0 0-1.131-1.282ZM15.002 20.933a17.552 17.552 0 0 0-8.182-2.019H5.455V5.278c.454-.026.91-.026 1.364 0A14.81 14.81 0 0 1 15 7.733v13.2Zm12.274-1.964H25.91a17.551 17.551 0 0 0-8.182 2.018V7.733a14.81 14.81 0 0 1 8.182-2.455c.454-.026.91-.026 1.364 0V18.97Zm1.596 5.66a17.866 17.866 0 0 0-2.96-.26 17.647 17.647 0 0 0-9.546 2.796 17.647 17.647 0 0 0-9.546-2.796c-.992.004-1.982.091-2.96.26a1.364 1.364 0 0 0-1.132 1.568 1.363 1.363 0 0 0 1.596 1.077 14.865 14.865 0 0 1 11.251 2.605 1.363 1.363 0 0 0 1.582 0 14.868 14.868 0 0 1 11.25-2.605 1.363 1.363 0 0 0 1.596-1.077 1.363 1.363 0 0 0-1.131-1.569Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconOpenBook.displayName = "open-book";

export default IconOpenBook;
