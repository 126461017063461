import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconDark: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35 35"
      >
        <path
          fill="currentColor"
          d="M18.4 34.7a18.2 18.2 0 0 1-2.9-.3 18.2 18.2 0 0 1-15-20.8 18 18 0 0 1 9-13 2.4 2.4 0 0 1 2.7.2 2.4 2.4 0 0 1 1 2.4l-1.3-.1 1.2.2A15.7 15.7 0 0 0 23.3 21a15.8 15.8 0 0 0 8.5.5 2.4 2.4 0 0 1 2.7 3.5 18.2 18.2 0 0 1-16 9.7ZM10.7 2.9a15.7 15.7 0 0 0-5 22.8A15.7 15.7 0 0 0 32.2 24a18.5 18.5 0 0 1-9.7-.6A18.2 18.2 0 0 1 10.7 2.9Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconDark.displayName = "dark";
export default IconDark;
