import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconAttention: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M17.072 3.37A9.835 9.835 0 0 0 12 2a9.79 9.79 0 0 0-5.048 1.37A9.842 9.842 0 0 0 3.37 6.952 9.79 9.79 0 0 0 2 12c0 1.81.457 3.494 1.37 5.048a9.657 9.657 0 0 0 3.558 3.582A9.834 9.834 0 0 0 12 22a9.834 9.834 0 0 0 5.072-1.37 9.478 9.478 0 0 0 3.558-3.558A9.834 9.834 0 0 0 22 12a9.834 9.834 0 0 0-1.37-5.072 9.478 9.478 0 0 0-3.558-3.558Zm-1.034 15.553A7.814 7.814 0 0 1 12 20.03a7.814 7.814 0 0 1-4.038-1.106 7.786 7.786 0 0 1-2.885-2.884A7.814 7.814 0 0 1 3.97 12c0-1.442.369-2.788 1.106-4.038a7.785 7.785 0 0 1 2.885-2.885A7.814 7.814 0 0 1 12 3.97c1.442 0 2.789.369 4.038 1.106a7.786 7.786 0 0 1 2.885 2.885A7.814 7.814 0 0 1 20.03 12a7.814 7.814 0 0 1-1.106 4.038 7.787 7.787 0 0 1-2.884 2.885ZM11 8a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0V8Zm1 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconAttention.displayName = "attention";

export default IconAttention;
