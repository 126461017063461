import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSeat: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="#1B394E"
          fillRule="evenodd"
          d="M11.72 16.77c.814.043 4.2 0 5.881-.036v-.027c.902.018 2.583.628 2.724 2.84.07 1.07-.327 1.733-.672 2.095-.778.832-1.91.858-2.034.858h-5.952a.887.887 0 0 1-.884-.884c0-.487.398-.885.884-.885h5.952c.124 0 .522-.062.743-.3.15-.169.212-.425.194-.77-.07-1.132-.866-1.176-.955-1.176l-.365.006c-1.16.02-4.7.08-5.622.02-1.37-.08-1.937-.981-2.052-1.503l-.138-.702c-.27-1.377-.862-4.408-1.153-5.736-.239-1.07-.964-1.097-1.37-1.017-.965.159-.93.875-.903 1.087l1.344 6.987a.879.879 0 0 1-.698 1.035.879.879 0 0 1-1.035-.699l-1.353-7.03c-.124-.912.203-2.22 1.441-2.822l-.07-.31C4.159 7.652 3 6.422 3 4.91 3 3.3 4.309 2 5.918 2a2.921 2.921 0 0 1 2.919 2.918 2.909 2.909 0 0 1-1.486 2.53l.08.354c.38.035.84.141 1.282.424.451.292 1.044.867 1.291 1.99.39 1.751 1.256 6.261 1.292 6.456-.004-.023.005-.018.035-.002.05.027.165.088.389.1ZM5.918 3.76c-.636 0-1.15.513-1.15 1.15 0 .636.514 1.15 1.15 1.15.637 0 1.15-.514 1.15-1.15 0-.637-.513-1.15-1.15-1.15Zm3.273 17.246a1.327 1.327 0 1 1-2.654 0 1.327 1.327 0 0 1 2.654 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconSeat.displayName = "seat";
export default IconSeat;
