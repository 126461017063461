import Link from "@/components/Link/Link";
import DefaultTeaser from "./variants/DefaultTeaser";
import MarketingTeaser from "./variants/MarketingTeaser";

enum TeaserVariantEnum {
  default,
  marketing,
}

const getTeaserVariant = (type, props) => {
  switch (type) {
    case "marketing":
      return <MarketingTeaser {...props} />;
    default:
      return <DefaultTeaser {...props} />;
  }
};

const Teaser = (props) => {
  const { layout, link, content } = props;
  return (
    <Link
      href={link || content.link.href}
      className=" shadow-lg relative flex h-full"
    >
      {getTeaserVariant(layout, props)}
    </Link>
  );
};

export const Typo3Teaser = ({ content, layout = 0 }) => {
  return (
    <Teaser
      content={content}
      layout={TeaserVariantEnum[layout]}
      header={content.header}
      subheader={content.subheader}
      imgSrc={content.image?.[0].publicUrl}
      link={content.headerLink}
      linkText={content.linkText || "Mehr anzeigen"}
      width={500}
      height={400}
    />
  );
};

export default Object.assign(Teaser);
