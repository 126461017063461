import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconHybrid: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 117.8 47"
        className={className}
      >
        <g>
          <path
            fill="currentColor"
            d="M45.1 13.2c0 .1 0 .1 0 0-.1.2-.1.4-.2.6-.3.9-.8 1.7-1.3 2.4-.8 1.3-1.6 2.5-2.4 3.8-.5.8-.9 1.6-1.2 2.5-.3 1.1-.4 2.1-.1 3.2.4 1.6 1.4 2.7 2.9 3.4 1.3.6 2.6.6 4 .2 1.1-.4 2-1.1 2.6-2 .8-1.1 1-2.4.8-3.7-.1-1-.5-1.9-.9-2.7-.6-1.1-1.3-2.2-2-3.3-.6-.9-1.2-1.9-1.8-2.9-.1-.5-.3-.9-.4-1.5z"
          />
          <path
            fill="currentColor"
            d="M45.1 13.2c.1.5.3 1 .5 1.4.5 1 1.1 1.9 1.8 2.9.7 1.1 1.4 2.1 2 3.3.4.9.8 1.8.9 2.7.2 1.3 0 2.6-.8 3.7-.7 1-1.5 1.6-2.6 2-1.3.4-2.7.4-4-.2-1.5-.7-2.5-1.8-2.9-3.4-.3-1.1-.2-2.1.1-3.2.3-.9.7-1.7 1.2-2.5.8-1.3 1.6-2.5 2.4-3.8.5-.8.9-1.6 1.3-2.4 0-.1 0-.2.1-.5 0 .1 0 .1 0 0z"
          />
        </g>
        <path
          fill="currentColor"
          d="M112.7 19.7c.4-1.5.2-3.2-.6-4.6-1.7-2.7-3.9-5-6.5-6.9l.6-2.4c.2-.7 0-1.5-.4-2-.4-.6-1.1-1-1.8-1L95.3 2C86.9 1 78.4.6 70 .8c-.9 0-1.8 0-2.7.1-1.3 0-2.4 1.1-2.4 2.5 0 1.3 1.1 2.4 2.4 2.5h2.6c8.3-.2 16.6.2 24.8 1.2l5.9.6-.4 1.4c-.3 1.1.3 2.3 1.3 2.8 2.5 1.5 4.7 3.7 6.2 6.2 0 .6-.1 1.1-.3 1.7-.4 1-.1 2.1.7 2.8 2.8 2.9 3.8 7.1 2.7 10.9-1.7.3-3.4.5-5.1.5-1-4.7-5.1-8.2-10-8.2-4.8-.1-9.1 3.2-10.2 7.9l-18.2.4c-1.4 0-2.5 1.1-2.5 2.5 0 1.3 1.1 2.4 2.4 2.5l18.2-.5c1.2 4.5 5.2 7.6 9.8 7.6s8.7-2.9 10-7.3c2.6-.1 5.2-.4 7.7-.9.9-.2 1.6-.8 1.9-1.7 3.3-8.6-.1-14.2-2.1-16.6zm-17 21.5c-3 0-5.4-2.4-5.4-5.3s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c0 2.9-2.4 5.3-5.4 5.3zM56.6 34.1l-23.9.6c-.6-5-4.8-8.8-9.8-9s-9.5 3.2-10.6 8.2c-1.9 0-3.8-.3-5.6-.8.2-.2.4-.4.5-.6.5-.6.6-1.5.3-2.2-.5-1.2-.6-2.5-.4-3.8.7-.6.9-1.6.7-2.5l-.2-.6c1.9-1 3.9-1.6 5.9-2 3.4-.7 5.7-1.1 7.4-1.4 3-.4 5.8-1.2 8.5-2.5.9-.4 2-1.1 3.4-1.8.1 0 .1-.1.2-.1h.1c5-2.9 12.7-7.3 23.4-8.9 1.5-.2 2.7-1.1 2.7-2.5s-1.1-2.5-2.5-2.5h-.5C44.3 3.1 35.7 8.1 30.7 11c-1.3.7-2.4 1.4-3.2 1.8a23.5 23.5 0 0 1-7.3 2.1c-.2 0-.5.1-.8.1-1.6.3-3.9.7-6.8 1.3-7.1 1.5-9.2 3.8-9.7 4.5-.4.6-.6 1.4-.4 2.1l.3 1.1c-.7 1.9-.9 3.9-.4 5.8-.8 1.3-1.2 2.9-1.1 4.5 0 .8.5 1.6 1.1 2 3.1 2 8.3 2.3 10.1 2.3a10.28 10.28 0 0 0 19.5.9l24.6-.5h.1c1.4 0 2.5-1.1 2.5-2.5-.1-1.3-1.2-2.4-2.6-2.4zm-34.2 7.1c-3 0-5.4-2.4-5.4-5.3s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c0 2.9-2.4 5.3-5.4 5.3z"
        />
        <path
          fill="currentColor"
          d="M79.5 13c.1 0 .2.1.3.1.9.3 1.4 1.3 1 2.2-.5 1.2-1 2.3-1.4 3.5 0 0 0 .1-.1.2.1 0 .1.1.1.1l2.7 1.5c1.1.6 1.2 1.9.3 2.7l-6.1 5.8c-1 .9-2.5.5-2.8-.7-.1-.4-.1-.7.1-1.1.5-1.1 1-2.3 1.4-3.4 0 0 0-.1.1-.2-.1 0-.1-.1-.2-.1-.9-.5-1.7-1-2.6-1.5-1-.6-1.2-1.9-.3-2.7l6.1-5.8c.3-.3.6-.4 1-.5.1-.1.2-.1.4-.1z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconHybrid.displayName = "hybrid";
export default IconHybrid;
