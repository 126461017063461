import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSport: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m21.85 8.188-3.55-3.55A1.25 1.25 0 1 0 16.523 6.4l2.65 2.65L9.05 19.175l-2.65-2.65A1.25 1.25 0 1 0 4.637 18.3l3.512 3.513 3.513 3.512a1.25 1.25 0 1 0 1.775-1.762l-2.613-2.613L20.95 10.825l2.65 2.65a1.248 1.248 0 0 0 1.769-.006 1.252 1.252 0 0 0-.006-1.769L21.85 8.188ZM4.636 21.825A1.255 1.255 0 0 0 2.862 23.6l3.537 3.538a1.25 1.25 0 0 0 .888.362 1.247 1.247 0 0 0 1.257-1.25 1.25 1.25 0 0 0-.37-.887l-3.537-3.538ZM27.137 6.4l-3.538-3.537a1.256 1.256 0 0 0-2.047 1.368c.063.152.156.29.272.407l3.538 3.537a1.25 1.25 0 0 0 1.775 0 1.251 1.251 0 0 0 0-1.775Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSport.displayName = "sport";

export default IconSport;
