enum Options {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export type ViewPorts = {
  [key in Options]: {
    numbersOfColumnsToUse:
      | "1"
      | "2"
      | "3"
      | "4"
      | "5"
      | "6"
      | "7"
      | "8"
      | "9"
      | "10"
      | "11"
      | "12";
  };
};

export const getColumnClasses = (viewPorts: ViewPorts): string => {
  const breakpoints = {
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
    xxl: "2xl",
  };

  const colspans = {
    "1": "col-span-1",
    "2": "col-span-2",
    "3": "col-span-3",
    "4": "col-span-4",
    "5": "col-span-5",
    "6": "col-span-6",
    "7": "col-span-7",
    "8": "col-span-8",
    "9": "col-span-9",
    "10": "col-span-10",
    "11": "col-span-11",
    "12": "col-span-12",
  };

  return Object.entries(viewPorts)
    .map(
      ([key, { numbersOfColumnsToUse }]) =>
        `${breakpoints[key]}:${colspans[numbersOfColumnsToUse]}`
    )
    .concat(["col-span-12"])
    .join(" ");
};
