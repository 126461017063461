import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconAirplane: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg fill="none" viewBox="0 0 30 30" className={className}>
        <path
          fill="currentColor"
          d="M27.501 6.35a3.85 3.85 0 0 0-6.575-2.725l-3.512 3.513-8.113-2.7a1.25 1.25 0 0 0-1.25.3l-2.975 2.95A1.25 1.25 0 0 0 5.438 9.7l6.476 2.938-3.25 3.25-2.138-1.075a1.25 1.25 0 0 0-1.45.187l-2.213 2.263a1.25 1.25 0 0 0 0 1.762l8.113 8.113a1.25 1.25 0 0 0 1.762 0l2.263-2.213a1.25 1.25 0 0 0 .238-1.45l-1.075-2.137 3.25-3.25 2.937 6.475a1.249 1.249 0 0 0 2.012.362l2.95-2.95a1.25 1.25 0 0 0 .3-1.25l-2.7-8.112 3.463-3.538a3.812 3.812 0 0 0 1.125-2.725Zm-2.9.963-4.05 4.05a1.25 1.25 0 0 0-.3 1.25l2.7 8.1-1.125 1.125-2.938-6.463a1.25 1.25 0 0 0-2.025-.362l-5.15 5.2a1.25 1.25 0 0 0-.237 1.437l1.025 2.1-.7.7-6.288-6.3.738-.65 2.137 1.075a1.25 1.25 0 0 0 1.438-.237l5.175-5.2a1.25 1.25 0 0 0-.362-2.025L8.126 8.175 9.251 7.05l8.1 2.7a1.25 1.25 0 0 0 1.25-.3l4.05-4.05a1.338 1.338 0 0 1 1.913 0 1.25 1.25 0 0 1 .4.95 1.324 1.324 0 0 1-.363.963Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconAirplane.displayName = "airplane";

export default IconAirplane;
