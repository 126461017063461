import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconMonitor: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M23.75 2.5H6.25A3.75 3.75 0 0 0 2.5 6.25v12.5a3.75 3.75 0 0 0 3.75 3.75H10V25H6.25a1.25 1.25 0 0 0 0 2.5h17.5a1.25 1.25 0 0 0 0-2.5H20v-2.5h3.75a3.75 3.75 0 0 0 3.75-3.75V6.25a3.75 3.75 0 0 0-3.75-3.75ZM17.5 25h-5v-2.5h5V25Zm7.5-6.25A1.25 1.25 0 0 1 23.75 20H6.25A1.25 1.25 0 0 1 5 18.75V17.5h20v1.25ZM25 15H5V6.25A1.25 1.25 0 0 1 6.25 5h17.5A1.25 1.25 0 0 1 25 6.25V15Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconMonitor.displayName = "monitor";

export default IconMonitor;
