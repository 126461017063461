import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPlayFull: FC<Icon> = ({
  className = "",
  purposeLabel,
  outerColor = "#FF7D00",
  innerColor = "#fff",
}) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 65 64"
        className={className}
      >
        <ellipse cx="32.473" cy="32" fill={outerColor} rx="31.993" ry="32" />
        <path
          fill={innerColor}
          fillRule="evenodd"
          d="M42.553 30.546c.7.514.7 1.543 0 2.057L28.572 42.89c-.864.636-2.097.031-2.097-1.029V21.288c0-1.06 1.233-1.664 2.097-1.029l13.981 10.287Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconPlayFull.displayName = "playFull";
export default IconPlayFull;
