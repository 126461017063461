import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSort: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.99 4c.344 0 .653.149.871.378l4.79 4.767c.47.47.47 1.226 0 1.696s-1.226.47-1.696 0l-2.75-2.75v9.889s-.539 1.203-1.203 1.203a1.203 1.203 0 0 1-1.204-1.203V8.09l-2.75 2.751c-.47.47-1.226.47-1.696 0a1.196 1.196 0 0 1 0-1.696l4.79-4.79c.23-.24.539-.355.848-.355Zm15.973 8.342a1.196 1.196 0 0 1 1.69-.006c.464.47.463 1.223-.005 1.69l-4.79 4.79c-.23.241-.539.356-.848.356-.344 0-.653-.15-.871-.378l-4.767-4.767a1.196 1.196 0 0 1 0-1.696c.47-.47 1.226-.47 1.696 0l2.75 2.75V5.203c0-.664.539-1.203 1.203-1.203.665 0 1.192.539 1.192 1.203v9.89l2.75-2.75Zm1.69-.006a.314.314 0 0 1 .006.006l-.011-.011.005.005Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSort.displayName = "sort";
export default IconSort;
