/* eslint-disable @next/next/no-img-element */
import { Icon } from "@/components/Icon";
import Image from "next/image";
import Link from "@/components/Link/Link";
import { FC, Fragment } from "react";
import * as gtag from "@/lib/gtag";

interface LinkI {
  text: string;
  icon?: string;
  image?: string;
  url: string;
}

interface linkListPicI {
  subheader: string;
  link: LinkI[];
}

interface ContentI {
  header: string;
  subheader: string;
  linkListPic: linkListPicI[];
}

const IconTeaser: FC<{ content: ContentI }> = ({ content }) => {
  const { linkListPic = [] } = content;
  return (
    <div
      className="w-full py-12 bg-gradient-to-br from-[#ffb400] to-[#ff7d00]"
      style={{ contentVisibility: "auto" }}
    >
      <div className="container mx-auto">
        <div className="w-full">
          <p className="text-5xl lg:text-6xl text-white text-center p-8 font-bold">
            {content.header}
          </p>
        </div>

        {linkListPic &&
          linkListPic.map((item, index) => {
            return (
              <Fragment key={`linkListPic-${index}`}>
                <Headline>{item.subheader}</Headline>
                <div className="grid grid-cols-3 lg:gap-[50px] gap-5 lg:grid-cols-6 pb-8">
                  {item.link?.map(({ text, icon, image, url }, index) => {
                    return (
                      <Link href={url} key={`linkListPic-link-${index}`}>
                        <div
                          className="flex flex-col cursor-pointer hover:scale-105"
                          onClick={() => {
                            gtag.event({
                              event: "itemClick",
                              itemClick: "car",
                              carCategory: text || "carCategory",
                              carType: icon || "carCategory",
                            });
                          }}
                        >
                          <Media icon={icon} image={image} alt="" />
                          <Title>{text}</Title>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

const Headline = ({ children }) => {
  return <p className="text-3xl font-bold text-white">{children}</p>;
};

const Title = ({ children }) => {
  return <p className="text-base text-white text-center ">{children}</p>;
};

const Media = ({ icon, image, alt }) => {
  if (icon != 0) {
    return (
      <Icon
        type={icon}
        className="xl:w-[160px] w-[100px] h-[120px] text-white opacity-50 mx-auto"
      />
    );
  }
  if (image !== null) {
    const imgProps: any = Object.entries(image)[0][1];
    return (
      <Image
        src={imgProps.publicUrl}
        width={imgProps.properties.cropDimensions.width}
        height={imgProps.properties.cropDimensions.height}
        alt={alt}
        role="presentation"
        className="mb-8"
      />
    );
  } else {
    return <p>No Image</p>;
  }
};

export default Object.assign(IconTeaser, {
  Title,
  Headline,
  Media,
});
