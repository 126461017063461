import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconTrash: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 3.5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1V6h5a1 1 0 1 1 0 2h-1.105l-1.401 12.61a1 1 0 0 1-.994.89h-11a1 1 0 0 1-.994-.89L4.105 8H3a1 1 0 0 1 0-2h5V3.5ZM10 6h4V4.5h-4V6ZM6.117 8l1.278 11.5h9.21L17.883 8H6.117ZM10 9.5a1 1 0 0 1 1 1V17a1 1 0 1 1-2 0v-6.5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1V17a1 1 0 1 1-2 0v-6.5a1 1 0 0 1 1-1Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconTrash.displayName = "trash";
export default IconTrash;
