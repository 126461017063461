import Badge from "@/components/Badge/Badge";
import ArtDirectionImage from "@/components/Image/ArtDirectionImage";
import Link from "next/link";
import * as gtag from "@/lib/gtag";
import { Icon } from "@/components/Icon";

interface SolrSettings {
  modell?: string;
  auspraegung?: string;
  layout?: string;
  bodystyle?: string;
  equipmentline?: string;
  kraftstoffart?: string;
}

interface ItemSettings {
  image: string;
  imageTablet?: string;
  imageMobile?: string;
  imageAlt?: string;
  tabText?: string;
  solr?: SolrSettings;
  link?: string;
  linkText?: string;
  showNettoPreis?: boolean;
}

interface ItemStats {
  stats?: {
    rabatt?: {
      min?: string;
      max?: number;
    };
    monatlicherateFinanzierung?: {
      min?: number;
    };
    preis?: { min?: number };
  };
}

interface Item {
  settings?: ItemSettings;
  stats?: ItemStats;
}

interface ModelCardProps {
  item: Item & {
    settings: ItemSettings & { imageMobile?: string; solr?: SolrSettings };
  };
}

const ModelCard: React.FC<ModelCardProps> = ({
  item: { settings = {}, stats = {} },
}) => {
  const defaultSettings: ItemSettings = {
    image: "",
    imageAlt: "Alt Text",
    tabText: "",
    link: "#",
    linkText: "Learn More",
    showNettoPreis: true,
    solr: {}, // Standardwert für solr hinzugefügt
  };

  const mergedSettings = { ...defaultSettings, ...settings };

  const { image, imageMobile, imageAlt, tabText, link, linkText } =
    mergedSettings;

  const discount = stats?.stats?.rabatt?.max || 35;

  // Sicherstellen, dass solr immer definiert ist
  const solrSettings: SolrSettings = mergedSettings.solr || {};
  const { modell } = solrSettings;

  const handleTracking = () => {
    gtag.event({
      event: "itemClick",
      itemClick: "popular models",
      title: `${tabText || "Model"}`,
    });
  };

  return (
    <Link
      href={link || ""}
      className="select-none relative rounded-lg shadow-md hover:shadow-lg ease-in duration-150 2xl:p-8 p-6  min-h-[305px] 2xl:min-h-[450px] 2xl:max-h-[450px] max-h-[350px] w-full justify-between items-center flex h-full group"
      onClick={handleTracking}
    >
      <ArtDirectionImage
        className="absolute inset-0 w-full h-full rounded-lg "
        image={image}
        imageMobile={imageMobile}
        priority={false}
        sizes="(max-width: 768px) 30vw, 50vw"
        alt={imageAlt || "Art Direction Image"}
      />
      <div className="flex flex-wrap md:flex-col z-10 h-full w-full justify-center">
        <div className="mb-auto lg:pt-2 pt-1 flex flex-col justify-center lg:gap-y-6 gap-y-3">
          <div className="mx-auto h-[26px]">
            {discount && (
              <Badge
                color="discount"
                size="small"
                className="min-w-[85px] xl:min-w-[125px] lg:!text-xl !text-sm"
              >
                -{discount}% Rabatt*
              </Badge>
            )}
          </div>

          <div className="font-bold md:text-[28px]  text-xl mb-2 text-blue-dark text-center group-hover:underline">
            Ford {modell}
          </div>
        </div>

        <div className="mt-auto">
          <div className="flex justify-center">
            <p className="px-4 text-center break-normal text-white font-bold underline lg:underline-offset-4 underline-offset-2 md:w-full w-[165px] lg:text-base text-sm">
              {linkText}
              <Icon
                type="arrow"
                className="inline lg:relative lg:w-7 lg:h-7 h-5 w-5 lg:ml-0 ml-2 lg:left-2 lg:group-hover:left-4 ease-in duration-150 text-orange-light group-hover:text-orange-medium"
              />
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ModelCard;
