import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconWeight: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M21.858 16.772c-.156-.863-.308-1.73-.462-2.596l-.327-1.85-.182-1.045c-.16-.91-.322-1.85-.512-2.774A3.883 3.883 0 0 0 19.1 6.428a3.908 3.908 0 0 0-2.268-.912c-.507-.02-1.019-.02-1.514-.022h-.594a3.005 3.005 0 0 0 .338-1.853 2.974 2.974 0 0 0-1.07-1.919 2.961 2.961 0 0 0-3.506-.274C8.963 2.45 8.558 3.816 9.32 5.51h-.6c-.473 0-.968 0-1.453.019a3.881 3.881 0 0 0-2.17.798A3.857 3.857 0 0 0 3.77 8.216a14.536 14.536 0 0 0-.358 1.725l-.072.42c-.325 1.869-.646 3.74-.966 5.614L2 18.093v.234c.022.095.039.194.055.29.035.226.088.448.157.666.214.709.629 1.34 1.194 1.82a3.86 3.86 0 0 0 1.995.88h.036l.044.017h13.031l.08-.025.072-.019a3.974 3.974 0 0 0 2.09-1.012 3.949 3.949 0 0 0 1.162-2.003 5.368 5.368 0 0 0-.058-2.169ZM10.575 4.055a1.447 1.447 0 0 1 1.981-1.335c.174.071.331.177.463.31a1.433 1.433 0 0 1 .056 2.068 1.439 1.439 0 0 1-1.606.304 1.44 1.44 0 0 1-.47-.333 1.405 1.405 0 0 1-.413-1.014h-.01Zm6.836 16.352H6.128a2.392 2.392 0 0 1-1.613-.526 2.308 2.308 0 0 1-.809-2.328l.438-2.467.437-2.467c.063-.345.123-.69.182-1.036.157-.9.32-1.826.506-2.742A2.29 2.29 0 0 1 7.471 7.09h9.095c1.15 0 2.098.861 2.301 2.09.184 1.096.385 2.22.58 3.29l.29 1.625c.06.345.124.69.187 1.036.157.883.322 1.798.462 2.698a2.136 2.136 0 0 1-.484 1.645 2.222 2.222 0 0 1-1.547.869c-.31.063-.627.065-.944.065Z"
        />
        <path
          fill="currentColor"
          d="M16.17 13.429h-1.176a.896.896 0 0 0-.607.211.749.749 0 0 0-.267.542.74.74 0 0 0 .231.556c.155.15.368.238.594.244h.245a.652.652 0 0 1-.28.338.754.754 0 0 1-.45.105.768.768 0 0 1-.488-.192.646.646 0 0 1-.219-.442v-1.554a.636.636 0 0 1 .197-.439.755.755 0 0 1 .467-.207.92.92 0 0 1 .409.067.84.84 0 0 1 .324.237c.155.139.363.219.58.223a.905.905 0 0 0 .59-.198.761.761 0 0 0 .283-.514.737.737 0 0 0-.178-.552 2.376 2.376 0 0 0-1.115-.736 2.585 2.585 0 0 0-1.374-.047 2.412 2.412 0 0 0-1.266.646 2.07 2.07 0 0 0-.609 1.202c-.052.395-.07.794-.056 1.192v.51c-.016.445.11.884.361 1.265.212.343.521.628.895.824.374.196.798.296 1.23.29h.039c.428.001.85-.101 1.22-.297.372-.195.68-.477.894-.817.391-.576.381-1.195.332-1.82a.719.719 0 0 0-.271-.459.849.849 0 0 0-.536-.178Z"
        />
        <path
          fill="currentColor"
          d="M10.523 13.5c.418-.378.82-.76 1.2-1.137a.83.83 0 0 0 .206-.295.774.774 0 0 0 .057-.347.731.731 0 0 0-.102-.306.798.798 0 0 0-.228-.242.913.913 0 0 0-1.176.124c-.404.381-.809.765-1.213 1.15l-.57.545h-.016V11.763a.706.706 0 0 0-.064-.294.76.76 0 0 0-.182-.25.837.837 0 0 0-.273-.164A.893.893 0 0 0 7.84 11a.882.882 0 0 0-.323.052.828.828 0 0 0-.274.165.749.749 0 0 0-.182.25.697.697 0 0 0-.06.296v4.45c-.002.101.02.202.061.296.042.093.103.179.181.25.078.072.17.13.273.168a.9.9 0 0 0 .92-.177.752.752 0 0 0 .245-.555V15.069l.079.07c.204.169.408.341.606.523s.399.37.597.557c.197.188.388.372.59.554a.79.79 0 0 0 .257.167c.098.039.203.06.31.06a.996.996 0 0 0 .329-.055.814.814 0 0 0 .38-.25.712.712 0 0 0 .167-.403.75.75 0 0 0-.056-.374.816.816 0 0 0-.234-.31c-.435-.412-.873-.827-1.338-1.272l-.415-.387.122-.094c.158-.112.31-.227.448-.354Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconWeight.displayName = "weight";
export default IconWeight;
