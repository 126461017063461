import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSpeaker: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M11.45,3.33A1.34,1.34,0,0,0,10,3.49l-5,4H1.27A1.34,1.34,0,0,0-.06,8.8v6.4a1.33,1.33,0,0,0,1.33,1.33h3.8l5,4a1.34,1.34,0,0,0,2.17-1V4.53A1.31,1.31,0,0,0,11.45,3.33ZM9.54,16.69,6.37,14.16a1.31,1.31,0,0,0-.83-.29H2.6V10.13H5.54a1.31,1.31,0,0,0,.83-.29L9.54,7.31Z"
        />
        <path
          fill="currentColor"
          d="M16.66,7.28a1.32,1.32,0,0,0-.94-.39h0a1.32,1.32,0,0,0-.94.39,1.34,1.34,0,0,0,0,1.89,4,4,0,0,1,0,5.65,1.31,1.31,0,0,0-.39.95,1.29,1.29,0,0,0,.39.94,1.32,1.32,0,0,0,.94.39h0a1.32,1.32,0,0,0,.94-.39A6.68,6.68,0,0,0,16.66,7.28Z"
        />
        <path
          fill="currentColor"
          d="M20.43,3.51A1.34,1.34,0,0,0,18.54,5.4a9.34,9.34,0,0,1,0,13.2,1.34,1.34,0,0,0,1.89,1.89A12,12,0,0,0,20.43,3.51Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSpeaker.displayName = "speaker";
export default IconSpeaker;
