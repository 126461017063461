import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconExchange: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.837 3.552a.91.91 0 0 1 1.285-1.286l3.306 3.306a.904.904 0 0 1 .257.775.906.906 0 0 1-.257.51l-3.306 3.307a.91.91 0 1 1-1.285-1.286l1.753-1.754H8.215a2.397 2.397 0 0 0-2.397 2.397v1.653a.91.91 0 0 1-1.818 0V9.52a4.215 4.215 0 0 1 4.215-4.215h9.375l-1.753-1.754Zm3.039 9.275a.91.91 0 1 1 1.818 0v1.652a4.215 4.215 0 0 1-4.215 4.215H7.104l1.754 1.754a.91.91 0 0 1-1.286 1.286l-3.3-3.3a.919.919 0 0 1-.19-.271.906.906 0 0 1 .184-1.02l3.306-3.306a.91.91 0 0 1 1.286 1.285l-1.754 1.754h9.375a2.397 2.397 0 0 0 2.397-2.397v-1.652Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconExchange.displayName = "exchange";
export default IconExchange;
