import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSpeakerNo: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M1.646 2.707a.5.5 0 0 0 0 .707l4.37 4.37H5.49a1 1 0 0 0-1 1v6.049a1 1 0 0 0 1 1h3.952a1 1 0 0 1 .707.293l4.707 4.707c.63.63 1.707.184 1.707-.707V18.33l3.824 3.823a.5.5 0 0 0 .707 0l1.06-1.06a.5.5 0 0 0 0-.707L3.414 1.646a.5.5 0 0 0-.707 0l-1.06 1.061ZM16.562 3.491v7.803l-5.109-5.108 3.402-3.402c.63-.63 1.707-.184 1.707.707Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSpeakerNo.displayName = "speaker-no";
export default IconSpeakerNo;
