import MappedComponent from "../ComponentMap/ComponentMap";

export const Shortcut = ({ content }) => {
  const { shortcut = [] } = content;
  return (
    <>
      {Object.entries(shortcut).map(([key, value]: any) => (
        <MappedComponent {...value} key={key} />
      ))}
    </>
  );
};
