const HTML = ({ content, className = "" }) => {
  const { bodytext } = content;
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: bodytext || content }}
    />
  );
};

export default HTML;
