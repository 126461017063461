import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconRadio: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.213 19.406a.909.909 0 0 0 0-1.285 8.182 8.182 0 0 1 0-11.57.91.91 0 0 0-1.285-1.285c-3.904 3.905-3.904 10.235 0 14.14a.91.91 0 0 0 1.285 0Zm11.57-12.854a.909.909 0 1 1 1.285-1.286c3.904 3.905 3.904 10.235 0 14.14a.91.91 0 0 1-1.286-1.285 8.182 8.182 0 0 0 0-11.57ZM8.786 16.833a.909.909 0 0 0-.001-1.285 4.545 4.545 0 0 1 0-6.432A.91.91 0 0 0 7.5 7.829a6.363 6.363 0 0 0 0 9.005.909.909 0 0 0 1.286 0Zm6.422-8.994a.909.909 0 0 0 0 1.286 4.545 4.545 0 0 1 0 6.432.909.909 0 1 0 1.286 1.286 6.364 6.364 0 0 0 0-9.004.909.909 0 0 0-1.286 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconRadio.displayName = "radio";
export default IconRadio;
