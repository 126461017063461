import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconParking: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M9.375 5a1.25 1.25 0 0 0 .887-.362l1.25-1.25a1.255 1.255 0 1 0-1.774-1.775l-1.25 1.25A1.25 1.25 0 0 0 9.375 5Zm5 0a1.25 1.25 0 0 0 .887-.362l1.25-1.25a1.255 1.255 0 1 0-1.774-1.775l-1.25 1.25A1.248 1.248 0 0 0 14.375 5Zm5 0a1.25 1.25 0 0 0 .887-.362l1.25-1.25a1.256 1.256 0 0 0-1.774-1.775l-1.25 1.25A1.248 1.248 0 0 0 19.375 5ZM22.4 19.525a.953.953 0 0 0-.112-.225l-.15-.187-.188-.15a.951.951 0 0 0-.225-.113.752.752 0 0 0-.238-.075 1.25 1.25 0 0 0-1.124.338c-.054.06-.104.122-.15.187a.953.953 0 0 0-.113.225.802.802 0 0 0-.075.225 1.71 1.71 0 0 0 0 .25 1.25 1.25 0 0 0 .775 1.15c.142.061.295.095.45.1A1.25 1.25 0 0 0 22.5 20a1.71 1.71 0 0 0 0-.25.8.8 0 0 0-.1-.225Zm2.3-5.637L23 8.813a3.75 3.75 0 0 0-3.55-2.5h-8.9A3.75 3.75 0 0 0 7 8.813l-1.7 5.075A3.75 3.75 0 0 0 2.5 17.5v5A3.75 3.75 0 0 0 5 26.025V27.5a1.25 1.25 0 1 0 2.5 0v-1.25h15v1.25a1.25 1.25 0 1 0 2.5 0v-1.475a3.75 3.75 0 0 0 2.5-3.525v-5a3.75 3.75 0 0 0-2.8-3.612ZM9.363 9.6a1.25 1.25 0 0 1 1.187-.85h8.9a1.25 1.25 0 0 1 1.25.85l1.313 4.15H7.986L9.364 9.6ZM25 22.5a1.25 1.25 0 0 1-1.25 1.25H6.25A1.25 1.25 0 0 1 5 22.5v-5a1.25 1.25 0 0 1 1.25-1.25h17.5A1.25 1.25 0 0 1 25 17.5v5Zm-8.75-3.75h-2.5a1.25 1.25 0 1 0 0 2.5h2.5a1.25 1.25 0 0 0 0-2.5Zm-6.35.775a.951.951 0 0 0-.113-.225l-.15-.187a1.25 1.25 0 0 0-1.362-.263 1.25 1.25 0 0 0-.413.263c-.053.06-.103.122-.15.187a.951.951 0 0 0-.112.225.8.8 0 0 0-.1.225 1.699 1.699 0 0 0 0 .25 1.25 1.25 0 0 0 2.5 0 1.71 1.71 0 0 0 0-.25.8.8 0 0 0-.1-.225Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconParking.displayName = "parking";

export default IconParking;
