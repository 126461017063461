import Image from "@/components/Image/Image";
import { Icon } from "@/components/Icon";
import { Button } from "@/components/Button/Button";
import Link from "next/link";
import * as gtag from "@/lib/gtag";
import { cva } from "class-variance-authority";

const CareerTileStyles = cva("", {
  variants: {
    backgroundColor: {
      true: "bg-blue-dark text-white",
      false: "bg-white text-blue-dark",
    },
    buttonVariant: {
      true: "!text-white !bg-orange-light hover:!border-orange-medium hover:!bg-orange-medium hover:!underline",
      false: "!hover:border-orange-light group-hover:text-orange-light",
    },
  },
  defaultVariants: {
    backgroundColor: false,
  },
});

const CareerTile = ({
  image,
  header,
  location,
  duration,
  entry,
  url,
  highlight = false,
}) => {
  const gtmTracking = () => {
    gtag.event({
      event: "itemClick",
      itemClick: "jobTile",
      itemName: header,
    });
  };
  const handleClick = () => {
    gtmTracking();
  };

  return (
    <Link
      className={`${CareerTileStyles({
        backgroundColor: highlight,
      })} w-full h-full flex lg:max-h-[190px] lg:min-h-[192px] group cursor-pointer`}
      href={url}
      onClick={handleClick}
    >
      <div className="md:flex hidden">
        <Image
          src={image}
          alt="Karriere Img"
          objectFit="cover"
          width={254}
          height={192}
        />
      </div>

      <div className="flex flex-grow lg:flex-row flex-col justify-between md:py-7 py-6 md:px-7 px-5 h-full w-full">
        <div className="">
          <p className="font-bold md:text-3xl text-base mb-5 ">{header}</p>
          <ul className="text-sm font-bold mt-4  sm:mb-0 md:mb-5 lg:mb-0 ">
            <li
              key={"location"}
              className="flex items-center align-center md:py-4 py-[6px] "
            >
              <Icon
                type={"location"}
                className="w-[20px] h-[20px] text-orange-light mr-4 "
              />
              <span className=" text-sm">{location}</span>
            </li>
            <li
              key={"duration"}
              className="flex items-center align-center md:py-4 py-[6px] "
            >
              <Icon
                type={"clock"}
                className="w-[20px] h-[20px] text-orange-light mr-4 "
              />
              <span className=" text-sm">
                {duration ? duration : "unbefristet"}
              </span>
            </li>
            <li
              key={"calendar"}
              className="flex items-center align-center md:py-4 py-[6px] "
            >
              <Icon
                type={"calendar"}
                className="w-[20px] h-[20px] text-orange-light mr-4 "
              />
              <span className=" text-sm">{entry}</span>
            </li>
          </ul>
        </div>
        <div className="flex justify-center items-center mt-6 lg:mt-0">
          <Button
            type="button"
            variant="hollow"
            as="span"
            color="primary"
            className={`${CareerTileStyles({
              buttonVariant: highlight,
            })} w-full cursor-pointer`}
          >
            Jetzt bewerben
          </Button>
        </div>
      </div>
    </Link>
  );
};

export default CareerTile;
