import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconFuel: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M21.811 7.237v8.914c0 1.255-1.02 2-2.274 2-1.255 0-1.891-.745-1.891-2v-4.754c0-.133-.168-.267-.328-.396-.106-.085-.21-.168-.26-.247v7.103l.588.294c.368.184.6.56.6.971v1.783c0 .598-.455 1.095-1.058 1.095H4.114C3.516 22 3 21.506 3 20.905v-1.783c0-.411.232-.787.6-.971l.589-.294V4.86C4.189 3.282 5.499 2 7.086 2h7.13a2.84 2.84 0 0 1 2.841 2.86v3.26c1.324.486 2.377 1.826 2.377 3.277v4.754c0 .056.05.439.27.439.195 0 .176-.303.17-.41l-.002-.029-.039-6.94c-.66-.395-1.34-1.12-1.367-1.903l-.577-.288a1.087 1.087 0 0 1-.487-1.457 1.082 1.082 0 0 1 1.454-.486l2.37 1.189c.368.184.585.56.585.971ZM5.09 20.033v-.942l.995-.521a.095.095 0 0 0 .05-.085V5.342c0-.852.685-1.54 1.502-1.54h6.269c.813 0 1.452.685 1.452 1.54v13.144c0 .035.019.068.05.084l.994.521v.942H5.09Zm8.705-9.917V6.712c0-.43-.368-.777-.822-.777H8.47c-.455 0-.823.348-.823.777v3.404c0 .43.368.778.823.778h4.502c.454 0 .822-.349.822-.778Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFuel.displayName = "fuel";
export default IconFuel;
