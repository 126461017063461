import { Icon } from "@/components/Icon";
import CDNImage from "@/components/Image/CDNImage";
import CDNVideo from "@/components/Video/CDNVideo";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";
import { FC, useContext, useState } from "react";
import { Slot } from "utils/create-slots";
import { ProductContext } from "./Product";
interface MediaProps {
  priority?: boolean;
  hasVideo?: boolean;
  index?: number;
  content: {
    image: string;
    movie?: string;
  };
  alt: string;
  sizesObj?: {
    [key: string]: string;
  };
}

const Media: FC<MediaProps> = ({
  content = { image: "", movie: "" },
  alt,
  sizesObj = {
    "(max-width: 768px)": "100vw",
    "(max-width: 1024px)": "50vw",
    "": "25vw",
  },
  hasVideo = false,
  index,
  priority = false,
}) => {
  const { image = "", movie = "" } = content;
  const { hoverState, isInViewport } = useContext(ProductContext);
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const VIDEO_LAYER_TEXT = "Video komplett ansehen";

  useIsomorphicLayoutEffect(() => {
    if (!hasVideo) return;

    if (window.innerWidth <= 480 && isInViewport) {
      setVideoSrc(movie);
      setTimeout(() => {
        setPlaying(true);
      }, 1000);
      return;
    }
    if (hoverState) {
      setVideoSrc(movie);
      setPlaying(hoverState);
    } else {
      setPlaying(false);
      setVideoSrc("");
      setEnded(false);
    }
  }, [hoverState, isInViewport]);

  if (!content) return null;

  return (
    <Slot name="media">
      <CDNImage
        alt={alt}
        src={image}
        className={`absolute transition-opacity duration-1000 h-full object-cover ${
          movie && playing ? "opacity-0" : "opacity-100"
        }`}
        fill
        sizesObj={sizesObj}
        priority={!!priority}
      />

      {movie && hasVideo && (
        <>
          {ended && (
            <div className=" z-20 absolute w-full transition-opacity duration-1000 h-full object-cover flex justify-center items-center ">
              <span className="text-white z-20 font-bold  border-b-[1px] pb-1 border-white flex items-center justify-center">
                {VIDEO_LAYER_TEXT}
              </span>
              <Icon
                type={"chevron"}
                className="w-5 h-5 text-white ml-2 z-20 "
              />
              <div className="absolute w-full transition-opacity duration-1000 h-full object-cover flex justify-center items-center  bg-black opacity-60"></div>
            </div>
          )}
          {videoSrc !== "" && (
            <CDNVideo
              src={movie}
              playing={playing}
              setEnded={setEnded}
              controls={false}
              preload="none"
              playsInline
              isMuted={true}
              className="w-full h-full object-cover block"
              preview={true}
            />
          )}
        </>
      )}
    </Slot>
  );
};

export default Media;
