import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconFacebook: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2 11.7C2 6.128 6.367 2 12 2s10 4.13 10 9.703c0 5.572-4.367 9.7-10 9.7-1.012 0-1.982-.136-2.895-.386a.804.804 0 0 0-.535.04l-1.985.876a.8.8 0 0 1-1.122-.708l-.056-1.78a.79.79 0 0 0-.267-.57C3.195 17.135 2 14.615 2 11.7Zm6.933-1.822-2.938 4.66c-.282.447.268.95.688.63l3.155-2.395a.6.6 0 0 1 .722-.003l2.338 1.753a1.5 1.5 0 0 0 2.17-.4l2.94-4.658c.28-.447-.27-.952-.69-.632l-3.155 2.395a.6.6 0 0 1-.723.002l-2.337-1.752a1.5 1.5 0 0 0-2.17.4Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFacebook.displayName = "facebook";
export default IconFacebook;
