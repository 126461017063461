import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCopy: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.92 21.68h8.663l.14-.038c.079-.02.158-.041.236-.066a2.12 2.12 0 0 0 1.47-1.98c.002-.06.001-.12 0-.189v-.118h.108c.065.001.126.002.186 0a15 15 0 0 1 .484-.005c.367 0 .734 0 1.09-.06.951-.163 1.627-.998 1.674-1.962.004-.076.007-.152.007-.227-.003-4.13-.002-8.26.002-12.392 0-.857-.339-1.517-1.073-1.962a2.035 2.035 0 0 0-1.076-.286 4332.63 4332.63 0 0 0-8.078 0 2.121 2.121 0 0 0-2.139 2.08v.313h-.652c-.312 0-.624-.001-.935.016-1.045.056-1.903.94-1.953 1.985a5.628 5.628 0 0 0-.008.264c.003 4.125.002 8.249-.003 12.373 0 .866.339 1.537 1.088 1.97.16.093.342.148.523.204.083.025.166.05.247.08Zm9.133-14.646v12.39c0 .457-.18.74-.542.842a1.115 1.115 0 0 1-.297.03H6.289c-.35 0-.598-.12-.732-.35a.982.982 0 0 1-.117-.503c-.002-4.141-.002-8.283 0-12.425 0-.545.31-.854.853-.854 2.636-.002 5.27-.002 7.906 0 .252 0 .45.063.592.184.172.145.262.376.262.686Zm-5.8-2.246H9.02c-.137-.652.323-1.017.765-1.014 1.712.006 3.425.005 5.137.005h2.883c.46.001.747.273.792.733.005.063.005.126.005.188v12.277c.005.126-.007.251-.036.373-.085.294-.282.512-.592.529-.353.02-.708.015-1.071.011a40.622 40.622 0 0 0-.473-.004v-3.184c-.003-2.556-.005-5.113.006-7.67.005-1.09-.667-1.881-1.456-2.123a2.944 2.944 0 0 0-.83-.116 504.239 504.239 0 0 0-3.672-.006H9.254Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="M9.018 4.787c-.137-.652.323-1.016.768-1.015 2.674.01 5.347.004 8.02.007.461 0 .748.272.793.732.006.062.004.125.004.188v12.277c.005.126-.007.251-.035.373-.085.296-.282.513-.592.529-.505.028-1.012.007-1.544.007v-.233c0-3.54-.01-7.08.006-10.62.004-1.09-.667-1.881-1.457-2.123a2.943 2.943 0 0 0-.83-.116c-1.632-.01-3.264-.005-4.895-.005h-.238Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCopy.displayName = "copy";
export default IconCopy;
