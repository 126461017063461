import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPen: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m21.68 6.48-2.027 2.027-4.16-4.16L17.52 2.32a1.07 1.07 0 0 1 .787-.32c.31 0 .573.107.786.32l2.587 2.587c.213.213.32.475.32.786 0 .311-.107.574-.32.787ZM2 17.84 14.293 5.547l4.16 4.16L6.16 22H2v-4.16Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconPen.displayName = "pen";
export default IconPen;
