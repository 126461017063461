import { Icon } from "@/components/Icon";
import {
  Content as PrimitiveContent,
  Trigger as PrimitiveTrigger,
} from "@radix-ui/react-tabs";
import IconsEnum from "enums/icons";

import { List, PrimitiveRoot } from "../Tabs";

const Trigger = ({ children, value, icon }) => {
  return (
    <PrimitiveTrigger
      value={value}
      className="group w-full p-6 mb-6 bg-gray-100 hover:bg-white active:bg-white flex justify-between active:shadow-md hover:shadow-md transition-all items-center"
    >
      <div className="flex items-center text-left font-bold">
        {icon && IconsEnum[icon] && (
          <span className="w-[32px] h-[32px] bg-orange-light group-active:bg-blue-light group-hover:bg-blue-light rounded-full text-white shrink-0 flex items-center justify-center mr-4">
            <Icon type={IconsEnum[icon]} className="w-[16px] h-[16px] " />
          </span>
        )}
        {children}
      </div>
      <Icon
        type="chevron"
        purposeLabel="Zu Tab wechseln"
        className="w-[20px] h-[20px] ml-6 shrink-0 rotate-90 group-active:-rotate-90 lg:rotate-0 lg:group-active:rotate-0 text-blue-light transition-all"
      />
    </PrimitiveTrigger>
  );
};

const Content = ({ children, value }) => {
  return (
    <PrimitiveContent
      value={value}
      className="bg-white shadow-md col-span-12 lg:col-span-9 hidden lg:active:block"
    >
      {children}
    </PrimitiveContent>
  );
};

const SideTab = ({ components }) => {
  return (
    <PrimitiveRoot
      defaultValue={components?.[0].id}
      className="grid grid-cols-12 gap-6"
      orientation="vertical"
    >
      <List className="col-span-12 lg:col-span-3" data-orientation="vertical">
        {components &&
          components.map((component) => (
            <>
              <Trigger
                value={component.id}
                key={component.id}
                icon={component.icon}
              >
                {component.title}
              </Trigger>
              <div className="hidden next-to-active:block lg:next-to-active:hidden">
                {component.content}
              </div>
            </>
          ))}
      </List>
      {components &&
        components.map((component) => (
          <Content value={component.id} key={component.id}>
            {component.content}
          </Content>
        ))}
    </PrimitiveRoot>
  );
};

export default Object.assign(SideTab, { Trigger, Content });
