import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";
import Image from "next/image";
import HTML from "../HTML/HTML";

const Contact = ({ content, id = 131 }) => {
  const { image, header, bodytext, name, position, phone, mail } = content;
  let imgProps: any = "";
  if (image) {
    imgProps = Object.entries(image)[0][1];
  }
  return (
    <div className="bg-blue-dark lg:mt-16 mt-9" id={`c${id}`}>
      <div className="container mx-auto py-9 xl:py-16 flex flex-col xl:flex-row gap-7 xl:gap-17">
        {image && (
          <div className={`xl:w-1/3`}>
            <Image
              className={`rounded-full mx-auto xl:mr-0 w-1/2 xl:w-2/3`}
              src={imgProps.publicUrl}
              quality={100}
              width={imgProps.properties.cropDimensions.width}
              height={imgProps.properties.cropDimensions.height}
              alt="image"
            />
          </div>
        )}
        <div className="text-gray-300 xl:max-w-2xl w-full xl:w-1/2">
          <h3 className="text-white text-4xl xl:text-5xl font-bold text-center xl:text-left">
            {header}
          </h3>
          {bodytext && (
            <HTML
              className={`text-3xl text-center xl:text-left mt-4 last:[&>p]:text-base last:[&>p]:mt-7`}
              content={bodytext}
            />
          )}
          <p className="text-3xl text-white font-bold mt-7">{name}</p>
          <p className="text-base text-white">{position}</p>
          <a
            className="text-blue-light items-center flex mt-5 hover:text-[#00a1ba]"
            href={`tel:${phone}`}
          >
            <Icon type="phone" className="w-5 h-5 mr-4" />
            {phone}
          </a>
          <a
            className="text-blue-light items-center flex mt-3 hover:text-[#00a1ba]"
            href={`mailto:${mail}`}
          >
            <Icon type="mail" className="w-5 h-5 mr-4" />
            {mail}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Object.assign(Contact, {});
