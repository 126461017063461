import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCheckmarkComplex: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="#currentColor"
          fillRule="evenodd"
          d="M6.09 20.22h7.172a5.015 5.015 0 0 0 4.698-3.221l3.3-8.612a3.599 3.599 0 0 0-1.677-4.474 3.581 3.581 0 0 0-1.672-.413h-7.174A5.015 5.015 0 0 0 6.04 6.722l-3.3 8.611a3.6 3.6 0 0 0 1.677 4.474 3.58 3.58 0 0 0 1.672.413ZM14.48 8.8a.951.951 0 0 1 1.355 0 .971.971 0 0 1 .002 1.36l-4.055 4.282a.951.951 0 0 1-1.355 0l-2.154-2.18a.971.971 0 0 1 0-1.363.951.951 0 0 1 1.355 0l1.473 1.49L14.48 8.8Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCheckmarkComplex.displayName = "checkmark-complex";

export default IconCheckmarkComplex;
