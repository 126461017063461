<svg
  style={{
    height: "100%",
    width: "100%",
    padding: 10,
    boxSizing: "border-box",
  }}
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  stroke="#4C68C1"
  strokeWidth={2}
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7" />
</svg>;

import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconReset: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 22 22"
        className={className}
      >
        <path
          fill="#008CBA"
          d="M11 .9A10.1 10.1 0 0 1 21.1 11a1.1 1.1 0 0 1-2.2 0A7.9 7.9 0 0 0 5.459 5.4h2.16a1.1 1.1 0 1 1 0 2.2h-4.5a1.1 1.1 0 0 1-1.1-1.1V2a1.1 1.1 0 1 1 2.2 0v1.54A10.1 10.1 0 0 1 11 .9Zm-9.778 9.323A1.1 1.1 0 0 1 3.1 11a7.9 7.9 0 0 0 13.44 5.61h-2.16a1.1 1.1 0 0 1 0-2.2h4.533a1.1 1.1 0 0 1 1.067 1.09V20a1.1 1.1 0 0 1-2.2 0v-1.54A10.1 10.1 0 0 1 .9 11a1.1 1.1 0 0 1 .322-.777Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconReset.displayName = "reset";
export default IconReset;
