import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconRating: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 21"
      >
        <path
          fill="currentColor"
          d="m7.157 7.391 2.165-4.385a.758.758 0 0 1 1.359 0l2.164 4.385 4.84.707c.622.091.87.855.42 1.293l-3.502 3.41.826 4.82a.758.758 0 0 1-1.099.798l-4.329-2.276-4.328 2.276a.757.757 0 0 1-1.1-.798L5.4 12.8l-3.502-3.41a.758.758 0 0 1 .419-1.293l4.84-.707Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconRating.displayName = "rating";
export default IconRating;
