import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconHeartFill: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m12 21.365-1.442-1.322c-2.18-1.97-3.686-3.381-4.52-4.23-1.362-1.379-2.347-2.605-2.956-3.678C2.36 10.917 2 9.699 2 8.48c0-1.01.244-1.935.733-2.777a5.352 5.352 0 0 1 1.983-1.983C5.55 3.241 6.48 3 7.505 3c.85 0 1.674.192 2.476.577A6.044 6.044 0 0 1 12 5.115a6.044 6.044 0 0 1 2.02-1.538A5.711 5.711 0 0 1 16.52 3c1.009 0 1.934.24 2.776.721a5.237 5.237 0 0 1 1.983 1.983c.48.842.721 1.767.721 2.777 0 1.218-.353 2.444-1.058 3.678-.625 1.073-1.618 2.3-2.98 3.677-.85.882-2.348 2.3-4.496 4.255L12 21.365Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconHeartFill.displayName = "heart-fill";
export default IconHeartFill;
