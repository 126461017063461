import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconInfo: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.971 3.942a7.814 7.814 0 0 0-4.038 1.106 7.785 7.785 0 0 0-2.885 2.885 7.814 7.814 0 0 0-1.106 4.038c0 1.443.369 2.789 1.106 4.039a7.784 7.784 0 0 0 2.885 2.884A7.814 7.814 0 0 0 11.97 20a7.814 7.814 0 0 0 4.039-1.106 7.784 7.784 0 0 0 2.884-2.884A7.814 7.814 0 0 0 20 11.97a7.814 7.814 0 0 0-1.106-4.038 7.784 7.784 0 0 0-2.884-2.885 7.814 7.814 0 0 0-4.039-1.106ZM12 22a9.834 9.834 0 0 1-5.072-1.37 9.478 9.478 0 0 1-3.558-3.558A9.835 9.835 0 0 1 2 12c0-1.827.457-3.518 1.37-5.072A9.478 9.478 0 0 1 6.928 3.37 9.835 9.835 0 0 1 12 2c1.827 0 3.518.457 5.072 1.37a9.657 9.657 0 0 1 3.558 3.582A9.79 9.79 0 0 1 22 12a9.79 9.79 0 0 1-1.37 5.048 9.842 9.842 0 0 1-3.582 3.582A9.79 9.79 0 0 1 12 22Zm0-5.5a1 1 0 0 0 1-1v-4a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1Zm0-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconInfo.displayName = "info";
export default IconInfo;
