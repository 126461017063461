import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPercentage: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          d="M9.697 13.447a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm0-5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm10.605 8.106a3.75 3.75 0 1 0 0 7.498 3.75 3.75 0 0 0 0-7.498Zm0 5a1.25 1.25 0 1 1 0-2.499 1.25 1.25 0 0 1 0 2.499Zm4.331-16.187a1.25 1.25 0 0 0-1.767 0l-17.5 17.5a1.25 1.25 0 1 0 1.767 1.768l17.5-17.5a1.252 1.252 0 0 0 0-1.768Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconPercentage.displayName = "percentage";

export default IconPercentage;
