import NextLink from "next/link";

interface UrlObject {
  href: string;
}

const cleanUrl = (url: string | UrlObject): string => {
  if (typeof url === "string" && url.startsWith("t3://")) {
    return "#";
  }
  if (typeof url !== "string" && url.href) {
    return cleanUrl(url.href);
  }

  return url as string;
};

const Link = ({ href, ...props }) => (
  <NextLink href={cleanUrl(href)} {...props} />
);

export default Link;
