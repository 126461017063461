import { NextPageWithLayout } from "./_app";
import { createContext, PropsWithChildren, useRef } from "react";
import Layout from "@/components/Layout/Layout";
import { getPage } from "@/data/page";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Navigation from "@/modules/Navigation/Navigation.component";
import NavigationCareer from "@/modules/NavigationCareer/NavigationCareer";
import { getNavigation } from "@/data/navigation";
import MappedComponent from "@/modules/ComponentMap/ComponentMap";
import Footer, { FooterMeta } from "@/modules/Footer/Footer.component";
import Head from "next/head";
import { fetchStaticPaths } from "@/data/sitemap";
import MetaAttributes from "@/modules/MetaAttributes/MetaAttributes";
import Typo3PreviewBar from "@/modules/Typo3PreviewBar/Typo3PreviewBar";
import { getConfig } from "@/data/config";
import { Slot } from "utils/create-slots";
import { Config, PageProps } from "@/types/types";
import RichSnippets from "@/modules/MetaAttributes/RichSnippets";
import { useRouter } from "next/router";
import useSalesforce from "hooks/useSalesforce";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";

export const PageContext = createContext<{ [key: string]: any }>({});

const Page: NextPageWithLayout<PropsWithChildren<PageProps>> = ({
  pageProps,
  navigation,
  footer,
  footerMeta,
  config,
  preview = false,
  editUrl,
}) => {
  const router = useRouter();
  const { pushEvent } = useSalesforce();
  const eventSent = useRef(false);

  useIsomorphicLayoutEffect(() => {
    const handleRouteChange = () => {};
    if (eventSent.current) {
      router.events.on("routeChangeComplete", handleRouteChange);
      router.events.on("hashChangeComplete", handleRouteChange);
    }
    eventSent.current = true;
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (!pageProps) return null;

  const {
    slug = "",
    breadcrumbs = [],
    content = {},
    meta = {},
    appearance = {},
  } = pageProps;

  const isCareer = (slug && slug.includes("karriere")) || false;
  return (
    <>
      <Head>
        <title>{meta.title}</title>
      </Head>
      <MetaAttributes meta={meta} slug={slug} />
      <RichSnippets config={config} meta={meta} />
      <PageContext.Provider value={{ config, breadcrumbs, meta, appearance }}>
        <Layout
          type={appearance.layout}
          transparentheader={appearance.transparentheader}
        >
          <Slot name="Navigation">
            {!isCareer ? (
              <Navigation
                items={navigation}
                appearance={appearance}
                key={`nav-${appearance.transparentheader}`}
              />
            ) : (
              <NavigationCareer
                items={JSON.parse(config.navigationCareer).navigation}
                appearance={appearance}
                key={`nav-${appearance.transparentheader}`}
              />
            )}
          </Slot>
          <Slot name="Breadcrumbs">
            <Breadcrumbs items={breadcrumbs}>
              {breadcrumbs.map(({ link, title }) => (
                <Breadcrumbs.Item href={link} key="link">
                  {title}
                </Breadcrumbs.Item>
              ))}
            </Breadcrumbs>
          </Slot>
          {Object.entries(content).map(([key, value]: any[]) => (
            <Slot name={key} key={key}>
              {value.map((props, index) => (
                <MappedComponent {...props} key={index} />
              ))}
            </Slot>
          ))}
          <Slot name="Footer">
            <Footer items={footer} />
          </Slot>
          <Slot name="FooterMeta">
            <FooterMeta items={footerMeta} />
          </Slot>
        </Layout>
        {preview && <Typo3PreviewBar editUrl={editUrl} />}
      </PageContext.Provider>
    </>
  );
};

export async function getStaticProps({ params, preview = false, previewData }) {
  const pageProps: any = await getPage(params.slug, preview, previewData);
  const { navigation, footer, footerMeta } = await getNavigation();
  const config: Config = await getConfig();
  if (
    !pageProps ||
    pageProps.status === 404 ||
    !navigation ||
    navigation.status === 404 ||
    !config ||
    config.status === 404
  )
    return {
      notFound: true,
    };

  if (pageProps.status >= 300 && pageProps.status <= 309) {
    return {
      redirect: {
        destination: `/${pageProps.url}`,
        permanent: true,
      },
    };
  }

  return {
    props: {
      pageProps,
      navigation,
      footer,
      footerMeta,
      config,
      preview,
      ...(preview
        ? {
            editUrl: `${process.env.NEXT_PUBLIC_API_URL}typo3/module/web/layout?id=${pageProps.id}`,
          }
        : {}),
    },
  };
}

export async function getStaticPaths() {
  const paths = await fetchStaticPaths(["pages", "bracar", "bracarlist"]);
  return {
    paths,
    fallback: "blocking", // false or 'blocking'
  };
}

export default Page;
