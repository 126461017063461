import { cva } from "class-variance-authority";

const ActionbarStyles = cva("w-full  xl:mt-0 -mt-5 ", {
  variants: {
    color: {
      black: "bg-black text-white",
      orange: "bg-orange-light red",
      blue: "bg-blue-medium text-white",
      blueLight: "bg-blue-light text-white",
      discount: "bg-[#FFED00] text-[#e30613]",
      default: "bg-black text-white",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const NavigationActionBar = ({ color }) => {
  return (
    <div
      className={`${ActionbarStyles({
        color,
      })} h-8 min-h-8 xl:mb-0 mb-5 opacity-100`}
    >
      <div className="container mx-auto flex justify-center items-center relative h-full">
        <p className=" text-sm font-bold select-none ">
          deutschlandweit kostenlose Lieferung
        </p>
      </div>
    </div>
  );
};

export default NavigationActionBar;
