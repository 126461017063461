import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconUsers: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 46 45"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M23.813 22.913A9.225 9.225 0 0 0 27 15.937a9.375 9.375 0 1 0-18.75 0 9.225 9.225 0 0 0 3.188 6.976 15 15 0 0 0-8.813 13.65 1.875 1.875 0 1 0 3.75 0 11.25 11.25 0 1 1 22.5 0 1.875 1.875 0 1 0 3.75 0 15 15 0 0 0-8.813-13.65Zm-6.188-1.35a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25Zm18.263.6a9.375 9.375 0 0 0-7.013-15.6 1.875 1.875 0 1 0 0 3.75 5.625 5.625 0 0 1 5.625 5.624 5.625 5.625 0 0 1-2.813 4.857 1.874 1.874 0 0 0-.093 3.187l.731.488.244.131a13.125 13.125 0 0 1 7.5 11.962 1.875 1.875 0 0 0 3.75 0 16.875 16.875 0 0 0-7.931-14.4Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconUsers.displayName = "users";

export default IconUsers;
