import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const ComfortIcon: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 24"
        className={className}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5.60724 3.30376c0 1.272-1.031 2.304-2.304 2.304-1.272 0-2.303-1.032-2.303-2.304 0-1.272 1.031-2.304004 2.303-2.304004 1.273 0 2.304 1.032004 2.304 2.304004Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7 21.5c0 .828-.67201 1.5-1.5 1.5-.82916 0-1.5-.672-1.5-1.5S4.67084 20 5.5 20c.82799 0 1.5.672 1.5 1.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m3.92565 17.8859-1.526-7.95204s-.284-2.023 1.81-2.343c0 0 2.201-.497 2.733 1.917.44 1.99304 1.456 7.27804 1.456 7.27804s.193.852 1.4.923c1.20695.071 6.72895-.036 6.72895-.036s1.919.033 2.059 2.272c.142 2.272-2.059 2.272-2.059 2.272H9.79865M3.79717 5.66357l.412 1.928"
        />
      </svg>
    </ConditionalWrapper>
  );
};

ComfortIcon.displayName = "comfort";
export default ComfortIcon;
