import Image from "next/image";
import React, { Fragment, useState } from "react";
import { Icon } from "../Icon";

const Video = ({ content }) => {
  const video = content.video?.[0];
  const preview = content.preview?.[0];
  const mimeType = video?.properties.mimeType;
  const [posterVisible, setPosterVisible] = useState(!!preview);

  const handleClick = () => {
    setPosterVisible(false);
  };

  if (!video || !mimeType) return null;

  switch (mimeType) {
    case "video/youtube":
      return (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={addVideoJsonLd(video)}
          />

          <VideoWrapper>
            {posterVisible && preview?.publicUrl && (
              <Fragment>
                <VideoPlayButton />
                <VideoPoster
                  previewUrl={preview?.publicUrl}
                  onClick={handleClick}
                />
              </Fragment>
            )}

            {!posterVisible && video?.publicUrl && (
              <iframe
                className="w-full h-full"
                src={`${video.publicUrl}&autoplay=1`}
                frameBorder="0"
                allow={!!preview ? "autoplay; encrypted-media" : ""}
                allowFullScreen
              />
            )}
          </VideoWrapper>
        </>
      );

    default:
      return (
        <VideoWrapper>
          {posterVisible && (
            <Fragment>
              <VideoPoster
                previewUrl={preview.publicUrl}
                onClick={handleClick}
              />
              <VideoPlayButton />
            </Fragment>
          )}
          {!posterVisible && (
            <video
              className="w-full h-full"
              src={video.publicUrl}
              autoPlay
              controls
            >
              <source src={video.publicUrl} />
            </video>
          )}
        </VideoWrapper>
      );
  }
};

const addVideoJsonLd = (video) => {
  return {
    __html: `${JSON.stringify(video.properties.schemaJson)}`,
  };
};

const VideoWrapper = ({ children }) => {
  return (
    <div className="relative xl:h-screen lg:h-[576px] md:h-[470px] h-[220px] w-full group cursor-pointer">
      {children}
    </div>
  );
};

const VideoPlayButton = () => {
  return (
    <Icon
      type="playFull"
      className="lg:w-14 w-12 lg:h-14 1-12 z-10 text-orange-light group-hover:text-gray-300 top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 absolute pointer-events-none"
      purposeLabel="Video Dialog öffen"
    />
  );
};
const VideoPoster = ({ previewUrl, onClick }) => {
  return (
    <Image
      src={previewUrl}
      alt="Video Vorschau"
      fill
      onClick={onClick}
      className="absolute inset-0 object-cover"
    />
  );
};

export default Object.assign(Video, {
  VideoWrapper,
  VideoPlayButton,
  VideoPoster,
});
