import { useState, useEffect, useMemo } from "react";

export function useElementInViewport(options, targetRef, delay) {
  let timeout;
  clearTimeout(timeout);
  const [isVisibile, setIsVisible] = useState();
  const callbackFunction = (entries) => {
    const [entry] = entries; //const entry = entries[0]

    if (entry.isIntersecting) {
      timeout = setTimeout(function () {
        setIsVisible(entry.isIntersecting);
      }, delay);
    } else {
      clearTimeout(timeout);
      setIsVisible(entry.isIntersecting);
    }
  };
  const optionsMemo = useMemo(() => {
    return options;
  }, [options]);
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, optionsMemo);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef, optionsMemo]);

  return isVisibile;
}
