import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconLabel: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M17.398 5.885 22 12.358l-4.602 6.473c-.185.27-.434.484-.746.644-.312.16-.636.24-.973.24H4.124c-.573 0-1.07-.202-1.492-.606A1.959 1.959 0 0 1 2 17.642V7.073c0-.573.21-1.062.632-1.466A2.083 2.083 0 0 1 4.124 5h11.555c.337 0 .661.08.973.24.312.16.56.375.746.645Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconLabel.displayName = "label";
export default IconLabel;
