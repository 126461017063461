import Link from "@/components/Link/Link";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import s from "./Navigation.module.css";
import * as gtag from "@/lib/gtag";
import { Slot } from "utils/create-slots";
import { Icon } from "@/components/Icon";
import WishlistButton from "../Wishlist/WishlistButton";
import { useCallback, useContext, useState } from "react";
import { useRouter } from "next/router";
import { RemoveScroll } from "react-remove-scroll";
import { cx } from "class-variance-authority";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";
import SearchButton from "../SearchButton/SearchButton";
import NavigationActionBar from "./NavigationActionBar";
import { PageContext } from "pages/[[...slug]]";

const Navigation = ({ items, appearance }: any) => {
  const [scrollY, setScrollY] = useState(false);
  const { config } = useContext(PageContext);
  const { search = {} } = config;

  const { transparentheader } = appearance;

  const getBgColorClass = (boolean?: 0 | 1) => {
    switch (boolean) {
      case 0:
        return "bg-white shadow-lg";
      case 1:
        return "bg-transparent";
      default:
        return "bg-white";
    }
  };

  const scrollHandler = useCallback(() => {
    setScrollY(window.pageYOffset <= 20);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const addScrollHandler = () => {
      if (transparentheader === 1) {
        window.addEventListener("scroll", scrollHandler, { passive: true });
      }
    };

    addScrollHandler();
    scrollHandler();

    return () => {
      window.removeEventListener("scroll", scrollHandler), { passive: true };
    };
  }, []);

  if (!items) return null;
  const [{ children = [] }] = items;

  return (
    <Slot name="Navigation">
      <header
        className={`${
          s.NavigationContainer
        }  flex-col pt-5 xl:pt-0 transition-colors duration-700  ${
          scrollY ? getBgColorClass(transparentheader) : "!bg-white shadow-lg"
        }
      `}
      >
        <NavigationActionBar color={"discount"} />

        <div
          className={`container flex-wrap xl:flex-nowrap flex items-center justify-between  ${cx(
            RemoveScroll.classNames.fullWidth
          )}`}
        >
          <Link
            href="/"
            title="Zur Startseite"
            onClick={() => {
              gtag.event({
                event: "itemClick",
                itemClick: "navigation",
                firstLevel: "/",
                secondLevel: "Startseite",
                position: "above",
              });
            }}
          >
            <Icon
              type="logo"
              className={`transition-all duration-700 text-blue-dark w-[170px] mr-7`}
            />
          </Link>
          <NavigationMenu.Root
            className={`${s.NavigationRoot}  w-full  no-scrollbar overflow-x-auto xl:overflow-visible`}
          >
            <NavigationMenu.List className="  text-sm xl:flex-grow flex items-center xl:items-stretch justify-start md:justify-between mt-3 xl:mt-0 xl:justify-center lg:gap-3 xl:gap-6 hd:gap-7">
              {children.map(({ title, link, target }) => (
                <NavigationLink
                  href="/[[...slug]]"
                  as={link}
                  key={`${title}-${link}`}
                  transparentheader={transparentheader}
                >
                  {title}
                </NavigationLink>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
          <div className="flex items-center justify-center align-baseline gap-7">
            <WishlistButton />
            <SearchButton searchConfig={search} />
          </div>
        </div>
      </header>
    </Slot>
  );
};
const NavigationLink = ({ href, as, transparentheader, ...props }) => {
  const router = useRouter();
  const isActive = router.asPath.split("/").join("") === as.split("/").join("");
  return (
    <NavigationMenu.Item
      data-state={isActive ? "active" : "inactive"}
      className={`transition-all duration-700 block font-bold whitespace-nowrap xl:inline-block xl:mt-0  text-blue-dark pt-4 pb-4 xl:py-6 border-b-4  border-transparent  hover:border-b-orange-light active:border-b-orange-light hover:text-blue-light focus-within:border-b-orange-light focus-within:text-blue-light mr-6 md:mr-0`}
      onClick={() => {
        gtag.event({
          event: "itemClick",
          itemClick: "navigation",
          firstLevel: `${props.children}`,
          position: "above",
        });
      }}
    >
      <Link href={href} passHref as={as} legacyBehavior>
        <NavigationMenu.Link active={isActive} {...props} />
      </Link>
    </NavigationMenu.Item>
  );
};

Navigation.displayName = "Navigation";

export default Navigation;
