import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconBell: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        viewBox="0 0 31 31"
      >
        <path
          fill="currentColor"
          d="m26.39 23.182-.943-1.488c-.41-.646-.834-1.305-1.245-1.952a.52.52 0 0 1-.073-.231V15.217c0-.585-.036-1.061-.097-1.512-.278-2.025-1.184-3.782-2.672-5.209a8.312 8.312 0 0 0-2.382-1.61l-.036-.073c0-.293 0-.573-.048-.83a3.466 3.466 0 0 0-1.475-2.353 3.487 3.487 0 0 0-2.733-.55c-1.657.391-2.77 1.794-2.757 3.49 0 .255-.048.329-.278.438-1.475.708-2.684 1.806-3.591 3.281-.87 1.415-1.318 3.038-1.33 4.818v4.404c0 .073-.024.17-.073.231-.302.488-.616.964-.919 1.452-.41.646-.834 1.305-1.245 1.964-.157.256-.254.56-.242.805.024.646.508 1.073 1.245 1.073H11.337c.06 0 .084 0 .072.037.387 1.183 1.16 2.049 2.285 2.549.23.098.847.378 1.741.378.194 0 .387 0 .605-.037 1.68-.28 2.83-1.244 3.41-2.915h5.865c.168 0 .483-.024.749-.17.266-.147.447-.379.52-.66.084-.328.012-.682-.194-1.012v-.024Zm-9.48 1.854c-.314.378-.882.646-1.462.659-.58-.025-1.137-.244-1.5-.659h2.963ZM14.25 6.179c.134-.5.593-.878 1.161-.903h.049c.532 0 .967.366 1.136.903a9.53 9.53 0 0 0-2.357 0h.012Zm-5.26 8.94c0-1.195.23-2.22.702-3.122 1.052-2.037 2.684-3.232 4.837-3.537 3.131-.44 6.094 1.451 7.037 4.537.193.647.29 1.33.302 2.11v4.66c0 .354.097.67.29.976.4.61.786 1.232 1.197 1.866l.06.097H7.444l.206-.317c.35-.56.701-1.11 1.064-1.659.193-.292.278-.597.278-.951v-4.672.013Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconBell.displayName = "bell";
export default IconBell;
