import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconGrowth: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M27.4 8.275a1.25 1.25 0 0 0-.676-.675 1.25 1.25 0 0 0-.475-.1H20a1.25 1.25 0 0 0 0 2.5h3.238l-6.988 6.988-4.112-4.125a1.248 1.248 0 0 0-1.775 0l-7.5 7.5a1.25 1.25 0 0 0 0 1.774 1.248 1.248 0 0 0 1.775 0l6.612-6.625 4.113 4.125a1.249 1.249 0 0 0 1.775 0L25 11.762V15a1.25 1.25 0 1 0 2.5 0V8.75a1.248 1.248 0 0 0-.1-.475Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconGrowth.displayName = "growth";

export default IconGrowth;
