import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconList: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <title>list</title>
        <path
          className="cls-1"
          d="M1.5,17.51H1.42a1.47,1.47,0,0,0,0,2.93H1.5a1.47,1.47,0,0,0,0-2.93Z"
        />
        <path
          className="cls-1"
          d="M1.5,3.46H1.42a1.47,1.47,0,0,0,0,2.93H1.5a1.47,1.47,0,0,0,0-2.93Z"
        />
        <path
          className="cls-1"
          d="M1.5,10.49H1.42a1.46,1.46,0,0,0,0,2.92H1.5a1.46,1.46,0,0,0,0-2.92Z"
        />
        <path
          className="cls-1"
          d="M22.54,17.51h-17a1.47,1.47,0,1,0,0,2.93h17a1.47,1.47,0,0,0,0-2.93Z"
        />
        <path
          className="cls-1"
          d="M5.54,6.39h17a1.47,1.47,0,0,0,0-2.93h-17a1.47,1.47,0,1,0,0,2.93Z"
        />
        <path
          className="cls-1"
          d="M22.54,10.49h-17a1.46,1.46,0,1,0,0,2.92h17a1.46,1.46,0,1,0,0-2.92Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconList.displayName = "list";
export default IconList;
