import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const CloseIcon: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M14.18,12l9.37-9.37A1.54,1.54,0,0,0,21.37.45L12,9.82,2.63.45A1.52,1.52,0,0,0,1.54,0,1.54,1.54,0,0,0,.45,2.63L9.82,12,.45,21.37A1.54,1.54,0,0,0,1.54,24a1.52,1.52,0,0,0,1.09-.45L12,14.18l9.37,9.37a1.54,1.54,0,0,0,2.18-2.18Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

CloseIcon.displayName = "close";
export default CloseIcon;
