import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSslLock: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.609 8.957h11.304v-1.74a5.217 5.217 0 1 0-10.435 0h-.87A2.609 2.609 0 0 0 3 9.827v9.564A2.609 2.609 0 0 0 5.609 22h12.174a2.609 2.609 0 0 0 2.608-2.609v-4.347a.87.87 0 1 0-1.739 0v4.347c0 .48-.39.87-.87.87H5.61a.87.87 0 0 1-.87-.87V9.826c0-.48.39-.87.87-.87Zm6.087-5.218a3.478 3.478 0 0 1 3.478 3.478H8.217a3.478 3.478 0 0 1 3.479-3.478Zm8.08 3.733-8.95 8.95-1.994-1.993a.87.87 0 1 0-1.23 1.23l2.61 2.608c.339.34.89.34 1.229 0l9.565-9.565a.87.87 0 1 0-1.23-1.23Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconSslLock.displayName = "ssl-lock";
export default IconSslLock;
