import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconMailCD: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 602 602"
      >
        <defs>
          <linearGradient id="a">
            <stop offset="0" stopColor="#70efff" />
            <stop offset="1" stopColor="#5770ff" />
          </linearGradient>
          <linearGradient
            xlinkHref="#a"
            id="b"
            x1="305.201"
            x2="305.785"
            y1="598.592"
            y2="8.244"
            gradientTransform="matrix(.15 0 0 .15 .85 961.212)"
            gradientUnits="userSpaceOnUse"
          />
        </defs>
        <g color="#000">
          <path
            fill="url(#b)"
            d="M21.653 961.362h48.694A20.607 20.607 0 0 1 91 982.015v48.695a20.607 20.607 0 0 1-20.653 20.652H21.653A20.607 20.607 0 0 1 1 1030.71v-48.695a20.607 20.607 0 0 1 20.653-20.653z"
            overflow="visible"
            transform="translate(-5.667 -6408.081) scale(6.6667)"
          />
          <path
            fill="#fff"
            d="M20.719 536.594c-.474 0-.921.082-1.344.25l8.469 8.718 8.562 8.875.157.188.25.25.25.25.5.531 7.343 7.532c.123.076.477.404.754.542.356.178.743.343 1.141.357.43.015.87-.108 1.256-.296.29-.141.419-.343.755-.604l8.5-8.78 8.594-8.845 8.281-8.53a3.634 3.634 0 0 0-1.75-.438zm-2.594 1.062c-.903.856-1.469 2.142-1.469 3.594v28.625c0 1.175.378 2.243 1 3.063l1.188-1.125 8.843-8.594 7.844-7.594-.156-.188-8.594-8.843-8.593-8.875zm57.188.282-8.376 8.656-8.562 8.843-.156.157 8.156 7.906 8.844 8.594.531.5c.476-.764.75-1.705.75-2.719V541.25c0-1.294-.449-2.468-1.188-3.313zM36.53 556.655l-7.812 7.594-8.875 8.594-1.125 1.093c.593.382 1.268.625 2 .625h51.718c.88 0 1.679-.338 2.344-.875l-.562-.562-8.875-8.594-8.156-7.875-7.344 7.563c-.398.263-.663.556-1.051.735-.625.289-1.31.533-1.998.523-.69-.011-1.366-.281-1.985-.586-.311-.152-.477-.304-.841-.61z"
            overflow="visible"
            transform="translate(-5.667 -3405.667) scale(6.6667)"
          />
        </g>
      </svg>
    </ConditionalWrapper>
  );
};
IconMailCD.displayName = "mailCD";
export default IconMailCD;
