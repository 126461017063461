import { FC, PropsWithChildren } from "react";
import Headlines from "../../modules/Headlines/Headlines";
import { Icon } from "../Icon";

enum BulletsType {
  OL = 0,
  UL = 1,
  DL = 2,
  icon = 3,
}

const BulletWrapper: FC<
  PropsWithChildren<{
    type: 0 | 1 | 2 | 3;
    icon?: string;
    className?: string;
    first?: boolean;
  }>
> = ({ children, type, icon = undefined, className = "", first = false }) => {
  switch (type) {
    case BulletsType.DL:
      if (first)
        return (
          <dt className={className}>
            {icon && <Icon type={icon} className="w-7 inline -ml-[1em] mr-4" />}
            {children}
          </dt>
        );
      return <dd className={className}>{children}</dd>;
    default:
      return (
        <li className={className}>
          {icon && <Icon type={icon} className="w-7 inline -ml-[1em] mr-4" />}
          {children}
        </li>
      );
  }
};

const BulletListWrapper = ({ children, type, className = "" }) => {
  switch (type) {
    case BulletsType.DL:
      return <dl className={`${className}`}>{children}</dl>;
    case BulletsType.UL:
      return (
        <ol className={`${className} list-decimal list-outside pl-[1em]`}>
          {children}
        </ol>
      );
    case BulletsType.icon:
      return <ol className={`${className} pl-[1em]`}>{children}</ol>;
    default:
      return (
        <ul className={`${className} list-disc list-outside  pl-[1em]`}>
          {children}
        </ul>
      );
  }
};
const Bullets: FC<{
  type: 0 | 1 | 2 | 3;
  items: any[];
  icon?: string;
  className?: string;
}> = ({ type, items, icon, className = "" }) => {
  return (
    <BulletListWrapper
      type={icon ? BulletsType.icon : type}
      className={className}
    >
      {items?.map((bullet, index) => {
        if (typeof bullet === "string")
          return (
            <BulletWrapper key={index} type={type} icon={icon}>
              {bullet}
            </BulletWrapper>
          );
        return bullet.map((subbullet, index) => (
          <BulletWrapper
            key={index}
            type={type}
            first={index === 0}
            icon={icon}
          >
            {subbullet}
          </BulletWrapper>
        ));
      })}
    </BulletListWrapper>
  );
};

Bullets.displayName = "Text";

export const Typo3Bullets = ({ content }) => {
  const { bodytext = [], bulletsType = 0 } = content;

  const icon = "attention";

  return (
    <div className=" w-full container mx-auto">
      <Headlines {...content} />
      <Bullets items={bodytext} type={bulletsType} icon={icon} />
    </div>
  );
};

export default Bullets;
