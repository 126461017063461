import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconAddUser: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 46 45"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M39.9584 19.6875h-1.875v-1.875c0-.4973-.1976-.9742-.5492-1.3258-.3516-.3517-.8285-.5492-1.3258-.5492-.4973 0-.9742.1975-1.3259.5492-.3516.3516-.5491.8285-.5491 1.3258v1.875h-1.875c-.4973 0-.9742.1975-1.3259.5492-.3516.3516-.5491.8285-.5491 1.3258 0 .4973.1975.9742.5491 1.3258.3517.3517.8286.5492 1.3259.5492h1.875v1.875c0 .4973.1975.9742.5491 1.3258.3517.3517.8286.5492 1.3259.5492.4973 0 .9742-.1975 1.3258-.5492.3516-.3516.5492-.8285.5492-1.3258v-1.875h1.875c.4973 0 .9742-.1975 1.3258-.5492.3516-.3516.5492-.8285.5492-1.3258 0-.4973-.1976-.9742-.5492-1.3258-.3516-.3517-.8285-.5492-1.3258-.5492Zm-14.4375 3.225c1.0004-.866 1.8029-1.9371 2.3529-3.1406.5499-1.2035.8346-2.5112.8346-3.8344 0-2.4864-.9877-4.871-2.7459-6.62913-1.7582-1.75815-4.1427-2.74587-6.6291-2.74587-2.4864 0-4.871.98772-6.6292 2.74587-1.7581 1.75813-2.74583 4.14273-2.74583 6.62913-.00001 1.3232.28463 2.6309.83463 3.8344s1.3524 2.2746 2.3529 3.1406c-2.6248 1.1885-4.85165 3.1079-6.41444 5.5285-1.56278 2.4207-2.39533 5.2402-2.39809 8.1215 0 .4973.19755.9742.54918 1.3258.35163.3517.82854.5492 1.32582.5492.49728 0 .9742-.1975 1.32583-.5492.35163-.3516.54917-.8285.54917-1.3258 0-2.9837 1.18527-5.8452 3.29503-7.955 2.1098-2.1097 4.9713-3.295 7.955-3.295s5.8451 1.1853 7.9549 3.295c2.1098 2.1098 3.2951 4.9713 3.2951 7.955 0 .4973.1975.9742.5491 1.3258.3517.3517.8286.5492 1.3259.5492.4973 0 .9742-.1975 1.3258-.5492.3516-.3516.5492-.8285.5492-1.3258-.0028-2.8813-.8353-5.7008-2.3981-8.1215-1.5628-2.4206-3.7897-4.34-6.4144-5.5285Zm-6.1875-1.35c-1.1125 0-2.2001-.3299-3.1251-.948-.925-.6181-1.646-1.4966-2.0717-2.5244-.4258-1.0278-.5372-2.1588-.3201-3.25.217-1.0911.7527-2.0934 1.5394-2.8801.7867-.7866 1.7889-1.3224 2.8801-1.5394 1.0911-.2171 2.2221-.1057 3.25.3201 1.0278.4257 1.9063 1.1467 2.5244 2.0717.6181.925.948 2.0126.948 3.1251 0 1.4918-.5927 2.9226-1.6475 3.9775-1.0549 1.0549-2.4857 1.6475-3.9775 1.6475Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconAddUser.displayName = "add-user";

export default IconAddUser;
