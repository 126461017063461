import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconSearch: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className={className}
      >
        <path d="M23.57,21.5l-5.12-5.12a10.24,10.24,0,1,0-2.07,2.07l5.12,5.12a1.46,1.46,0,0,0,2.07-2.07ZM15.5,15.34l-.09.07-.07.09a7.3,7.3,0,1,1,.16-.16Z" />
      </svg>
    </ConditionalWrapper>
  );
};

IconSearch.displayName = "search";
export default IconSearch;
