import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";

const SearchButton = ({ searchConfig }) => {
  const { link = "/detailsuche", name = "Suche" } = searchConfig;
  return (
    <Link
      href={link}
      className="px-5 pt-2 pb-1  rounded-[17px] border-blue-light border-2 flex  group text-blue-light hover:bg-blue-light hover:text-white transition-all duration-300 ease-in-out  "
    >
      <Icon type={"search"} className="w-[14px] h-[14px] !mt-[2px] mr-3 " />
      <p className="text-[12px] leading-[20px] font-normal ">{name}</p>
    </Link>
  );
};

export default SearchButton;
