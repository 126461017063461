import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconGoogle: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="#4285F4"
          d="M23.75 12.27c0-.79-.07-1.54-.2-2.27h-11.3v4.51h6.48a5.57 5.57 0 0 1-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82Z"
        />
        <path
          fill="#34A853"
          d="M12.26 24c3.24 0 5.95-1.08 7.93-2.91l-3.86-3a7.24 7.24 0 0 1-10.8-3.8H1.55v3.09A11.98 11.98 0 0 0 12.25 24Z"
        />
        <path
          fill="#FBBC05"
          d="M5.53 14.29a6.96 6.96 0 0 1 0-4.58V6.62H1.55a11.86 11.86 0 0 0 0 10.76l3.98-3.09Z"
        />
        <path
          fill="#EA4335"
          d="M12.26 4.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42A11.47 11.47 0 0 0 12.26 0C7.56 0 3.52 2.7 1.55 6.62l3.98 3.09a7.17 7.17 0 0 1 6.73-4.96Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconGoogle.displayName = "google";
export default IconGoogle;
