import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconChevron: FC<Icon> = ({ className, purposeLabel }) => (
  <ConditionalWrapper
    condition={purposeLabel}
    wrapper={(children) => (
      <AccessibleIcon.Root label={purposeLabel!}>
        {children}
      </AccessibleIcon.Root>
    )}
  >
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M18.76,12h0a1.59,1.59,0,0,1-.47,1.13L7.89,23.53a1.59,1.59,0,0,1-2.26,0,1.61,1.61,0,0,1,0-2.26L14.9,12,5.63,2.73a1.61,1.61,0,0,1,0-2.26,1.59,1.59,0,0,1,2.26,0l10.4,10.4A1.61,1.61,0,0,1,18.76,12Z"
      />
    </svg>
  </ConditionalWrapper>
);
IconChevron.displayName = "chevron";
export default IconChevron;
