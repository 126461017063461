import {
  Root,
  Trigger,
  Portal,
  Content,
  Arrow,
} from "@radix-ui/react-hover-card";
import { FC, PropsWithChildren } from "react";
import { createSlots } from "utils/create-slots";
import { Icon } from "../Icon";

const HoverCard: FC<
  PropsWithChildren<{
    className?: string;
    open?: boolean;
    openChange?: () => void;
    openDelay?: number;
    closeDelay?: number;
  }>
> = ({ children, className = "", ...props }) => {
  const [trigger, content] = createSlots(["trigger", "content"], children);
  return (
    <Root {...props}>
      <Trigger>
        <span className="relative ">
          {trigger ? (
            trigger
          ) : (
            <Icon
              type="attention"
              purposeLabel="Öffne Tooltip"
              className="mx-3 w-[17px] text-blue-light inline-block"
            />
          )}
        </span>
      </Trigger>
      <Portal>
        <Content
          sideOffset={8}
          className={`bg-blue-light text-white rounded-4 w-[340px] p-7 ${className}`}
        >
          <Arrow className={`fill-blue-light`} />
          {content}
        </Content>
      </Portal>
    </Root>
  );
};

export default HoverCard;
