import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconPrintFill: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M18.5 3.017A1.033 1.033 0 0 0 17.46 2H6.54c-.572 0-1.036.453-1.04 1.017V7h13V3.017ZM17.055 13H6.936c-.517 0-.936.434-.936.969v7.06c0 .537.42.971.936.971h10.12c.517 0 .939-.433.944-.97v-7.061c-.005-.537-.427-.969-.945-.969ZM4.817 8h14.366c1.552 0 2.812 1.245 2.817 2.785v4.43c-.005 1.535-1.26 2.78-2.808 2.785h-.159v-3.943A2.074 2.074 0 0 0 17 11.941H7a2.074 2.074 0 0 0-2.025 2.115V18h-.158C3.264 18 2.004 16.754 2 15.214v-4.43C2.004 9.246 3.264 8 4.817 8Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconPrintFill.displayName = "heart-fill";
export default IconPrintFill;
