import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCocktail: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M27.224 4.525A1.25 1.25 0 0 0 26.25 2.5H3.75a1.25 1.25 0 0 0-.975 2.025L13.749 18.25V25H6.562a1.25 1.25 0 0 0 0 2.5h16.875a1.25 1.25 0 1 0 0-2.5h-7.188v-6.75L27.226 4.525ZM6.35 5h17.3l-3 3.75H9.4L6.35 5ZM15 15.813l-3.65-4.563h7.3L15 15.813Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCocktail.displayName = "cocktail";

export default IconCocktail;
