import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconZoom: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 25 25"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M11.515 19.573a8.537 8.537 0 1 1 6.837-3.424l4.27 4.27a1.22 1.22 0 1 1-1.725 1.724l-4.27-4.27a8.498 8.498 0 0 1-5.112 1.7Zm4.38-4.294a1.219 1.219 0 0 0-.137.137 6.098 6.098 0 1 1 .137-.137Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10.307 7.572a1.171 1.171 0 0 1 2.343 0v2.257h2.257a1.171 1.171 0 1 1 0 2.343H12.65v2.257a1.171 1.171 0 1 1-2.343 0v-2.257H8.05a1.171 1.171 0 1 1 0-2.343h2.257V7.572Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconZoom.displayName = "zoom";
export default IconZoom;
