import Image from "../Image/Image";

const Gallery = ({ rows = [] }: any) => {
  return (
    <>
      {Object.values(rows).map((row: any, index: number) => {
        const columns = Object.values(row.columns);

        return (
          <div
            key={`row-${index}`}
            className={`grid grid-${Object.values(columns).length}`}
          >
            {columns.map(({ publicUrl, properties }: any, index: number) => (
              <Image
                key={`gallery-${index}`}
                src={publicUrl}
                alt={properties.alternative}
                width={properties.dimensions.width}
                height={properties.dimensions.height}
                objectFit="cover"
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

export const Typo3Gallery = ({ content }) => {
  const { gallery } = content;

  return <Gallery {...gallery} />;
};

export default Gallery;
