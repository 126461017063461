import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconLeasing: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m3.351 8.555 8.182-6.364a.909.909 0 0 1 1.116 0l8.182 6.364a.91.91 0 0 1 .35.718v10A2.727 2.727 0 0 1 18.455 22H5.727A2.727 2.727 0 0 1 3 19.273v-10c0-.28.13-.546.351-.718Zm1.467 1.162v9.556c0 .502.407.909.91.909h12.726a.91.91 0 0 0 .91-.91V9.718L12.09 4.061 4.818 9.717Zm6.28 4.763h2.434v-.963h-2.434v-.875h2.434v-.964h-2.427c.041-.748.246-1.317.615-1.71.37-.391.898-.587 1.586-.587.46 0 .923.077 1.388.232l.191-1.401A7.274 7.274 0 0 0 13.253 8c-1.153 0-2.072.322-2.755.967-.684.645-1.055 1.549-1.114 2.71H8.2v.965h1.176v.875H8.2v.963h1.176c.036 1.176.39 2.092 1.06 2.748.67.657 1.588.985 2.754.985.584 0 1.149-.066 1.696-.198l-.192-1.395c-.433.15-.9.226-1.401.226-.693 0-1.225-.196-1.596-.588-.372-.392-.571-.985-.599-1.777Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconLeasing.displayName = "leasing";
export default IconLeasing;
