import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCookie: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.665 21.34A9.002 9.002 0 0 1 12 21.5c-4.965-.001-8.999-4.035-9-9 .001-4.965 4.035-8.999 9-9 4.965.001 8.999 4.035 9 9 0 .55-.055 1.104-.16 1.665a1.006 1.006 0 0 1-1.907.228.592.592 0 0 0-.54-.354.588.588 0 0 0-.558.426c-.153.51-.677.812-1.195.69a1.643 1.643 0 0 0-.376-.05 1.665 1.665 0 0 0-1.659 1.658c0 .112.016.234.05.378.121.518-.181 1.04-.689 1.193a.59.59 0 0 0-.426.562c0 .231.138.44.353.537a1.005 1.005 0 0 1-.228 1.907Zm-2.071-1.865a2.582 2.582 0 0 1 .998-2.667v-.045a3.674 3.674 0 0 1 3.716-3.67 2.583 2.583 0 0 1 2.087-1.067c.198 0 .392.024.58.068a6.96 6.96 0 0 0-2.037-4.532A6.96 6.96 0 0 0 12 5.513a6.96 6.96 0 0 0-4.938 2.049A6.96 6.96 0 0 0 5.013 12.5a6.96 6.96 0 0 0 2.049 4.938 6.96 6.96 0 0 0 4.532 2.037Zm3.603-9.107a1.066 1.066 0 1 0 2.132 0 1.066 1.066 0 0 0-2.132 0Zm-5.21-1.42a1.066 1.066 0 1 1 0-2.132 1.066 1.066 0 0 1 0 2.131Zm.829 2.723a1.066 1.066 0 1 0 2.131 0 1.066 1.066 0 0 0-2.131 0Zm-.83 5.803a1.066 1.066 0 1 1 0-2.132 1.066 1.066 0 0 1 0 2.132Zm-2.96-5.803a1.066 1.066 0 1 0 2.132 0 1.066 1.066 0 0 0-2.132 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCookie.displayName = "cookie";
export default IconCookie;
