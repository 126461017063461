import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconEdit: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 34 34"
        className={className}
      >
        <path
          fill="currentColor"
          d="M31.999 17a1.667 1.667 0 0 0-1.667 1.666v10a1.667 1.667 0 0 1-1.667 1.667H5.332a1.667 1.667 0 0 1-1.667-1.667V5.333a1.667 1.667 0 0 1 1.667-1.667h10a1.666 1.666 0 1 0 0-3.333h-10a5 5 0 0 0-5 5v23.333a5 5 0 0 0 5 5h23.333a5 5 0 0 0 5-5v-10A1.667 1.667 0 0 0 32 17Zm-25 1.266v7.067A1.667 1.667 0 0 0 8.665 27h7.067a1.669 1.669 0 0 0 1.183-.484l11.534-11.55 4.733-4.633a1.668 1.668 0 0 0 0-2.367L26.115.816a1.667 1.667 0 0 0-2.366 0l-4.7 4.717-11.567 11.55a1.666 1.666 0 0 0-.483 1.183ZM24.932 4.35l4.717 4.716-2.367 2.367-4.717-4.717 2.367-2.366Zm-14.6 14.6 9.883-9.884 4.717 4.717-9.883 9.883h-4.717V18.95Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconEdit.displayName = "edit";
export default IconEdit;
