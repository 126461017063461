import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconEnergy: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.91 14.726h7.15l-.78 6.25c-.113.9 1.018 1.391 1.6.694l9.088-10.906a.909.909 0 0 0-.699-1.49h-7.15l.782-6.25c.112-.9-1.02-1.391-1.6-.694L3.212 13.236a.909.909 0 0 0 .699 1.49Zm1.94-1.817 5.863-7.034-.525 4.195a.909.909 0 0 0 .902 1.021h6.239l-5.862 7.034.525-4.195a.909.909 0 0 0-.902-1.021H5.85Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconEnergy.displayName = "energy";
export default IconEnergy;
