import { useState } from "react";
import Badge from "@/components/Badge/Badge";
import Tabs from "@/components/Tabs/Tabs";
import s from "./TabStage.module.css";
import { Slot } from "utils/create-slots";
import Slider from "@/components/Slider/Slider";
import CarGrid from "../CarGrid/CarGrid";
import { cva } from "class-variance-authority";
import { textColorVariant } from "@/lib/getTextColorClass";
import ArtDirectionImage from "@/components/Image/ArtDirectionImage";
import * as gtag from "@/lib/gtag";

const TabStage = ({ content, id }) => {
  const { data } = content;
  const { settings, tabs } = data;
  const tabsArr: any[][] = Object.entries(tabs);
  const [activeTab, setActiveTab] = useState(tabsArr?.[0][0]);
  const [style, setStyle] = useState<"white" | "blue">(
    tabsArr?.[0]?.[1]?.settings.textColor || "white"
  );

  const handleTabChange = (e: string) => {
    const [identifier = "", active] = tabsArr.find(
      ([identifier]) => e === identifier
    )!;
    setActiveTab(identifier);
    setStyle(active?.settings?.textColor || "white");
  };

  const headlineStyles = cva("", {
    variants: {
      textColor: textColorVariant,
    },
    defaultVariants: {
      textColor: "blue",
    },
  });

  return (
    <>
      <div
        className={`${s.Stage} xl:min-h-screen lg:min-h-[65vh]  min-h-[544px] lg:items-center lg:p-0 lg:pb-[290px] pb-[100px]`}
      >
        <ArtDirectionImage
          sizes="100vw"
          priority={false}
          alt={tabs[activeTab].settings.tabText}
          image={tabs[activeTab].settings.image}
          imageMobile={tabs[activeTab].settings.imageMobile}
          imageTablet={tabs[activeTab].settings.imageTablet}
        />

        <div
          className={`container text-center h-full lg:w-full z-10 mb-auto mt-[2%]`}
        >
          <div className="mb-11">
            <p
              className={`lg:text-6xl text-4xl font-bold  ${
                settings.subheader !== "" ? "mb-0" : "mb-4"
              } ${style === "white" ? "text-white" : "text-blue-dark"}`}
            >
              {settings.header}
            </p>
            {settings.subheader !== "" && (
              <p
                className={`lg:text-4xl text-sm font-bold  lg:mb-5 mb-4 ${
                  style === "white" ? "text-white" : "text-blue-dark"
                }`}
              >
                {settings.subheader}
              </p>
            )}

            <Badge
              color="discount"
              size="big"
              className="lg:!text-6xl !text-4xl !font-bold lg:!px-8 !px-6  lg:!rounded-2xl !rounded-xl"
            >
              {settings.bottomHeadline}
            </Badge>
          </div>
        </div>
      </div>
      {/* sm:max-w-[690px] md:max-w-[818px] lg:max-w-[1074px] xl:max-w-[1330px] 2xl:max-w-[1586px] */}
      <div
        className={`tab-stage container mx-auto pt-8 lg:-mt-[290px] -mt-[100px] mb-7 overflow-x-hidden`}
      >
        <Tabs.Root
          defaultValue={tabsArr?.[0][0]}
          value={activeTab}
          className="relative"
          onValueChange={handleTabChange}
        >
          <Tabs.List
            className="mx-[-12px] md:mx-0"
            aria-owns={tabsArr
              .map(([identifier]: any[]) => `${id}-${identifier}`)
              .join(" ")}
          >
            <div className={`w-full mx-auto relative`}>
              <Slider
                className={`${s.TabStage} `}
                trackClassName="overflow-hidden sm:skew-x-[-20deg] md:rounded-tl-xl md:rounded-br-xl lg:rounded-tl-[20px] lg:rounded-br-[20px] md:rounded-tr-[15px] md:rounded-bl-[15px] lg:rounded-tr-[30px] lg:rounded-bl-[30px]  drop-shadow-md"
                options={{
                  perPage: 3,
                  perMove: 3,
                  gap: "12px",
                  pagination: false,
                  arrows: true,
                  width: "100%",
                  focus: 1,
                  updateOnMove: true,
                  breakpoints: {
                    640: {
                      perPage: 2,
                      perMove: 2,
                      autoWidth: true,
                      width: "auto",
                    },
                  },
                }}
              >
                {tabsArr
                  .filter((item) => item[1].cars.length !== 0)
                  .map(([identifier, item]: any[], index: number) => {
                    return (
                      <Slider.Slide key={index}>
                        <Tabs.Trigger
                          id={`${id}-${identifier}`}
                          key={identifier}
                          value={identifier}
                          className={`active:text-white  active:bg-blue-light  transition-colors ${s.TabStageSlide} group `}
                          onClick={() => {
                            gtag.event({
                              event: "itemClick",
                              itemClick: "Carousel",
                              title: `${item.settings?.tabText}` || "",
                            });
                          }}
                        >
                          <TabTrigger
                            title={item.settings?.tabText}
                            price={item.stats?.stats.preis.min}
                            financeRate={
                              item.stats?.stats?.monatlicherateFinanzierung?.min
                            }
                            showNettoPreis={item.settings?.showNettoPreis}
                          />
                        </Tabs.Trigger>
                      </Slider.Slide>
                    );
                  })}

                <Slot name="arrows">
                  <Slider.Arrows
                    variant="gray"
                    position="outside"
                    className="!hidden xl:!flex"
                  />
                </Slot>
              </Slider>
            </div>
          </Tabs.List>

          {tabsArr.map(([identifier, item]: any[], index: number) => (
            <Tabs.Content key={identifier} value={identifier}>
              <CarGrid
                items={item.cars}
                link={{
                  text: item.settings.linkText,
                  url: item.settings.link || "/",
                }}
              />
            </Tabs.Content>
          ))}
        </Tabs.Root>
      </div>
    </>
  );
};

const Headline = ({ children }) => {
  return (
    <p className="text-center pb-8 text-white  text-4xl font-bold">
      {children}
    </p>
  );
};

const TabTrigger = ({ title, price, financeRate, showNettoPreis = true }) => {
  return (
    <div className={`${s.TabStageTrigger} sm:skew-x-[20deg] relative`}>
      <p className="lg:text-2xl text-sm font-bold group-hover:text-gray-100 md:truncate whitespace-nowrap max-w-full px-5">
        {title}
      </p>
      <div className="flex">
        {!!price && (
          <span className="text-[13px] text-gray-600 group-hover:text-gray-100">
            ab{" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(price)}
            {showNettoPreis && " zzgl. Mwst."}
          </span>
        )}
        {!!financeRate && (
          <span className="text-[13px] text-gray-600 group-hover:text-gray-100 lg:block hidden">
            &nbsp; | ab{" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(financeRate)}
            {showNettoPreis ? " mtl. zzgl. Mwst." : " mtl."}
          </span>
        )}
      </div>
    </div>
  );
};

export default Object.assign(TabStage, { Headline });
