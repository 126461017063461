import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconEuroHouse: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 35 38"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.783 12.465 16.124.533a1.704 1.704 0 0 1 2.093 0l15.34 11.932c.416.323.659.82.659 1.345v18.75a5.114 5.114 0 0 1-5.114 5.114H5.24A5.114 5.114 0 0 1 .125 32.56V13.81c0-.526.243-1.022.658-1.345Zm2.751 2.179V32.56c0 .942.763 1.705 1.705 1.705h23.863c.942 0 1.705-.763 1.705-1.705V14.644L17.17 4.038 3.534 14.644Zm11.776 8.93h4.563v-1.807H15.31v-1.64h4.563V18.32h-4.55c.076-1.402.46-2.47 1.153-3.205.692-.735 1.683-1.102 2.974-1.102.863 0 1.73.145 2.602.436l.358-2.628c-1.136-.265-2.157-.397-3.063-.397-2.162 0-3.884.604-5.165 1.814-1.282 1.209-1.979 2.903-2.09 5.082H9.875v1.807h2.205v1.64H9.875v1.808h2.205c.068 2.204.73 3.922 1.986 5.152s2.978 1.846 5.166 1.846a13.47 13.47 0 0 0 3.178-.372l-.358-2.614a7.954 7.954 0 0 1-2.628.422c-1.299 0-2.296-.367-2.993-1.102-.696-.735-1.07-1.846-1.121-3.332Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconEuroHouse.displayName = "euroHouse";
export default IconEuroHouse;
