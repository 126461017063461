import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconFormular: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 44 45"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M26.708 4.755c.064.048.125.1.182.158l9.918 9.917c.31.31.484.73.484 1.169v19.834a4.959 4.959 0 0 1-4.959 4.96H12.498a4.959 4.959 0 0 1-4.958-4.96V9.387a4.959 4.959 0 0 1 4.958-4.959h13.24c.352.004.69.12.97.327Zm-2.64 2.98h-11.57c-.912 0-1.652.74-1.652 1.652v26.446c0 .913.74 1.653 1.652 1.653h19.835c.913 0 1.653-.74 1.653-1.653V17.652h-8.265c-.913 0-1.653-.74-1.653-1.653V7.734Zm7.58 6.61h-4.274v-4.273l4.274 4.274Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFormular.displayName = "formular";
export default IconFormular;
