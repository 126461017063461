const Table = ({ head, body }) => {
  return (
    <div className=" w-full container mx-auto md:overflow-x-auto overflow-x-scroll">
      <table className="border-collapse  w-full">
        <thead>
          <tr>
            {head?.map((col, index) => (
              <th
                className="md:text-2xl text-xl text-left pb-5 pt-7"
                key={index}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body?.map((cols, index) => (
            <tr
              key={`tbody-row-${index}`}
              className="border-t-[1px] border-gray-300 first:border-transparent"
            >
              {cols.map((col, index) => (
                <td
                  className={`md:text-base text-sm text-gray-600 first:font-bold py-4 md:min-w-0 min-w-[150px] `}
                  key={index}
                >
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const Typo3Table = ({ content }) => {
  const { bodytext } = content;
  const [tableHead, ...tableBody] = bodytext;
  return <Table head={tableHead} body={tableBody} />;
};

export default Table;
