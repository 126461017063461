import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconFormUpload: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 31 34"
        className={className}
      >
        <path
          fill="currentColor"
          d="M17.167 30.333H5.5a1.667 1.667 0 0 1-1.667-1.667V5.333A1.667 1.667 0 0 1 5.5 3.666h8.333v5a5 5 0 0 0 5 5h5V17a1.667 1.667 0 0 0 3.334 0v-5.1a2.187 2.187 0 0 0-.1-.45v-.15a1.786 1.786 0 0 0-.317-.467l-10-10a1.783 1.783 0 0 0-.467-.317.533.533 0 0 0-.15 0 1.467 1.467 0 0 0-.55-.183H5.5a5 5 0 0 0-5 5v23.333a5 5 0 0 0 5 5h11.667a1.667 1.667 0 0 0 0-3.333Zm0-24.317 4.316 4.317h-2.65a1.667 1.667 0 0 1-1.666-1.667v-2.65Zm-8.334 4.317a1.667 1.667 0 1 0 0 3.333H10.5a1.667 1.667 0 0 0 0-3.333H8.833Zm10 6.667h-10a1.667 1.667 0 0 0 0 3.333h10a1.667 1.667 0 1 0 0-3.333Zm11.184 8.816-3.334-3.333a1.667 1.667 0 0 0-.55-.35 1.666 1.666 0 0 0-1.266 0c-.205.08-.392.198-.55.35l-3.334 3.333a1.674 1.674 0 0 0 2.367 2.367l.483-.5V32a1.667 1.667 0 0 0 3.334 0v-4.317l.483.5a1.666 1.666 0 0 0 2.367 0 1.666 1.666 0 0 0 0-2.367ZM15.5 27a1.667 1.667 0 0 0 0-3.334H8.833a1.667 1.667 0 1 0 0 3.334H15.5Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconFormUpload.displayName = "form-upload";
export default IconFormUpload;
