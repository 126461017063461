import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCalculator: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M12.71 17.29a1.007 1.007 0 0 0-.16-.12.561.561 0 0 0-.17-.09.602.602 0 0 0-.19-.06.93.93 0 0 0-.57.06.9.9 0 0 0-.54.54.84.84 0 0 0-.08.38 1 1 0 0 0 .07.38c.058.12.132.23.22.33A1 1 0 0 0 12 19a.84.84 0 0 0 .38-.08 1.15 1.15 0 0 0 .33-.21A1 1 0 0 0 13 18a1.001 1.001 0 0 0-.08-.38 1 1 0 0 0-.21-.33Zm-4.16-4.12a.56.56 0 0 0-.17-.09.6.6 0 0 0-.19-.08.859.859 0 0 0-.39 0l-.18.06-.18.09-.15.12a1.05 1.05 0 0 0-.216 1.114 1 1 0 0 0 .216.326c.097.088.209.16.33.21A1 1 0 0 0 9 14a1.05 1.05 0 0 0-.29-.71l-.16-.12Zm.16 4.12a1 1 0 0 0-.33-.21A1 1 0 0 0 7.8 17l-.18.06a.762.762 0 0 0-.18.09 1.577 1.577 0 0 0-.15.12 1 1 0 0 0-.21.33.94.94 0 0 0 0 .76A1 1 0 0 0 8 19a.84.84 0 0 0 .38-.08 1.15 1.15 0 0 0 .33-.21 1.15 1.15 0 0 0 .21-.33.94.94 0 0 0 0-.76 1 1 0 0 0-.21-.33Zm2.91-4.21a1 1 0 0 0-.33.21A1.05 1.05 0 0 0 11 14a1 1 0 0 0 1.38.92 1.15 1.15 0 0 0 .33-.21A1 1 0 0 0 13 14a1.05 1.05 0 0 0-.29-.71.999.999 0 0 0-1.09-.21Zm5.09 4.21a1.15 1.15 0 0 0-.33-.21 1 1 0 0 0-1.09.21 1 1 0 0 0-.21.33.94.94 0 0 0 0 .76c.05.121.122.233.21.33A1 1 0 0 0 16 19a.84.84 0 0 0 .38-.08 1.15 1.15 0 0 0 .33-.21.999.999 0 0 0 .21-1.09 1 1 0 0 0-.21-.33ZM16 5H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Zm-1 4H9V7h6v2Zm3-8H6a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3Zm1 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16Zm-2.45-6.83a.561.561 0 0 0-.17-.09.602.602 0 0 0-.19-.06.859.859 0 0 0-.39 0l-.18.06-.18.09-.15.12A1.05 1.05 0 0 0 15 14a1 1 0 0 0 1.38.92 1.15 1.15 0 0 0 .33-.21A1 1 0 0 0 17 14a1.05 1.05 0 0 0-.29-.71l-.16-.12Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconCalculator.displayName = "calculator";

export default IconCalculator;
