import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconShoppingCart: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.72 6.082h13.26c.643 0 1.126.589 1 1.22l-1.371 6.848a2.648 2.648 0 0 1-2.634 2.135h-8.62a2.653 2.653 0 0 1-2.652-2.307L5.462 4.573a.612.612 0 0 0-.605-.532H3.02A1.02 1.02 0 1 1 3.02 2H4.86a2.653 2.653 0 0 1 2.626 2.307l.234 1.775Zm.268 2.04.738 5.59c.04.307.303.535.62.533h8.649a.613.613 0 0 0 .612-.494l1.127-5.629H7.988ZM6.082 19.347a2.653 2.653 0 1 0 5.306 0 2.653 2.653 0 0 0-5.306 0Zm3.265 0a.612.612 0 1 1-1.225 0 .612.612 0 0 1 1.225 0Zm6.53 0a2.653 2.653 0 1 0 5.306 0 2.653 2.653 0 0 0-5.306 0Zm3.266 0a.612.612 0 1 1-1.225 0 .612.612 0 0 1 1.225 0Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconShoppingCart.displayName = "shopping-cart";
export default IconShoppingCart;
