import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconRepairTool: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m27.137 19.475-5.65-5.638a8.573 8.573 0 0 0 .175-1.75A9.589 9.589 0 0 0 8.024 3.4a1.25 1.25 0 0 0-.362 2.025L13.1 10.85l-2.25 2.25-5.426-5.438a1.25 1.25 0 0 0-1.1-.337 1.25 1.25 0 0 0-.925.7 9.587 9.587 0 0 0 8.75 13.637 8.573 8.573 0 0 0 1.75-.175l5.638 5.65a1.251 1.251 0 0 0 2.049-.406 1.251 1.251 0 0 0-.274-1.369l-6.125-6.125a1.25 1.25 0 0 0-1.188-.325 7.354 7.354 0 0 1-1.85.25A7.089 7.089 0 0 1 5 12.087a7.499 7.499 0 0 1 .1-1.25L10 15.75a1.252 1.252 0 0 0 1.775 0l3.976-4.013a1.25 1.25 0 0 0 0-1.737l-4.863-4.9a7.65 7.65 0 0 1 1.25-.1 7.088 7.088 0 0 1 7.075 7.087 7.354 7.354 0 0 1-.25 1.85 1.25 1.25 0 0 0 .325 1.188l6.125 6.125a1.255 1.255 0 0 0 1.775-1.775h-.05Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconRepairTool.displayName = "repair-tool";

export default IconRepairTool;
