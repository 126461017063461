import React, { Fragment, PropsWithChildren } from "react";

export interface SlotProps {
  name: string;
}

export const Slot: React.FC<PropsWithChildren<SlotProps>> = ({ children }) => (
  <>{children}</>
);

export function createSlots(
  names: string[],
  children:
    | React.ReactElement
    | React.ReactElement[]
    | React.ReactNode
    | React.ReactNodeArray
): Array<React.ReactElement[]> {
  const slots = names.map((name, index) => {
    let slot: any = null;

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (child.type === Slot && (child.props as SlotProps).name === name) {
        slot = slot
          ? [
              ...slot,
              <Fragment key={`slot-${index}`}>
                {React.cloneElement(child)}
              </Fragment>,
            ]
          : [
              <Fragment key={`slot-${index}`}>
                {React.cloneElement(child)}
              </Fragment>,
            ];
      }
    });
    return slot;
  });

  return [
    ...slots,
    React.Children.toArray(children).filter((child) => {
      if (!React.isValidElement(child)) {
        return false;
      }
      if (child.type === Slot) {
        return false;
      }
      return true;
    }),
  ];
}
