import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconCheckmark: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M23.51 3.71a1.71 1.71 0 00-2.39 0l-13 13-5.24-5.26a1.7 1.7 0 00-1.2-.5 1.67 1.67 0 00-1.19.5 1.69 1.69 0 000 2.38l6.45 6.45a1.69 1.69 0 002.38 0L23.51 6.09a1.7 1.7 0 000-2.38z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconCheckmark.displayName = "checkmark";
export default IconCheckmark;
