import { ToastContext } from "@/components/Toast/ToastProvider";
import { useState, useContext } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

export default function useNetworkState() {
  const [online, setOnline] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const { addToast } = useContext(ToastContext);

  useIsomorphicLayoutEffect(() => {
    setInitialized(true);
    if (online && !initialized) {
      return;
    }

    if (online) {
      addToast({
        title: "Du bist wieder online",
        variant: "success",
      });
    } else {
      addToast({
        title: "Keine Internetverbindung!",
        variant: "alert",
      });
    }
  }, [online]);

  useIsomorphicLayoutEffect(() => {
    function onlineChange() {
      setOnline(true);
    }
    function offlineChange() {
      setOnline(false);
    }

    setOnline(window.navigator.onLine);

    window.addEventListener("online", onlineChange);
    window.addEventListener("offline", offlineChange);
    return () => {
      window.removeEventListener("online", onlineChange);
      window.removeEventListener("offline", offlineChange);
    };
  }, []);

  return {
    online,
  };
}
