import Head from "next/head";

const MetaAttributes = ({ meta, slug = "/" }) => {
  return (
    <Head>
      <meta httpEquiv="x-ua-compatible" content="IE=edge" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black" />

      {meta.robots && (
        <meta
          name="robots"
          content={`
        ${meta.robots.noIndex ? "noindex," : "index,"}
        ${meta.robots.noFollow ? "nofollow" : "follow"}`}
        />
      )}

      {meta.ogImage && meta.ogImage["publicUrl"] && (
        <meta property="og:image" content={meta.ogImage["publicUrl"]} />
      )}
      {meta.twitterTitle && (
        <meta name="twitter:title" content={meta.twitterTitle} />
      )}
      {meta.twitterCard && (
        <meta name="twitter:card" content={meta.twitterCard} />
      )}
      {meta.twitterImage && (
        <meta name="twitter:image" content={meta.twitterImage} />
      )}
      {meta.twitterDescription && (
        <meta name="twitter:description" content={meta.twitterDescription} />
      )}
      {meta.title && <title>{meta.title}</title>}
      {meta.description ? (
        <meta name="description" content={meta.description} />
      ) : (
        <meta
          name="description"
          content="Ford - bis zu -40% Rabatt bei Ford Kögler: Europas grösste Ford-Ausstellung"
        />
      )}
      {meta.canonical
        ? [
            <link
              key="canonicalKey"
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${meta.canonical}`}
            />,
            <meta
              key="ogUrlKey"
              property="og:url"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${meta.canonical}`}
            />,
          ]
        : [
            <link
              key="canonicalKey"
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${slug}`}
            />,
            <meta
              key="ogUrlKey"
              property="og:url"
              content={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${slug}`}
            />,
          ]}
      {meta.ogTitle && <meta property="og:title" content={meta.ogTitle} />}
      {meta.ogDescription && (
        <meta property="og:description" content={meta.ogDescription} />
      )}
      {meta.ogType && <meta property="og:type" content={meta.ogType} />}
    </Head>
  );
};

export default MetaAttributes;
