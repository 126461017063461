interface WLTPData {
  kombiniert: string;
  CO2EmissionenKombiniert: string;
  Schadstoffklasse: string;
  Feinstaubplakette: string;
  Energieeffizienz: string;
}

class Car {
  public id: string;
  public uid: number;
  public internalid: string;
  internaltitle: string;
  public subline: string;
  public preis: number;
  public verbrauchkombiniert: number;
  public verbrauchinnerorts: number;
  public verbrauchausserorts: number;
  public schadstoffklasse: string;
  public umweltplakette: string;
  public energieeffizienzklasse: string;
  public kilometer: number;
  public ez: number;
  public leistung: number;
  public farbe: string;
  public originalfarbe: string;
  public getriebeart: string;
  public kraftstoffart: any;
  public tueren: any;
  public sitze: any;
  public ccm: number;
  public marke: string;
  public marke_modell: string;
  public modell: string;
  public submodel: string;
  public monatlicherate: number;
  public rabatt: number;
  public ezTeaser: string;
  public consumptionAndCo2Emission: string;
  public model: string;
  public equipmentline: string;
  public auspraegung: string;
  public color: string;
  public interieurtyp: string;
  public radformel: string;
  public ps: number;
  public vehicleType: string;
  public slide: any[];
  public usagecategory: string;
  public zustand: string;
  public url: string;
  public nettoPreis: number;
  public showNettoPreis: boolean;
  public isleasable: boolean;
  public isfinanceable: boolean;
  public nettoMonatlicherate: number;
  public WLTP: WLTPData;

  public assets: {
    image: string;
    movie: string;
    images?: string[];
  };

  static timestampToDate(
    timestamp: number,
    day: boolean,
    month: boolean,
    year: boolean
  ): string {
    const d = new Date(timestamp * 1000);
    const dayStr = d.getDate() + "/";
    const monthStr = d.getMonth() + 1 + "/";
    const yearStr = d.getFullYear();
    let dataString = `${day ? dayStr : ""}${month ? monthStr : ""}${
      year ? yearStr : ""
    }`;

    return dataString;
  }

  static isValidCar(value: Object) {
    return [
      "usagecategory",
      "kilometer",
      "getriebeart",
      "rabatt",
      "monatlicherate",
      "kraftstoffart",
      "preis",
      "ez",
      "uid",
      "assets",
      "link",
    ].every((prop) => prop in value);
  }

  static from(value: Object) {
    if (Car.isValidCar(value)) {
      return new Car(value);
    }

    console.error("Car has insufficient attributes", value);
    return undefined;
  }

  constructor(data) {
    this.id = data.id;
    this.uid = data.uid;
    this.internalid = data.internalid;
    this.internaltitle = data.title;
    this.subline = data.subline;
    this.preis = data.preis;
    this.verbrauchkombiniert = data.verbrauchkombiniert;
    this.verbrauchausserorts = data.verbrauchausserorts;
    this.verbrauchinnerorts = data.verbrauchinnerorts;
    this.schadstoffklasse = data.schadstoffklasse;
    this.umweltplakette = data.umweltplakette;
    this.energieeffizienzklasse = data.energieeffizienzklasse;
    this.kilometer = data.kilometer;
    this.ez = data.ez;
    this.leistung = data.leistung;
    this.farbe = data.farbe;
    this.originalfarbe = data.originalfarbe;
    this.marke = data.marke;
    this.marke_modell = data.marke_modell;
    this.submodel = data.submodel;
    this.monatlicherate = data.monatlicherate;
    this.rabatt = data.rabatt;
    this.ezTeaser = data.ezTeaser;
    this.kraftstoffart = data.kraftstoffart;
    this.kilometer = data.kilometer;
    this.getriebeart = data.getriebeart;
    this.consumptionAndCo2Emission = data.consumptionAndCo2Emission;
    this.assets = data.assets;
    this.model = data.modell;
    this.equipmentline = data.equipmentline;
    this.auspraegung = data.auspraegung;
    this.color = data.color;
    this.interieurtyp = data.interieurtyp;
    this.radformel = data.radformel;
    this.modell = data.modell;
    this.ps = data.ps;
    this.ccm = data.ccm;
    this.sitze = data.sitze;
    this.tueren = data.tueren;
    this.color = data.originalfarbe;
    this.vehicleType = data.vehicleType;
    this.slide = data.slide;
    this.usagecategory = data.usagecategory;
    this.zustand = data.zustand;
    this.url = data.link.detail;
    this.nettoPreis = data.nettoPreis;
    this.showNettoPreis = data.showNettoPreis;
    this.isleasable = data.isleasable;
    this.isfinanceable = data.isfinanceable;
    this.nettoMonatlicherate = data.nettoMonatlicherate;
    this.WLTP = data.WLTP;
  }

  get formattedPrice() {
    const priceToDisplay = this.showNettoPreis ? this.nettoPreis : this.preis;

    const formattedPrice = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(priceToDisplay);

    return formattedPrice;
  }
  get formattedRate() {
    if (this.monatlicherate > 0)
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      }).format(this.monatlicherate);
    return undefined;
  }
  get formattedKilometer() {
    return `${this.kilometer.toLocaleString("de-DE")} km`;
  }

  get title() {
    return this.internaltitle || this.marke_modell;
  }

  get subtitle() {
    return this.subline || this.submodel;
  }

  get keyfacts() {
    return [
      {
        value: `${this.kilometer.toLocaleString("de-DE")} km`,
        label: "KM",
      },
      // {
      //   value: `${this.ezTeaser}`,
      //   label: `${
      //     this.ezTeaser === "Tageszulassung" || this.ezTeaser === "Neuwagen"
      //       ? ""
      //       : "Erstzulassung"
      //   }`,
      // },
      {
        value: `${this.ezTeaser}`,
        label: "Erstzulassung",
      },

      {
        value: this.getriebeart,
        label: "Getriebe",
      },
      {
        value: this.kraftstoffart,
        label: "Kraftstoff",
      },
      {
        value: `${this.ps} PS`,
        label: "Leistung",
      },
      {
        value: this.originalfarbe || this.farbe,
        label: "Farbe",
      },
      {
        value: this.tueren,
        label: "Türen",
      },
      {
        value: this.sitze,
        label: "Sitze",
      },
      {
        value: `${this.ccm.toLocaleString("de-DE")} cm³`,
        label: "Hubraum",
      },
      {
        value: this.marke,
        label: "Marke",
      },
    ];
  }

  get productionDate() {
    const date = new Date(this.ez * 1000);
    return date.getFullYear();
  }
  get registrationDate() {
    if (this.usagecategory === "Neuwagen" && this.kilometer <= 100)
      return "Neu";
    else {
      return Car.timestampToDate(this.ez || this.ez, false, true, true);
    }
  }
  get registrationDateTeaser() {
    if (this.usagecategory === "Neuwagen" && this.kilometer <= 100)
      return "Neu";
    else {
      return this.ezTeaser;
    }
  }
}

export default Car;
