import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconLineendSkewedLeft: FC<Icon> = ({ className, purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 32"
      >
        <path
          fill="currentColor"
          d="M16.9412 0C12.7513 0 8.75515 2.89109 7.37542 6.24981L0.497606 22.9609C-1.29337 27.3154 1.93693 32 7.19363 32C7.19363 32 12.7303 32 16.9412 32V0Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconLineendSkewedLeft.displayName = "lineend-skewed-left";
export default IconLineendSkewedLeft;
