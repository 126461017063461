import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconAward: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.575 13.99a6.707 6.707 0 1 1 8.264 0l.921 6.937a.946.946 0 0 1-1.425.936l-3.628-2.177-3.628 2.177a.946.946 0 0 1-1.425-.936l.921-6.936Zm1.777 1-.563 4.24 2.431-1.459c.3-.18.674-.18.974 0l2.431 1.459-.562-4.241a6.692 6.692 0 0 1-2.356.425c-.829 0-1.622-.15-2.355-.425Zm5.033-2.282a.94.94 0 0 0-.14.09 4.814 4.814 0 1 1 .14-.09Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};

IconAward.displayName = "award";

export default IconAward;
