const Rhombus = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 289 246"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M236.488 0H123.535C92.983 0 63.843 22.225 53.782 48.045L3.629 176.512C-9.431 209.987 14.124 246 52.456 246h112.953c30.552 0 59.692-22.225 69.753-48.045l50.153-128.467C297.984 36.965 277.08 0 236.488 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Rhombus;
