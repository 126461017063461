import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconTelegram: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          fill="currentColor"
          d="M18.908 19.778 21.926 5.57c.148-.589.074-1.03-.22-1.325-.295-.295-.59-.295-1.031-.147L2.932 10.944c-.368.147-.663.294-.81.515-.147.22-.147.368-.074.515.074.147.295.295.663.368l4.565 1.4 10.527-6.627c.295-.22.516-.22.663-.147.074.074.074.147-.074.294l-8.54 7.804-.368 4.712c.295 0 .59-.147.957-.441L12.65 17.2l4.565 3.387c.957.515 1.472.221 1.693-.81Z"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconTelegram.displayName = "telegram";
export default IconTelegram;
