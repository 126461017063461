import ConditionalWrapper from "@/components/ConditionalWrapper/ConditionalWrapper";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { FC } from "react";
import { Icon } from "../icon";

const IconGalleryDetail: FC<Icon> = ({ className = "", purposeLabel }) => {
  return (
    <ConditionalWrapper
      condition={purposeLabel}
      wrapper={(children) => (
        <AccessibleIcon.Root label={purposeLabel!}>
          {children}
        </AccessibleIcon.Root>
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
        className={className}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6.5.67H2.33C1.41.67.67 1.4.67 2.33V6.5c0 .92.74 1.67 1.66 1.67H6.5c.92 0 1.67-.75 1.67-1.67V2.33c0-.92-.75-1.66-1.67-1.66ZM2.33 9.83H6.5c.92 0 1.67.75 1.67 1.67v4.17c0 .92-.75 1.66-1.67 1.66H2.33c-.92 0-1.66-.74-1.66-1.66V11.5c0-.92.74-1.67 1.66-1.67Zm0 1.67v4.17H6.5V11.5H2.33ZM15.67.67H11.5c-.92 0-1.67.74-1.67 1.66V6.5c0 .92.75 1.67 1.67 1.67h4.17c.92 0 1.66-.75 1.66-1.67V2.33c0-.92-.74-1.66-1.66-1.66ZM11.5 6.5V2.33h4.17V6.5H11.5Zm0 3.33h4.17c.92 0 1.66.75 1.66 1.67v4.17c0 .92-.74 1.66-1.66 1.66H11.5c-.92 0-1.67-.74-1.67-1.66V11.5c0-.92.75-1.67 1.67-1.67Zm0 1.67v4.17h4.17V11.5H11.5Z"
          clipRule="evenodd"
        />
      </svg>
    </ConditionalWrapper>
  );
};
IconGalleryDetail.displayName = "galleryDetail";
export default IconGalleryDetail;
