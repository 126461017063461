import Link from "@/components/Link/Link";

const Typo3PreviewBar = ({ editUrl }) => (
  <div className="bg-typo text-white p-3 flex justify-center items-center fixed w-full bottom-0">
    Du bist im TYPO3 Preview Modus{" "}
    {editUrl && (
      <Link
        href={editUrl}
        className="border-white border inline-flex rounded-md border-2 py-1 px-2 mr-3 ml-3"
      >
        <img src="/typo3_logo.svg" width="20" className="mr-2" />
        in TYPO3 bearbeiten
      </Link>
    )}
    <Link
      href="/api/exit-preview?slug=/"
      className="border-white border inline-flex rounded-md border-2 py-1 px-2"
    >
      Logout
    </Link>
  </div>
);

export default Typo3PreviewBar;
